/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  addDays, set, isBefore, isEqual,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import axios from 'axios';

import api from '../../services/api';
import TableAlunos from '../../components/TableAlunos';
import MessageBox from '../../components/MessageBox';
import Button from '../../components/Button';
import AnimateContainer from '../../components/AnimateContainer';
import * as FrequenciaActions from '../../store2/modules/frequencia/actions';
import * as TurmaActions from '../../store2/modules/turma/actions';

import {
  Titulo, Container, HeaderButtons, Card,
} from '../../styles/Defaults';
import DateTimeStyle from './styles';

registerLocale('ptBR', ptBR);

export default function FrequenciaGeral() {
  const newDate0 = () =>
    set(new Date(), { hours: 0, minutes: 0, seconds: 0 });

  ptBR.options.weekStartsOn = 0;
  setDefaultLocale('ptBR');
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState(newDate0());
  const [dataFim, setDataFim] = useState(newDate0());
  const [filtro, setFiltro] = useState(false);
  const [msgData, setMsgData] = useState('');

  const dispatch = useDispatch();
  const selectedAula = useSelector((state) =>
    state.aula.selected);
  const titulo = useSelector((state) => {
    const { NOMEDISC: disciplina, NOMETURMA: turma } = state.turma.turmaDisciplina;
    if (disciplina && turma) {
      return `${disciplina} - ${turma}`;
    }
    return '';
  }, shallowEqual);

  const tableRef = useRef();

  useEffect(() => {
    dispatch(TurmaActions.getTurmaDisciplinaRequest(selectedAula.IDTURMADISC));

    return () =>
      dispatch(TurmaActions.clearTurma());
  }, [dispatch, selectedAula.IDTURMADISC]);

  const filtrar = async () => {
    setMsgData('');
    if (dataInicio === '' || dataFim === '') {
      setMsgData('Por favor, preencha os campos de data');
      return false;
    }
    const dataInicioISO = dataInicio.toISOString().substring(0, dataInicio.toISOString().indexOf('T'));
    const dataFimISO = dataFim.toISOString().substring(0, dataFim.toISOString().indexOf('T'));

    setFilterLoading(true);
    const response = await axios.all([
      api.getAlunos(selectedAula.CODTURMA, selectedAula.IDTURMADISC),
      api.getTurmaDisciplina(selectedAula.IDTURMADISC),
      api.getDiasGeral(selectedAula.IDTURMADISC, dataInicioISO, dataFimISO),
      api.getFaltasAlunosGeral(selectedAula.IDTURMADISC, dataInicioISO, dataFimISO),
    ]);

    const alunosWithFilterDate = response[0].dados.filter((item) => {
      if (item.DTSOLICITACAOALTERACAO) {
        const parseStringToDate = Date.parse(item.DTSOLICITACAOALTERACAO);
        return (
          isBefore(parseStringToDate, dataFim)
          || isEqual((parseStringToDate, dataFim))
        );
      }
      return true;
    });

    dispatch(
      FrequenciaActions.loadTableRequest(
        alunosWithFilterDate,
        response[1].dados,
        response[2].dados,
        response[3].dados,
      ),
    );
    setFilterLoading(false);
    setFiltro(true);
    tableRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setDataFim(dataInicio);
    setFiltro(false);
  }, [dataInicio]);

  useEffect(() => {
    if (titulo !== '' && loading) {
      setLoading(false);
    }
  }, [titulo, loading, filtro]);

  return (
    <>
      <DateTimeStyle />
      <AnimateContainer loading={loading || filterLoading}>
        <Container key="container">
          <HeaderButtons>
            <Button to="/AulasGeral/faltas" role="button">
              Voltar
            </Button>
            <Button to="/Home" role="button">
              Início
            </Button>
          </HeaderButtons>
          <Titulo>{titulo}</Titulo>
          <Card>
            <Card.Header>
              <h4>Selecione as datas nos campos abaixo:</h4>
            </Card.Header>
            <Card.Body>
              <Card.Body.Row>
                <label htmlFor="inputDataInicio">Data Início</label>
              </Card.Body.Row>
              <Card.Body.Row>
                <DatePicker
                  selected={dataInicio}
                  maxDate={newDate0()}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) =>
                    setDataInicio(date)}
                  todayButton="Hoje"
                />
              </Card.Body.Row>
              <Card.Body.Row>
                <label htmlFor="inputDataFim">Data Fim</label>
              </Card.Body.Row>
              <Card.Body.Row>
                <DatePicker
                  selected={dataFim}
                  minDate={dataInicio}
                  maxDate={Math.min(addDays(dataInicio, 6), newDate0())}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) =>
                    setDataFim(date)}
                  todayButton="Hoje"
                />
              </Card.Body.Row>
              <Card.Footer>
                <Button as="button" onClick={filtrar}>
                  Filtrar
                </Button>
              </Card.Footer>
              {msgData !== '' && <MessageBox message={msgData} type="danger" />}
            </Card.Body>
          </Card>

          <div ref={tableRef}>
            {filtro && <TableAlunos voltar="AulasGeral/faltas" />}
          </div>
        </Container>
      </AnimateContainer>
    </>
  );
}
