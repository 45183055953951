import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)``;

export const ContainerJustificativa = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonOcorrencia = styled.button`
  background: #032b50;
  color: #fff;
  margin: 5px;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #032b50;
  cursor: pointer;

  &:hover,
  &.active,
  &:focus {
    color: #032b50;
    background: #fff;
  }
`;

const buttonsPresentAbsent = css`
  cursor: pointer;
  border: 0.1rem solid;
  padding: 0.6rem;
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.1rem;
  font-size: 1.3rem;
  background: #fff;

  @media screen and (max-width: 550px) {
    font-size: 2rem;
    padding: 1.5rem;
  }
`;

export const BotaoPresente = styled.button`
  ${buttonsPresentAbsent}
  color: green;
`;

export const BotaoAusente = styled.button`
  ${buttonsPresentAbsent}
  color: red;
`;

export const HeaderButtons = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: ${(props) =>
    (props.permitLicao ? 'space-between' : 'flex-end')};

  & > * {
    margin: 0;
  }
`;

export const Table = styled.table`
  position: relative;
  background: #fff;
  border-collapse: collapse;
  margin: auto;
  width: 100%;
`;

Table.Header = styled.thead``;

Table.Header.Row = styled.tr``;

Table.Header.Col = styled.th`
  position: sticky;
  top: -1px;
  border-top: 0;
  z-index: 5;
  font-size: 1.5rem;
  font-weight: 500;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 0.75rem;
  transition: all ease-in-out 0.3s;
  background: var(--color-primary);
  color: var(--color-white);

  &.col-photo {
    z-index: 6;
    min-width: 8rem;
  }

  &.col-name {
    text-align: left;
    padding-left: 2rem;
  }

  &:last-child {
    border-right: 0;
  }
`;

Table.Body = styled.tbody``;

Table.Body.Row = styled.tr`
  font-size: 1.8rem;
  text-transform: capitalize;
`;

Table.Body.Cell = styled.td`
  text-align: center;
  padding: 0.75rem;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;

  &.col-photo {
    width: 10%;
    padding: 0;
    border-bottom: 0;
    background: #e3e5e6;
  }

  &:last-child {
    border-right: 0;
  }

  &.col-name {
    text-align: left;
    padding-left: 2rem;
  }
`;

export const ContainerTable = styled.div`
  margin: 2rem auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  width: 100%;

  overflow: auto;
  height: ${() =>
    `${document.documentElement.clientHeight
    - document.documentElement.clientHeight * 0.18
    }px`};
`;

export const ButtonPhotos = styled.div`
  display: inline-block;
  text-decoration: underline;
  color: var(--color-primary);
  background: transparent;
  margin-left: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const ModalContainer = styled.div``;

ModalContainer.Header = styled.div`
  width: 100%;
  text-align: right;

  button {
    background: transparent;
    border: 0;
    color: var(--color-primary);
    font-size: 1rem;
    text-rendering: optimizeLegibility;
    cursor: pointer;
    transform: scale(4.5);
  }
`;

export const ContainerObs = styled(motion.div)`
  width: 100%;
  padding: 5px;
  font-size: 1.2rem;
`;

ContainerObs.TextArea = styled(motion.textarea)`
  width: 100%;
  display: flex;
`;

ContainerObs.Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .btn-danger {
    background: #dc3545;
  }

  button {
    margin: 1rem 0.5rem;
  }
`;
