/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {
  message: null,
  user: null,
  signed: false,
  parametros: [],
  masterData: [],
  master: false,
};

export default function login(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@login/LOGIN_FAILURE': {
        const { message } = action.payload;
        draft.signed = false;
        draft.message = message;
        draft.user = null;
        break;
      }

      case '@login/LOGIN_SUCCESS': {
        const { user, parametros } = action.payload;
        draft.signed = true;
        draft.user = user;
        draft.message = null;
        draft.parametros = parametros;
        break;
      }

      case '@login/LOGIN_MASTER': {
        const { dados } = action.payload;
        draft.masterData = dados;
        draft.master = true;
        break;
      }

      case '@login/LOGOUT': {
        return INITIAL_STATE;
      }

      case '@login/SELECT_USER_MASTER': {
        draft.masterData = [];
        draft.master = false;
        break;
      }
      default:
    }
  });
}
