import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from '../../components/Loading';
import {
  ListAlunosNotas,
  ListAlunosNotasCaderno,
} from '../../components/ListAlunosNotas';
import HeaderNotas from '../../components/HeaderNotas';

import * as AlunoActions from '../../store2/modules/aluno/actions';
import * as TurmaActions from '../../store2/modules/turma/actions';

import { Titulo, Container, HeaderButtons } from '../../styles/Defaults';
import Button from '../../components/Button';
import useNotas from '../../hooks/useNotas';
import ListAlunosNotasAneSintese from '../../components/ListAlunosNotas/ListAlunosNotasAneSintese';

const ANESINTESE_ETAPAS = ['3', '4', '8', '9'];

const WrapperListAlunos = ({ titulo }) => {
  const config = useSelector((state) =>
    state.aluno.config);
  const alunos = useSelector((state) =>
    state.aluno.list);
  const instrumento = useSelector((state) =>
    state.notas.instrumento);

  let alunosList = [];

  if (config.length > 0 && instrumento.length > 0) {
    const ras = config
      .filter((item) =>
        item.codetapa === instrumento[0].CODETAPA)
      .map((item) =>
        item.ra);
    const newList = alunos.filter((item) =>
      ras.indexOf(item.RA) > -1);
    alunosList = newList;
  } else {
    alunosList = alunos;
  }

  if (alunosList.length > 0 && instrumento.length > 0) {
    if (ANESINTESE_ETAPAS.includes(instrumento[0]?.CODETAPA)) {
      return (
        <ListAlunosNotasAneSintese
          nomeDisc={titulo}
          alunos={alunosList}
        />
      );
    }
    if (
      !!instrumento[0].CADERNO
      || instrumento[0].DESCETAPA?.includes('CADERNO')
    ) {
      return (
        <ListAlunosNotasCaderno nomeDisc={titulo} alunos={alunosList} />
      );
    }
    return <ListAlunosNotas nomeDisc={titulo} alunos={alunosList} />;
  }
  return '';
};

export default function Notas() {
  const dispatch = useDispatch();
  const codTurma = useSelector(
    (state) =>
      state.aula.selected.CODTURMA,
  );
  const idTurmaDisc = useSelector((state) =>
    state.aula.selected.IDTURMADISC);

  const alunosLoading = useSelector((state) =>
    state.aluno.loading);

  const turmasLoading = useSelector((state) =>
    state.turma.loading);

  const instrumento = useSelector((state) =>
    state.notas.instrumento);

  const { nomeDisc: titulo } = useNotas();

  useEffect(() => {
    document.title = 'Notas';
  }, []);

  useEffect(() => {
    if (codTurma && idTurmaDisc) {
      dispatch(AlunoActions.getAlunosRequest(codTurma, idTurmaDisc));
      dispatch(TurmaActions.getTurmaDisciplinaRequest(idTurmaDisc));
    }

    return () => {
      dispatch(TurmaActions.clearTurma());
      dispatch(AlunoActions.clearAlunos());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codTurma, idTurmaDisc]);

  return (
    <Container>
      <HeaderButtons>
        <Button to="/AulasGeral/notas">Voltar</Button>
        <Button to="/Home">Início</Button>
      </HeaderButtons>
      <Titulo>{titulo}</Titulo>
      {(alunosLoading || turmasLoading || titulo === '') ? <Spinner /> : (
        <>
          <HeaderNotas idTurmaDisc={idTurmaDisc} />
          {instrumento.length > 0 && <WrapperListAlunos titulo={titulo} />}
        </>
      )}
    </Container>
  );
}
