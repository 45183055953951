import * as Accordion from '@radix-ui/react-accordion';
import * as Separator from '@radix-ui/react-separator';
import styled, { keyframes } from 'styled-components';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

export const AccordionItem = styled(Accordion.Item)`
    overflow: hidden;
    margin-top: 1px;
`;

export const AccordionRoot = styled(Accordion.Root)`
    border-radius: 6px;
    width: 100%;
    box-shadow: 0 2px 10px #c0c0c0;

    ${AccordionItem}:first-child {
        margin-top: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    ${AccordionItem}:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;

export const AccordionContent = styled(Accordion.Content)`
    overflow: hidden;
    font-size: 1.5rem;
    background-color: #f7f7f7;
    color: #000;

    &[data-state="open"] {
        animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
    &[data-state="closed"] {
        animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    & > div {
        padding: 15px 20px;
    }

    & .item-container {
        margin-bottom: 1rem;
    }

    & .cronograma {
        margin-left: 2rem;

        div:first-child {
            margin-top: 1rem;
        }

        div > div{
            display: flex;

            span {
                margin-left: 1rem;
            }
        }
    }   
    
    
`;

export const AccordionTrigger = styled(Accordion.Trigger)`
    all: unset;
    font-family: inherit;
    padding: 0 20px;
    height: 45px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.5s ease-in-out;
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        background-color: #f7f7f7;
    }

    & .paginas {
        font-size: 1.2rem;
    }
`;

export const AccordionHeader = styled(Accordion.Header)`
    display: flex;
`;

export const CronogramaSeparator = styled(Separator.Root)`
    margin: 15px 0;
    height: 1px;
    width: 100%;
    background-color: #aaa;
`;
