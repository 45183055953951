import styled from 'styled-components';
import * as Tabs from '@radix-ui/react-tabs';

export const TabsRoot = styled(Tabs.Root)`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 2px 10px #ccc;
`;

export const TabsList = styled(Tabs.List)`
    flex-shrink: 0;
    display: flex;
    border-bottom: 1px solid #eee;

    &:first-child {
        border-top-left-radius: 6px;
    }
    &:last-child {
        border-top-right-radius: 6px;
    }
`;

export const TabsTrigger = styled(Tabs.Trigger)`
    all: unset;
    background-color: white;
    padding: 0 20px;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    line-height: 1;
    color: #a9a9a9;
    user-select: none;
    cursor: pointer;

    &:hover {
        color: var(--color-primary);
    }

    &[data-state="active"] {
        color: var(--color-primary);
        box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
    }
`;

export const TabsContent = styled(Tabs.Content)``;
