/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = {
  codProf: null,
  codPessoa: null,
  email: null,
  series: [],
  disciplinasSerie: [],
};

export default function professor(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@professor/GET_SUCCESS': {
        const { codProf, codPessoa, email } = action.payload;
        draft.codProf = codProf;
        draft.codPessoa = codPessoa;
        draft.email = email;
        break;
      }
      case '@professor/GET_SERIES_SUCCESS': {
        const { series } = action.payload;
        draft.series = series;
        break;
      }
      case '@professor/GET_DISCIPLINAS_SERIE_SUCCESS': {
        const { disciplinasSerie } = action.payload;
        draft.disciplinasSerie = disciplinasSerie;
        break;
      }
      case '@login/LOGOUT': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
