/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { AnimatePresence } from 'framer-motion';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import api from '../../services/api';
import FotoAluno from '../FotoAluno';
import MessageBox from '../MessageBox';
import Modal from '../Modal';
import useModal from '../Modal/hooks/useModal';
import LicaoDeCasa from '../LicaoDeCasa';
import Button from '../Button';
import Loading from '../Loading';

import * as FrequenciaActions from '../../store2/modules/frequencia/actions';

import {
  ContainerJustificativa,
  ButtonOcorrencia,
  HeaderButtons,
  BotaoAusente,
  BotaoPresente,
  Table,
  ContainerTable,
  Container,
  ButtonPhotos,
  ContainerObs,
} from './styles';

function TableAlunos() {
  const [loadingSave, setLoadingSave] = useState(false);
  const [dadosFrequencia, setDadosFrequencia] = useState(null);
  const [selected, setSelected] = useState({});
  const [showObs, setShowObs] = useState({ show: false, code: '' });
  const [obs, setObs] = useState('');
  const [justificativas, setJustificativas] = useState(null);
  const [showLicao, setShowLicao] = useState(false);
  const [licoes, setLicoes] = useState(false);
  const [diaLicao, setDiaLicao] = useState(null);
  const [licaoData, setLicaoData] = useState(null);
  const [statusModal, toggleModal] = useModal();
  const {
    alunos, dias, faltasAlunos, modalFrequencia,
  } = useSelector(
    (state) =>
      state.frequencia,
  );
  const [showPhotos, setShowPhotos] = useState(false);
  const JustificativaRef = useRef();
  const selectedAula = useSelector((state) =>
    state.aula.selected);
  const { tipoLicao } = useSelector((state) =>
    state.licao);
  const { IDTURMADISC: idTurmaDisc, CODDISC: codDisc } = selectedAula;
  const { codPessoa } = useSelector((state) =>
    state.professor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (JustificativaRef.current) {
      const btnHover = document.querySelectorAll(
        `button[value="${showObs.code}"]`,
      );

      const actives = JustificativaRef.current.querySelectorAll('active');

      if (actives.length >= 1) {
        actives.forEach((item) =>
          item.classList.remove('active'));
      }

      if (btnHover.length === 1) {
        btnHover[0].classList.toggle('active');
      }
    }
  }, [showObs]);

  useEffect(() => {
    async function fetchData() {
      let response = await api.getTiposFalta();
      setJustificativas(response);
      if (diaLicao) {
        const idHabilitacaoFilial = alunos[0].IDHABILITACAOFILIAL;
        const dateFormatted = `${diaLicao.slice(-4)}-${diaLicao.substring(3, 5)}-${diaLicao.substring(0, 2)}`;
        response = await api.getLicao(idTurmaDisc, dateFormatted,
          idHabilitacaoFilial, codDisc, codPessoa);
        if (response.status === 200) {
          setLicaoData(response.dados);
          setShowLicao(true);
        } else {
          setShowLicao(false);
        }
      }
    }

    fetchData();
  }, [alunos, codDisc, codPessoa, diaLicao, idTurmaDisc]);

  useEffect(() => {
    async function fetchData() {
      if (showLicao) {
        if (licaoData && (licaoData.id !== undefined || licaoData.idLicao !== undefined)) {
          const response = await api.getLicoes(
            licaoData.id, licaoData.idLicao, licaoData.dataEntrega,
          );
          if (response.status === 200) {
            setLicoes(
              response.dados.map((res) =>
                ({
                  aluno: res.ra ?? res.RA,
                  licao: tipoLicao[res.tipo ?? res.codOcorrenciaTipo],
                })),
            );
          } else {
            setLicoes(alunos.map((res) =>
              ({ aluno: res.ra ?? res.RA, licao: '' })));
          }
        }
      } else {
        setLicoes(alunos.map((res) =>
          ({ aluno: res.ra ?? res.RA, licao: '' })));
      }
    }
    fetchData();
  }, [showLicao, alunos, licaoData, tipoLicao]);

  // salva a data da lição se houver apenas um dia selecionado
  useEffect(() => {
    if (dias) {
      let licaoDia = dias.map((dia) =>
        dia.DATA);
      licaoDia = licaoDia.filter((a, b) =>
        licaoDia.indexOf(a) === b);
      if (licaoDia.length === 1) {
        setDiaLicao(licaoDia[0]);
      } else {
        setDiaLicao(null);
      }
    }
  }, [dias]);

  useEffect(() => {
    if (!modalFrequencia) {
      setShowObs({ show: false });
    }
  }, [modalFrequencia]);

  const checkLicoes = async () => {
    if (showLicao && alunos.length > licoes.filter((item) =>
      item.licao !== '').length) {
      return new Promise((resolve, reject) => {
        setLoadingSave(false);
        confirmAlert({
          title: 'Confirmar lançamento',
          message: 'Não há lançamento de lição para todos os alunos, deseja continuar?',
          buttons: [
            {
              label: 'Sim',
              onClick: () =>
                resolve(),
            },
            {
              label: 'Não',
              onClick: () =>
                reject(new Error('Cancelado pelo usuário')),
            },
          ],
        });
      });
    }
    return Promise.resolve();
  };

  const setFrequencia = async () => {
    if (loadingSave === true) {
      return;
    }

    await checkLicoes();

    setLoadingSave(true);
    let data = {
      IDTURMADISC: idTurmaDisc,
      DADOS: faltasAlunos,
      licao: { hasLicao: showLicao, data: diaLicao, licoes },
      dias,
    };

    if (showLicao) {
      data = Object.assign(data, { licoes });
    }

    const result = await api.setFrequencia(data);

    if (result.status === 200) {
      dispatch(FrequenciaActions.clearTipo());
    }
    setLoadingSave(false);
    setDadosFrequencia(result);
  };

  const showModal = (ra, idHorarioTurma, data, hora) => {
    setSelected({
      ra, idHorarioTurma, data, hora,
    });
    setObs('');
    const currentIndex = faltasAlunos.findIndex(
      (faltas) =>
        faltas.RA === ra && faltas.IDHORARIOTURMA === idHorarioTurma,
    );

    if (currentIndex === -1 || faltasAlunos[currentIndex].PRESENCA === 'P') {
      toggleModal();
    } else {
      dispatch(FrequenciaActions.addFrequencia(currentIndex));
    }
  };

  const handleCheck = (event, ra, idHorarioTurma, data, hora) => {
    setShowObs({ show: false });
    const tipoCode = event.target.value;
    const day = data.substring(0, 2);
    const month = data.substring(3, 5);
    const year = data.substring(6, 10);
    const newDate = format(new Date(year, month - 1, day), 'yyyy-MM-dd');

    const currentIndex = faltasAlunos.findIndex(
      (faltas) =>
        faltas.RA === ra && faltas.IDHORARIOTURMA === idHorarioTurma,
    );

    dispatch(
      FrequenciaActions.addFrequencia(currentIndex, {
        idTurmaDisc,
        idHorarioTurma,
        ra,
        data: newDate,
        hora,
        tipoCode,
        obs,
      }),
    );
    toggleModal();
  };

  const setLicao = (e, ra, status) => {
    const parent = e.target.parentElement;
    const licoesTmp = licoes.slice();
    if (licoesTmp.findIndex((item) =>
      item.aluno === ra) < 0) {
      licoesTmp.push({ aluno: ra, licao: status });
    } else {
      licoesTmp[licoesTmp.findIndex((item) =>
        item.aluno === ra)].licao = status;
    }
    setLicoes(licoesTmp);
    parent.querySelector('.selected').classList.remove('selected');
    e.target.classList.add('selected');
  };

  const Alert = () => {
    if (
      dadosFrequencia !== null
      && dadosFrequencia.status === 200
      && !loadingSave
    ) {
      return <MessageBox type="success" message="Frequências Salvas" />;
    }

    if (
      dadosFrequencia !== null
      && dadosFrequencia.status !== 200
      && !loadingSave
    ) {
      return (
        <MessageBox
          type="danger"
          message="Erro ao lançar frequência, tente novamente!"
        />
      );
    }

    return '';
  };

  if (dias === null) {
    return (
      <MessageBox type="info" message="Não há aulas no intervalo informado!" />
    );
  }

  return (
    <>
      {loadingSave && <Loading />}
      <Container>
        <MessageBox
          message="Atenção! Não esqueça de salvar as ausências antes de sair!"
          type="warning"
        />
        <HeaderButtons>
          <Button as="button" onClick={setFrequencia}>
            Salvar
          </Button>
        </HeaderButtons>
        <Alert />
        <ButtonPhotos onClick={() =>
          setShowPhotos((old) =>
            !old)}
        >
          {(!showPhotos && 'Mostrar ') || 'Ocultar '}
          fotos
        </ButtonPhotos>
        <ContainerTable>
          <Table>
            <Table.Header>
              <Table.Header.Row>
                {showPhotos && (
                  <Table.Header.Col className="col-photo">#</Table.Header.Col>
                )}
                <Table.Header.Col className="col-name">Nome</Table.Header.Col>

                {/* Retorna as colunas dos dias */}
                {dias.map((dia) =>
                  (
                    <Table.Header.Col key={dia.IDHORARIOTURMA}>
                      <div>{dia.DATA}</div>
                      <div>
                        {dia.HORAINICIAL}
                        {' '}
                        -
                        {' '}
                        {dia.HORAFINAL}
                      </div>
                    </Table.Header.Col>
                  ))}

                {showLicao && (
                  <Table.Header.Col>Lição de casa</Table.Header.Col>
                )}
              </Table.Header.Row>
            </Table.Header>
            <Table.Body>
              {alunos.map((aluno) =>
                (
                  <Table.Body.Row key={aluno.RA}>
                    {showPhotos && (
                    <Table.Body.Cell className="col-photo">
                      <FotoAluno nomeAluno={aluno.NOMEALUNO} />
                    </Table.Body.Cell>
                    )}
                    <Table.Body.Cell className="col-name">
                      {aluno.NOMEALUNO.toLowerCase()}
                    </Table.Body.Cell>
                    {dias.map((dia) =>
                      (
                        <Table.Body.Cell
                          key={dia.IDHORARIOTURMA}
                          onClick={() =>
                            showModal(
                              aluno.RA,
                              dia.IDHORARIOTURMA,
                              dia.DATA,
                              dia.HORAINICIAL,
                            )}
                        >
                          {faltasAlunos.findIndex(
                            (faltaDia) =>
                              faltaDia.RA === aluno.RA
                          && dia.IDHORARIOTURMA === faltaDia.IDHORARIOTURMA
                          && faltaDia.PRESENCA === 'A',
                          ) !== -1 ? (
                            <BotaoAusente>AUSENTE</BotaoAusente>
                            ) : (
                              <BotaoPresente>PRESENTE</BotaoPresente>
                            )}
                        </Table.Body.Cell>
                      ))}
                    {showLicao && licoes && (
                    <LicaoDeCasa
                      setLicao={setLicao}
                      aluno={aluno.RA}
                      selected={
                        licoes
                        && (licoes[
                          licoes.findIndex((item) =>
                            item.aluno === aluno.RA)
                        ]?.licao)
                      }
                    />
                    )}
                  </Table.Body.Row>
                ))}
            </Table.Body>
          </Table>
        </ContainerTable>
      </Container>
      <Modal
        showModal={statusModal}
        hide={toggleModal}
        titulo="Justificativa da ausência"
      >
        <ContainerJustificativa ref={JustificativaRef}>
          {justificativas
            && justificativas.map((item) =>
              (
                <ButtonOcorrencia
                  type="button"
                  key={item.cod_ocorrencia_tipo}
                  value={item.cod_ocorrencia_tipo}
                  onClick={(e) =>
                    (item.has_obs
                      ? setShowObs({ show: true, code: item.cod_ocorrencia_tipo })
                      : handleCheck(
                        e,
                        selected.ra,
                        selected.idHorarioTurma,
                        selected.data,
                        selected.hora,
                      ))}
                >
                  {item.descricao}
                </ButtonOcorrencia>
              ))}
          <AnimatePresence>
            {showObs.show && (
              <ContainerObs
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={{ duration: 0.2 }}
              >
                <p>Observações:</p>
                <ContainerObs.TextArea
                  name="txtObs"
                  id="txtObs"
                  cols="30"
                  rows="5"
                  key="textarea"
                  onBlur={(e) =>
                    setObs(e.target.value)}
                  /* initial={{ height: 0 }}
                  animate={{ height: '100px' }}
                  transition={{ duration: 0.2 }} */
                />
                <ContainerObs.Buttons>
                  <Button
                    as="button"
                    className="btn-danger"
                    onClick={() =>
                      setShowObs({ show: false })}
                  >
                    Cancelar
                  </Button>
                  <Button
                    as="button"
                    value={showObs.code}
                    onClick={(e) =>
                      handleCheck(
                        e,
                        selected.ra,
                        selected.idHorarioTurma,
                        selected.data,
                        selected.hora,
                      )}
                  >
                    Salvar
                  </Button>
                </ContainerObs.Buttons>
              </ContainerObs>
            )}
          </AnimatePresence>
        </ContainerJustificativa>
      </Modal>
    </>
  );
}

export default TableAlunos;
