import {
  put, all, takeLatest, call,
} from 'redux-saga/effects';
import {
  format, getDaysInMonth, getMonth, getYear, parseISO,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { toast } from 'react-toastify';

import {
  loadCalendarReportSuccess,
  loadMonthReportRequest,
  loadMonthReportSuccess,
} from './actions';

import api from '../../../services/api';

function* loadCalendarReport({ payload }) {
  const { codTurma, idTurmaDisc } = payload;
  const response = [];

  response[0] = yield call(api.getAlunos, codTurma, idTurmaDisc);
  response[1] = yield call(api.getTurmaDisciplina, idTurmaDisc);
  if (response[0].status === 200 && response[1].status === 200) {
    yield put(loadCalendarReportSuccess(response));
    yield put(loadMonthReportRequest(new Date(), idTurmaDisc));
  }
}

function* loadMonthReport({ payload }) {
  const { date, idTurmaDisc } = payload;
  const daysInMonth = getDaysInMonth(date);
  const month = getMonth(date);
  const year = getYear(date);
  let formatedDate;
  let formatedDateFinal;
  try {
    formatedDate = format(new Date(year, month), 'yyyy-MM-dd').toString();
    formatedDateFinal = format(
      new Date(year, month, daysInMonth),
      'yyyy-MM-dd',
    ).toString();
  } catch (e) {
    const formatSafari = 'dd MMM yyyy';
    formatedDate = format(new Date(year, month), formatSafari).toString();
    formatedDateFinal = format(
      new Date(year, month, daysInMonth),
      formatSafari,
    ).toString();
  }
  const response = yield call(
    api.getOcorrenciasTurmaDiscRange,
    formatedDate,
    formatedDateFinal,
    idTurmaDisc,
  );
  if (response.status === 200) {
    const arrResponse = response.dados.map((dateMap) =>
      parseISO(dateMap.DATAOCORRENCIA));
    yield put(loadMonthReportSuccess(arrResponse));
  } else {
    toast.warn(
      `Não há registros no mês de ${format(date, 'MMMM', { locale: ptBR })}`,
    );
    yield put(loadMonthReportSuccess([]));
  }
}

export default all([
  takeLatest('@frequencia/LOAD_CALENDAR_REPORT_REQUEST', loadCalendarReport),
  takeLatest('@frequencia/LOAD_MONTH_REPORT_REQUEST', loadMonthReport),
]);
