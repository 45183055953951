import React from 'react';

import Container from './styles';

function Avatar({ src, size = '12' }) {
  return (
    <Container size={size}>
      <img src={src} alt="Avatar do professor" />
    </Container>
  );
}

export default Avatar;
