import styled from 'styled-components';

export const Container = styled.td`
  vertical-align: middle !important;
  border-bottom: 1px solid #e6e6e6;
`;

export const LicaoFarol = styled.div`
  display: flex;
  justify-content: space-around;
`;

LicaoFarol.Button = styled.button`
  width: 10px;
  padding: 10px;
  border: 0;
  border-radius: 50%;
  margin-right: 5px;
  opacity: 0.2;
  cursor: pointer;

  &.green {
    color: green;
    background: currentColor;
  }

  &.yellow {
    color: #f5dd2a;
    background: currentColor;
  }

  &.red {
    color: #d45050;
    background: currentColor;
  }

  &.selected {
    opacity: 1;
    box-shadow: 0px 0px 10px 0px currentColor;
    outline: none;
  }
`;
