import styled from 'styled-components';
import * as Select from '@radix-ui/react-select';

export const Container = styled.div`
    width: 100%;
    padding: 2rem;
`;

export const Label = styled.label`
    display: block;
    font-size: 1.4rem;
    color: var(--color-primary);
`;

export const Fieldset = styled.fieldset`
    border: none;
    width: 80%;
    margin: auto auto 1.5rem auto;

    &.grid-col {
        width: 100%;
        margin: 0;
    }
`;

export const Input = styled.input`
    width: 100%;
    height: 3rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
`;

export const Textarea = styled.textarea`
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    padding: 5px;
`;

export const DivColumns = styled.div`
    display: grid;
    grid-template-columns: 8fr 3fr;
    grid-gap: 1rem;
    justify-content: space-between;
    align-items: end;
    width: 80%;
    margin: auto auto 1.5rem auto;
`;

export const SelectRoot = styled(Select.Root)`
    all: unset;
`;

export const SelectTrigger = styled(Select.Trigger)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 13px;
    line-height: 1;
    height: 35px;
    gap: 5px;
    background-color: white;
    color: var(--color-primary);
    box-shadow: 0 2px 10px var(--color-hover);
    cursor: pointer;

    &:hover {
        background-color: var(--color-hover);
    }

    &[data-placeholder] {
        color: var(--color-primary);
    }
`;

export const SelectPortal = styled(Select.Portal)``;

export const SelectContent = styled(Select.Content)`
    overflow: hidden;
    background-color: white;
    border-radius: 6px;   
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
`;

export const SelectItem = styled(Select.Item)`
    font-size: 13px;
    line-height: 1;
    color: var(--color-primary);
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;

    &[data-highlighted] {
        outline: none;
        background-color: var(--color-primary);
        color: var(--color-white);
    }
`;

export const SelectItemText = styled(Select.ItemText)``;

export const SelectItemIndicator = styled(Select.ItemIndicator)`
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const SelectViewport = styled(Select.Viewport)``;

export const SelectValue = styled(Select.Value)``;
