import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  padding: 1.5rem;
  max-width: 1440px;
  margin: auto;

  @media screen and (max-width: 412px) {
    & > a {
      width: 100%;
    }
  }
`;

Container.Cards = styled.div`
  --gap: 1.2em;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  & > * {
    margin: var(--gap) 0 0 var(--gap);
    flex: 1;
    max-width: calc(50% - var(--gap));
  }

  @media screen and (max-width: 1024px) {
    & > * {
      flex-basis: calc(50% - var(--gap));
    }
  }

  @media screen and (max-width: 425px) {
    & > * {
      flex-basis: calc(100% - var(--gap));
      max-width: 100%;
    }
  }
`;

export const Titulo = styled.h1`
  font-size: 3.5rem;
  font-weight: 300;
`;

export const SubTitulo = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
`;
