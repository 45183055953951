import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 3rem;
  max-width: 1200px;
  margin: auto;
`;

export const HeaderButtons = styled.div`
  display: inline-flex;
`;

export const Titulo = styled.h1`
  margin-top: 2rem;
  font-size: 3rem;
  font-weight: 300;
`;

export const BotaoFiltrar = styled.button`
  ${(props) =>
    props.status && 'height: 50px;'}

  &:focus {
    outline: none;
  }
`;

export const Card = styled.div`
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

Card.Header = styled.div`
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 1rem 1.5rem;

  h4 {
    font-weight: 300;
    font-size: 2rem;
  }
`;

Card.Body = styled.div`
  background: #fff;
  padding: 1.25rem;
  height: 100%;
`;

Card.Body.Row = styled.div`
  width: 100%;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 1rem;

  input {
    width: 100%;
    height: auto;
    padding: 0.375rem 0.75rem;

    font-size: 1.5rem;
    font-weight: 300;
    color: var(--color-primary);
    background: #fff;

    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
`;

Card.Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  * {
    margin: 0;
  }

  @media screen and (max-width: 412px) {
    & > button {
      width: 100%;
    }
  }
`;
