import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  max-width: ${(props) =>
    `${props.width}rem`};
  min-height: ${(props) =>
    `${props.height}rem`};
  margin: 0;

  @media screen and (max-width: 900px) {
    font-size: 120%;
  }

  @media screen and (max-width: 550px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const Button = styled.button.attrs({ type: 'button' })`
  @media screen and (max-width: 550px) {
    --width: 100%;
  }

  --padding: ${(props) =>
    `${props.width / 40}rem`};
  --width: ${(props) =>
    `${props.width}rem`};
  --contentWidth: calc((var(--width) - 2px) - (var(--padding) * 2));

  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 var(--padding);

  border-radius: calc(var(--width) * 0.025);
  background: none;
  border: 1px solid
    ${(props) =>
    `${props.status ? 'var(--border-success)' : '#721c24'}`};

  width: var(--width);
  height: ${(props) =>
    `${props.height}rem`};
  cursor: pointer;

  /* @media screen and (max-width: 900px) {
    height: ${(props) =>
    `${props.height - 1}rem`} !important;
  } */

  & div.item {
    display: inline-block;
    width: calc(var(--contentWidth) / 2);
    padding: 0 var(--padding);
    margin: 0 !important;
    transition: all 1s;

    &:nth-child(1) {
      color: ${(props) =>
    `${props.status ? '#ccc' : '#721c24'}`};
    }
    &:nth-child(2) {
      color: ${(props) =>
    `${!props.status ? '#ccc' : 'var(--text-success)'}`};
    }
  }
  &:before {
    content: '';

    width: calc(var(--contentWidth) / 2);
    height: ${(props) =>
    `${props.height * 0.8}rem`};

    left: calc(var(--padding) / 2);
    z-index: -1;

    position: absolute;

    background-color: var(--background-error);
    border-radius: calc((var(--contentWidth) / 2) * 0.025);
    transition: all 1s;

    ${(props) =>
    props.status
    && `left: calc( (100% / 2) + (var(--padding) / 2) );
       background-color: var(--background-success);
    `};
  }
`;
