import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Button as BtnNotas } from '../../../../../styles/Notas';

export const Container = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
`;

export const Header = styled.div`
  width: 100%;
`;

export const Body = styled.div`
  width: 100%;
`;

Body.Button = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const TextArea = styled(motion.textarea)`
  width: 100%;
  border: 1px solid #e6e6e6;
  padding: 1rem;
  color: var(--color-primary);
`;

export const ContainerParametros = styled(motion.div)`
  width: 100%;
`;

ContainerParametros.Title = styled.h3`
  border-bottom: 1px solid #e6e6e6;
`;

ContainerParametros.List = styled(motion.ul)`
  font-size: 1.3rem;
`;

ContainerParametros.List.Item = styled(motion.li)`
  list-style: none;
  margin: 0;
  padding: 2rem;
  word-break: break-all;
  position: relative;
  min-height: 10rem;
  &:not(.bg-gray) > div {
    width: calc(100% + 5rem);
    margin: 0 -2.5rem;
  }

  &.bg-gray {
    background: #f0f0f0;
    margin: 0 -2.5rem;
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    @media screen and (min-width: 550px) {
      margin: 0 -2rem;
    }
    @media screen and (min-width: 1440px) {
      margin: 0 -1.8rem;
    }
  }
`;

ContainerParametros.List.Item.Buttons = styled(motion.div)`
  background: rgba(0, 0, 0, 0.61);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

ContainerParametros.List.Item.Buttons.Button = styled(motion.button)`
  border: 1px solid #fff;
  border-radius: 20%;

  width: 8rem;
  height: 8rem;
  color: #fff;
  font-size: 2.5rem;
  background: transparent;
  cursor: pointer;
`;

export const Button = styled(BtnNotas)`
  background-color: var(--color-primary);
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;

  margin-left: 0.25rem !important;

  &.btn-danger {
    background: #dc3545;

    &:hover,
    &:active {
      background: #dc3545;
    }
  }

  &.btn-success {
    background: #28a745;

    &:hover,
    &:active {
      background: #28a745;
    }
  }

  &:hover,
  &:active {
    background: var(--color-primary);
  }
`;
