import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';

import api from '../../../services/api';
import { getAlunosSuccess, getAlunosConfig } from './actions';

function* getAluno({ payload }) {
  const { codTurma, idTurmaDisc } = payload;
  const response = yield call(api.getAlunos, codTurma, idTurmaDisc);
  if (response.config) {
    yield put(getAlunosConfig(response.config));
  } else {
    yield put(getAlunosConfig([]));
  }
  if (response.status === 200) {
    yield put(getAlunosSuccess(response.dados));
  }
}

export default all([takeLatest('@aluno/GET_ALUNOS_REQUEST', getAluno)]);
