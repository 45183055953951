export function loginRequest(location) {
  return { type: '@login/LOGIN_REQUEST', payload: { location } };
}

export function loginFailure(message) {
  return { type: '@login/LOGIN_FAILURE', payload: { message } };
}

export function loginSuccess(user, parametros) {
  return { type: '@login/LOGIN_SUCCESS', payload: { user, parametros } };
}

export function loginMaster(dados) {
  return { type: '@login/LOGIN_MASTER', payload: { dados } };
}

export function selectUserMaster(email) {
  return { type: '@login/SELECT_USER_MASTER', payload: { email } };
}

export function logout() {
  return { type: '@login/LOGOUT' };
}
