import React from 'react';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

import Loading from '../Loading';
// import { Container } from './styles';

function AnimateContainer({ loading, children }) {
  return (
    <AnimatePresence>
      {loading ? <Loading key="loading" /> : children}
    </AnimatePresence>
  );
}

AnimateContainer.defaultProps = {
  loading: false,
};

AnimateContainer.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool,
};

export default AnimateContainer;
