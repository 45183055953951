/*

Modal que gerência os parâmetros de avaliação
Verifica se existem os parâmetros cadastrados, caso não existe abre a tela de cadastro

*/

import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import Modal from '../../Modal';
import useModal from '../../../hooks/useModal';
import { Spinner } from '../../Loading';
import SelectField from '../../SelectField';

import { ModalContainer, Button } from '../../../styles/Notas';
import useCadernos from '../../../hooks/useCadernos';
import * as RubricaActions from '../../../store2/modules/rubrica/actions';

import CadastroParametros from './Components/CadastroParametros';
import { isMedia } from '../../../helpers';

function ModalParametrosAvaliacao() {
  const dispatch = useDispatch();
  const [statusModal, toggleModal] = useModal();
  const [instrumentoParametros, setInstrumentosParametros] = useState([]);
  const {
    handleSelect,
    setComments,
    saveRubrica,
    validateInput,
    rubricaTotal,
  } = useCadernos();

  const rubricaData = useSelector((state) =>
    state.rubrica.rubricaData);
  const loadingSaveRubrica = useSelector((state) =>
    state.rubrica.loadingSaveRubrica);
  const escala = useSelector((state) =>
    state.rubrica.escala);
  const loadingComments = useSelector((state) =>
    state.rubrica.loadingComments);
  const comments = useSelector((state) =>
    state.rubrica.comments);
  const escalaValues = useSelector((state) =>
    state.rubrica.escalaValues);
  const showCreateParametros = useSelector((state) =>
    state.rubrica.showCreateParametros);
  const loadingSavingParametros = useSelector((state) =>
    state.rubrica.loadingSavingParametros);
  const loadingEscala = useSelector((state) =>
    state.rubrica.loadingEscala);

  const instrumento = useSelector((state) =>
    state.notas.instrumento);

  useEffect(() => {
    if (
      loadingSaveRubrica.loading === false
      && loadingSaveRubrica.status === 200
      && statusModal === true
    ) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSaveRubrica.loading, loadingSaveRubrica.status]);

  useEffect(() => {
    dispatch(RubricaActions.setModal({ statusModal, toggleModal }));

    // Verifica se o modal mudou para "close" e seta para false a tela de criação de parâmetros
    if (!statusModal) {
      dispatch(RubricaActions.setCreateParametros(false));
    }

    dispatch(
      RubricaActions.getEscalaSuccess({
        data: [
          { value: '0', label: 0 },
          { value: '1', label: 1 },
          { value: '2', label: 2 },
          { value: '3', label: 3 },
          { value: '4', label: 4 },
          { value: '5', label: 5 },
          { value: '6', label: 6 },
          { value: '7', label: 7 },
          { value: '8', label: 8 },
          { value: '9', label: 9 },
          { value: '10', label: 10 },
        ],
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusModal]);

  useEffect(() => {
    if (
      loadingSavingParametros.loading === false
      && loadingSavingParametros.status === 200
      && statusModal === true
    ) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSavingParametros.loading, loadingSavingParametros.status]);

  useEffect(() => {
    setInstrumentosParametros(
      instrumento.filter(
        (item) =>
          item.PARAMETRO !== 'ATIVIDADE' && !isMedia(item.CODPROVA),
      ),
    );
  }, [instrumento]);

  const handleSaveRubrica = () => {
    saveRubrica();
    toggleModal();
  };

  const handleSelectNotaParametro = (codProva, nota) => {
    handleSelect(codProva, nota);
  };

  return (
    <Modal
      showModal={statusModal}
      hide={toggleModal}
      width="80%"
      titulo={`Nome: ${rubricaData.nomeAluno} - Turma: ${rubricaData.turma}`}
      createParametros={showCreateParametros}
    >
      <ModalContainer className="rubrica">
        <AnimatePresence>
          {(showCreateParametros && (
            <CadastroParametros
              key="cadastroParametros"
              initial={{ opacity: 1 }}
            />
          )) || (
            <motion.div key="parametrosNotas" exit={{ opacity: 0 }}>
              <h2 className="text-3xl">{rubricaData.disciplina}</h2>
              <Button
                onClick={() =>
                  dispatch(RubricaActions.setCreateParametros(true))}
              >
                Editar parâmetros
              </Button>
              <table>
                <thead>
                  <tr className="header">
                    <th>Parâmetro de avaliação</th>
                    <th style={{ borderRightColor: '#fff' }}>
                      Escala de proficiência
                    </th>
                    <th style={{ borderRightColor: '#032b50' }}>Nota</th>
                  </tr>
                </thead>
                <tbody>
                  {instrumentoParametros.map((inst) => {
                    let defaultValue = '-';
                    const notaIndex = Object.keys(escalaValues).findIndex(
                      (i) =>
                        i === inst.CODPROVA,
                    );
                    const nota = Object.values(escalaValues)[notaIndex];
                    if (notaIndex > -1) {
                      defaultValue = Math.round(nota / (inst.PESO / 100));
                    }

                    return (
                      statusModal && (
                        <tr key={inst.CODPROVA}>
                          <td>
                            {inst.PARAMETRO}
                            <p
                              style={{
                                fontSize: '1.5rem',
                                paddingTop: '0.5rem',
                              }}
                            >
                              <b>
                                Peso:
                                {' '}
                                {inst.PESO}
                                %
                              </b>
                            </p>
                          </td>
                          <td
                            style={{
                              width: '100px',
                              textAlign: 'center',
                            }}
                          >
                            {(loadingEscala && <Spinner size={0.3} />) || (
                              <SelectField
                                items={escala}
                                onChange={(e) =>
                                  handleSelectNotaParametro(
                                    inst.CODPROVA,
                                    (
                                      (Number(e.currentTarget.value)
                                        * inst.PESO)
                                      / 100
                                    ).toString(),
                                  )}
                                defaultValue={defaultValue}
                              />
                            )}
                          </td>
                          <td>
                            {nota || nota === 0 ? nota : '-'}
                            {' '}
                            /
                            {' '}
                            {inst.PESO / 10}
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td />
                    <td />
                    <td>{rubricaTotal}</td>
                  </tr>
                </tfoot>
              </table>
              <div className="comentario-aluno">
                <label htmlFor="comments">
                  <span>Comentários para o aluno</span>
                  {loadingComments ? (
                    <Spinner />
                  ) : (
                    <textarea
                      id="comments"
                      style={{ padding: '1rem' }}
                      rows="5"
                      className="border-[1px] rounded"
                      value={comments.observacoes}
                      onChange={(e) =>
                        setComments({
                          ...comments,
                          observacoes: e.currentTarget.value,
                        })}
                      disabled={loadingComments}
                    />
                  )}
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                {validateInput() && (
                  <Button onClick={handleSaveRubrica}>Salvar</Button>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ModalContainer>
    </Modal>
  );
}

export default ModalParametrosAvaliacao;
