/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = {
  livros: [], loading: false, activeTab: 'listLivros', cronogramas: [],
};

export default function livros(state = INITIAL_STATE, action) {
  const printState = (draft) =>
    JSON.parse(JSON.stringify(draft));

  return produce(state, (draft) => {
    switch (action.type) {
      case '@livros/GET_LIVROS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@livros/GET_LIVROS_SUCCESS': {
        draft.livros = action.payload;
        draft.loading = false;
        break;
      }
      case '@livros/SAVE_LIVROS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@livros/SAVE_LIVROS_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@livros/SAVE_CRONOGRAMA_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@livros/SAVE_CRONOGRAMA_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@livros/DELETE_CRONOGRAMA_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@livros/DELETE_CRONOGRAMA_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@livros/GET_CRONOGRAMAS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@livros/GET_CRONOGRAMAS_SUCCESS': {
        if (action.payload.length > 0) {
          const { idLivro } = action.payload[0];
          draft.cronogramas[idLivro] = action.payload;
        } else {
          draft.cronogramas = [];
        }
        draft.loading = false;
        break;
      }
      case '@livros/SET_ACTIVE_TAB': {
        draft.activeTab = action.payload.activeTab;
        break;
      }
      default:
    }
  });
}
