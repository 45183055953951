import React from 'react';

import Container from './styles';

function SelectField({ items = [], onChange, defaultValue = '-' }) {
  return (
    <Container
      onChange={onChange}
      defaultValue={defaultValue}
      key="select"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <option hidden value>
        -
      </option>
      {items.length > 0
        && items?.map((item) =>
          (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
    </Container>
  );
}

export default SelectField;
