export function getProfessorRequest() {
  return { type: '@professor/GET_REQUEST' };
}

export function getProfessorSuccess(payload) {
  return { type: '@professor/GET_SUCCESS', payload };
}

export function getSeriesProfessorRequest(payload) {
  return { type: '@professor/GET_SERIES_REQUEST', payload };
}

export function getSeriesProfessorSuccess(payload) {
  return { type: '@professor/GET_SERIES_SUCCESS', payload };
}

export function getDisciplinasSerieProfessorRequest(payload) {
  return { type: '@professor/GET_DISCIPLINAS_SERIE_REQUEST', payload };
}

export function getDisciplinasSerieProfessorSuccess(payload) {
  return { type: '@professor/GET_DISCIPLINAS_SERIE_SUCCESS', payload };
}
