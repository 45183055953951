import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import persistReducers from './persistReducers';

import reducers from './modules/rootReducer';
import sagas from './modules/rootSaga';
// import reactotron from '../config/ReactotronConfig';

const middlewares = [];

/* const sagaMonitor = process.env.NODE_ENV === 'development'
  ? console.tron.createSagaMonitor()
  : null; */

const sagaMonitor = null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

middlewares.push(sagaMiddleware);

/* const composer = process.env.NODE_ENV === 'development'
  ? compose(applyMiddleware(...middlewares), window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  : compose(applyMiddleware(...middlewares)); */
const composeEnhancers = process.env.NODE_ENV === 'development'
  ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
  ) : compose;

const composer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(persistReducers(reducers), /* preloadedState, */ composer);
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { persistor, store };
