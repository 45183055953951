import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ButtonCard = styled.button`
  text-align: left;
  background: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1.3rem 1rem;

  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
  color: var(--color-primary);
  width: 100%;
  display: block;
  cursor: pointer;
`;

export const Container = styled(motion.div)`
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
`;

Container.Header = styled.div`
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 1rem 1.5rem;

  h4 {
    font-weight: 300;
    font-size: 2.5rem;
  }
`;

Container.Body = styled.div`
  background: #fff;
  padding: 1.25rem;
  height: 100%;

  h4 {
    color: #868686;
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 1rem;
    margin-left: 0.8rem;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 1.5rem;
    }
  }
`;
