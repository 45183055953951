import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useModal from '../Modal/hooks/useModal';
import useNotas from '../../hooks/useNotas';

import {
  TextFiltrarAluno,
  Container,
  ContainerTable,
  Table,
  ButtonPhotos,
  ContainerButtons,
} from '../../styles/Notas';

import ListRowParametros from './ListRowParametros';
import ModalParametrosAvaliacao from './ModalParametrosAvaliacao';
import ModalParametrosAvaliacao20241Sem from './ModalParametrosAvaliacao-2024-1SEM';
import { Aluno } from '../../domain/aluno';

interface Props {
  alunos: Aluno[];
}

export default function ListAlunosNotasAneSintese({ alunos }: Props) {
  const codPerlet = alunos[0].CODPERLET;
  const [showPhotos, setShowPhotos] = useState(false);
  const [statusModal, toggleModal] = useModal();
  const { loadingSaveRubrica, instrumento } = useSelector(
    (state) =>
    // @ts-ignore FixMeLater
      state.notas,
  );

  const { filtro, setFiltro } = useNotas();

  const alunosList: Aluno[] = alunos.filter((aluno) =>
    aluno.NOMEALUNO.includes(filtro.toUpperCase()));

  useEffect(() => {
    if (statusModal && loadingSaveRubrica.status === 200) {
      // @ts-ignore FixMeLater
      toggleModal();
    }
  }, [loadingSaveRubrica, statusModal, toggleModal, instrumento]);

  const ModalParametrosAvaliacaoWrapper = useCallback(() => {
    if (Object.keys(instrumento).length === 0) {
      return <></>;
    }
    if (
      codPerlet === '2024'
      && [3, 4].includes(Number(instrumento[0].CODETAPA))
    ) {
      return <ModalParametrosAvaliacao20241Sem />;
    }
    return <ModalParametrosAvaliacao />;
  }, [codPerlet, instrumento]);

  return (
    <>
      <Container>
        {instrumento.length > 0 && (
        <>
          <TextFiltrarAluno.Label>
            <TextFiltrarAluno
              onChange={(e) =>
                setFiltro(e.currentTarget.value)}
              value={filtro}
            />
            <TextFiltrarAluno.Span>Filtrar aluno</TextFiltrarAluno.Span>
          </TextFiltrarAluno.Label>
          <ContainerButtons>
            <ContainerButtons.Left>
              <ButtonPhotos onClick={() =>
                setShowPhotos((old) =>
                  !old)}
              >
                {(!showPhotos && 'Mostrar ') || 'Ocultar '}
                fotos
              </ButtonPhotos>
            </ContainerButtons.Left>
          </ContainerButtons>
          <ContainerTable>
            <Table>
              <Table.Header>
                <Table.Header.Row>
                  {showPhotos && (
                  <Table.Header.Col className="col-photo">
                    #
                  </Table.Header.Col>
                  )}
                  <Table.Header.Col className="col-name">
                    Nome
                  </Table.Header.Col>
                  <Table.Header.Col>Rubricas</Table.Header.Col>
                </Table.Header.Row>
              </Table.Header>
              <Table.Body>
                {/* LISTA OS ALUNOS */}
                {alunosList.map((aluno) =>
                  (
                    <ListRowParametros
                      aluno={aluno}
                      showPhotos={showPhotos}
                      key={aluno.RA}
                      instrumento={instrumento}
                    />
                  ))}
              </Table.Body>
            </Table>
          </ContainerTable>
        </>
        )}
      </Container>
      <ModalParametrosAvaliacaoWrapper />
    </>
  );
}
