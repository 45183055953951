import { useState, useEffect, useCallback } from 'react';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    if (isShowing) {
      document.body.classList.add('modal--open');
      document.documentElement.classList.add('modal--open');
      document.getElementById('root').classList.add('modal--open');
    } else {
      document.body.classList.remove('modal--open');
      document.documentElement.classList.remove('modal--open');
      document.getElementById('root').classList.remove('modal--open');
    }
  }, [isShowing]);

  const toggle = useCallback(() => {
    setIsShowing(!isShowing);
  }, [isShowing]);

  return [isShowing, toggle];
};

export default useModal;
