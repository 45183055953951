import styled from 'styled-components';
import { motion } from 'framer-motion';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(motion.div)`
  padding: 0 1.5rem;
  max-width: 1440px;
  margin: auto;
`;

Container.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

Container.HeaderButtons = styled.div`
  display: inline-flex;
`;
