import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled(motion.div)`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

Container.Header = styled.div`
  cursor: pointer;
  color: var(--primary-color);
  border-bottom: 0.1rem solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    margin: 0.8rem 0 0.8rem 0.8rem;
    font-size: 1.3rem;

    @media screen and (max-width: 550px) {
      font-size: 1.8rem;
    }
  }
`;

Container.CloseButton = styled.button`
  margin: 0 0.8rem 0 0;
  padding: 0;
  line-height: 0;
  transition: all 100ms linear;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 0;
  color: #032b50;
  cursor: pointer;
  font-size: 4rem;
`;

Container.Content = styled(motion.div)`
  margin: auto;
  padding: 10px 20px;
  background-color: #fff;
  width: ${({ width }) =>
    width};
  box-shadow: 0 0 20px 2px #032b50;
  border-radius: 2px;
  align-items: center;
  /* overflow: scroll; */

  @media screen and (max-width: 550px) {
    font-size: 1.8rem;
    width: 50rem;
  }
`;

Container.Footer = styled.div`
  border-top: 1px solid #ccc;
  background-color: red;
`;

export default Container;
