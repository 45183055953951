import styled from 'styled-components';

export const ButtonSemestre = styled.button`
  background: transparent;
  color: #032b50;
  font-size: 1.5rem;
  border: 0;
  padding: 0.2rem;
  cursor: pointer;
  border-bottom: 0.1rem solid transparent;

  &:hover {
    border-bottom: 0.1rem solid var(--color-primary);
  }

  &.active {
    background: var(--color-primary);
    color: var(--color-white);
    padding: 1rem;
    border-radius: 0.5rem;
  }

  &:not(.active) {
    opacity: 0.3;
  }

  &:focus {
    outline: 0;
  }
`;

export const CardSemestre = styled.div`
  padding: 1rem;
  align-items: center;
  display: flex;
  background: #fff;
`;

export const SeparatorSemestre = styled.span`
  background: rgb(146, 182, 204);
  width: 0.2rem;
  margin: 0 1rem;
  height: 2.5rem;
`;

export const HeaderEtapas = styled.div`
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  background-color: #032b50;
  display: flex;

  &.loading {
    background: transparent;
  }
`;

export const ButtonEtapasNotas = styled.button`
  background: ${(props) =>
    (props.active && '#7252a1') || 'transparent'};
  color: #fff;
  border: 0;
  font-weight: 700;
  padding: 1rem;
  border-left: 0.1rem solid #828282;
  font-size: 1.4rem;
  transition: all ease-in-out 500ms;
  cursor: pointer;

  &:hover {
    background: #7252a1;
  }

  &:focus {
    outline: 0;
  }
`;

export const ButtonAvaliacoes = styled.button`
  padding: 1.25rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: all ease-in-out 500ms;

  &.active,
  &:hover {
    background: #7252a1;
    border-color: #7252a1;
    color: #fff;
    font-weight: 700;
  }
`;
