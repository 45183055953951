import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../../components/Loading';

import * as LoginActions from '../../store2/modules/login/actions';

import { Container, ContainerCallback } from './styles';

export default function LoginCallback({ location }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LoginActions.loginRequest(location));
  }, [dispatch, location]);

  return (
    <Container>
      <ContainerCallback>
        <Loading />
      </ContainerCallback>
    </Container>
  );
}
