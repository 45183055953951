import * as yup from 'yup';

export interface ICronograma {
    idCronograma: number
    idLivro: number
    inicio: Date
    fim: Date
    paginaInicial: number
    paginaFinal: number
    localLeitura: 'Casa' | 'Sala de aula'
}

export interface ILivro {
    idLivro: number
    livro: string
    qtdePaginas: number
    objetivos: string
    comotrabalhar: string
    cobranca: string
    status: number
}

export const schema = yup.object({
  idHabilitacaoFilial: yup.string().required(),
  codDisc: yup.string().required(),
  livro: yup.string().required(),
  qtdePaginas: yup.number().positive().required(),
  objetivos: yup.string().required(),
  comotrabalhar: yup.string().required(),
  cobranca: yup.string().required(),
}).required();
