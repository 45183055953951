import React, { Component, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FotoAluno from '../../FotoAluno';

import useNotas from '../../../hooks/useNotas';

import { Table, Button } from '../../../styles/Notas';
import { Spinner } from '../../Loading';
import useCadernos from '../../../hooks/useCadernos';

import { isMedia } from '../../../helpers';
/*
  Object Example:
  {
      showPhotos: Component,
      aluno: {
          ra: string,
          nome: string,
      },
      instrumento: {
          codProva,
          codEtapa
      }
  }
*/

interface AlunoObj {
  showPhotos: Component;
  aluno: {
    ra: string;
    nome: string;
  };
}

function ListRowParametros({ aluno, showPhotos, instrumento }) {
  const [alunoObj, setAlunoObj] = useState<AlunoObj|null>(aluno);
  const { modalParametros } = useCadernos();
  const { getNota, checkNaoEntregue } = useNotas();

  const { stackSavingRubrica, loadingSavingAtividade } = useSelector(
    (state) =>
    // @ts-ignore FixMeLater
      state.rubrica,
  );
  const [savingStatus, setSavingStatus] = useState({ statusLoading: { loading: '', status: '' } });

  // Checa se a rubrica esta sendo processada------------
  useEffect(() => {
    if (aluno) {
      const currentAluno = stackSavingRubrica.find(
        (item) =>
          item.ra === aluno.RA && item.caderno === instrumento[0].CADERNO,
      );
      if (currentAluno) {
        setSavingStatus(currentAluno);
      } else {
        setSavingStatus({ statusLoading: { loading: '', status: '' } });
      }
    }
  }, [aluno, instrumento, stackSavingRubrica]);
  // ----------------------------------------------------

  useEffect(() => {
    const compPhotos = showPhotos && (
      <Table.Body.Cell className="col-photo">
        <FotoAluno nomeAluno={aluno.NOMEALUNO} />
      </Table.Body.Cell>
    );

    setAlunoObj({
      showPhotos: compPhotos,
      aluno: { ra: aluno.RA, nome: aluno.NOMEALUNO },
    });
  }, [aluno.NOMEALUNO, aluno.RA, showPhotos]);

  const classCell = (status) => {
    switch (status) {
      case 200:
        return 'media-caderno success';
      case 500:
        return 'media-caderno error';

      default:
        return 'media-caderno';
    }
  };

  const colValue = (codProva, codEtapa, ra) => {
    const nota = getNota(codProva, codEtapa, ra);
    if (nota !== '') {
      if (checkNaoEntregue(codEtapa, codProva, ra)) {
        return 'Não entregue';
      }
      return nota;
    }

    return 'Rubricas';
  };

  return (
    (alunoObj?.aluno && (
      <Table.Body.Row key={alunoObj.aluno.ra} className="linha-nota">
        {alunoObj.showPhotos}
        <Table.Body.Cell className="col-name">
          {alunoObj.aluno.nome}
        </Table.Body.Cell>
        {instrumento.map(
          (inst) =>
            isMedia(inst.CODPROVA) && (
              <Table.Body.Cell
                key={inst.CODPROVA}
                className={classCell(savingStatus.statusLoading.status)}
              >
                {loadingSavingAtividade.loading
                || savingStatus.statusLoading.loading ? (
                  <Spinner size={0.3} />) : (
                    <Button onClick={() =>
                      modalParametros(aluno, inst)}
                    >
                      {colValue(inst.CODPROVA, inst.CODETAPA, aluno.RA)}
                    </Button>
                  )}
              </Table.Body.Cell>
            ),
        )}
      </Table.Body.Row>
    )) || (
      <tr>
        <td />
      </tr>
    )
  );
}

export default ListRowParametros;
