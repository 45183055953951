import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';

import api from '../../../services/api';
import { getTurmaDisciplinaSuccess } from './actions';

function* getTurmaDisciplina({ payload }) {
  const { idTurmaDisc } = payload;
  const response = yield call(api.getTurmaDisciplina, idTurmaDisc);
  if (response.status === 200) {
    yield put(getTurmaDisciplinaSuccess(response.dados));
  }
}

export default all([
  takeLatest('@turma/GET_TURMADISCIPLINA_REQUEST', getTurmaDisciplina),
]);
