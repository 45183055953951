import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, shallowEqual } from 'react-redux';
import api from '../services/api';

// import { Container } from './styles';

function useNotas() {
  const [currentNota, setCurrentNota] = useState('');
  const [filtro, setFiltro] = useState('');

  const idTurmaDisc = useSelector(
    (state) =>
      state.aula.selected.IDTURMADISC,
  );

  const notas = useSelector((state) =>
    state.notas.notasEtapa, shallowEqual);

  const turmaDisciplina = useSelector((state) =>
    state.turma.turmaDisciplina);

  const nomeDisc = turmaDisciplina.NOMEDISC && turmaDisciplina.NOMETURMA ? `${turmaDisciplina.NOMEDISC} - ${turmaDisciplina.NOMETURMA}` : '';

  const pressEnter = (evt) => {
    const currentCode = evt.which || evt.code;
    const validate = [
      'Backspace',
      ',',
      '.',
      'Enter',
      'Tab',
      'ArrowRight',
      'ArrowLeft',
      'ArrowDown',
      'ArrowUp',
    ];
    let currentKey = evt.key;
    if (!currentKey) {
      currentKey = String.fromCharCode(currentCode);
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(currentKey) && !validate.includes(currentKey)) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    if (currentKey === 'Enter') {
      const inputs = document.querySelectorAll('td input');
      let item = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const value of inputs.entries()) {
        if (value[1] === document.activeElement) {
          item = value[0] + 1;
        }
      }
      if (inputs.item(item)) {
        inputs.item(item).focus();
      } else {
        inputs.item(0).focus();
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const saveNota = async (inst, aluno, nomeDiscLocal, e) => {
    let val = e.currentTarget.value;
    const comp = e.currentTarget;

    // Verifica se a nota inserida é igual a anterior e que não deu erro, se for, cancela a inclusão
    if (val === currentNota && !comp.classList.contains('errorNota')) {
      setCurrentNota('');
      return false;
    }
    comp.classList.add('jump');
    val = val.replace('.', ',');
    const {
      CODPROVA, CODETAPA, IDTURMADISC, DESCETAPA, DESCRICAO,
    } = inst;
    const {
      RA, IDHABILITACAOFILIAL, CODCURSO, CODHABILITACAO,
    } = aluno;
    try {
      const response = await api.setNota({
        codProva: CODPROVA,
        codEtapa: CODETAPA,
        ra: RA,
        idTurmaDisc: IDTURMADISC || idTurmaDisc,
        nota: val,
        nomeAluno: aluno.NOMEALUNO,
        disciplina: nomeDiscLocal,
        etapa: DESCETAPA || DESCRICAO,
        notaAnterior: currentNota || null,
        idHabilitacaoFilial: IDHABILITACAOFILIAL,
        CODCURSO,
        CODHABILITACAO,
      });
      if (response.status === 200) {
        comp.classList.remove('errorNota');
        if (val === '') {
          comp.classList.remove('saveNota');
        } else if (!comp.classList.contains('saveNota')) {
          comp.classList.add('saveNota');
        }
      } else {
        throw new Error();
      }
      return response;
    } catch (error) {
      comp.classList.add('errorNota');
      comp.classList.remove('saveNota');
      toast.error(`Erro! ${aluno.NOMEALUNO} - ${nomeDiscLocal} - ${val}`);
    } finally {
      comp.classList.remove('jump');
    }
  };

  const getNota = useCallback(
    (codProva, codEtapa, ra) => {
      if (notas !== null && notas !== undefined) {
        const notaAluno = notas.filter(
          (nota) =>
            Number(codProva) === Number(nota.CODPROVA)
            && Number(codEtapa) === Number(nota.CODETAPA)
            && nota.RA === ra && nota.DESCRICAO === 'MATRICULADO',
        );
        if (notaAluno[0] && notaAluno[0].NOTA) {
          return parseFloat(notaAluno[0].NOTA);
        }
      }
      return '';
    },
    [notas],
  );

  const checkNaoEntregue = (codEtapa, codProva, ra) => {
    if (notas !== null && notas !== undefined) {
      const notaAluno = notas.filter(
        (nota) =>
          Number(codEtapa) === Number(nota.CODETAPA)
          && nota.RA === ra && nota.DESCRICAO === 'MATRICULADO'
          && nota.CODPROVA.startsWith(codProva),
      );
      const notaRubrica = notaAluno.find(
        (item) =>
          Number(item.CODPROVA) === Number(codProva),
      )?.NOTA;
      if (notaRubrica === undefined || notaRubrica === null) {
        return false;
      }
      if (Number(notaRubrica) === 0) {
        const naoEntregue = notaAluno.filter(
          (item) =>
            Number(item.CODPROVA) !== Number(codProva),
        ).reduce((acc, cv) =>
          acc.NOTA === undefined && cv.NOTA === undefined, true);
        return naoEntregue;
      }
    }
    return false;
  };

  return {
    pressEnter,
    saveNota,
    getNota,
    filtro,
    setFiltro,
    setCurrentNota,
    nomeDisc,
    currentNota,
    checkNaoEntregue,
  };
}

export default useNotas;
