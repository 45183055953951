/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = { turmaDisciplina: [], loading: false };

export default function turma(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@turma/GET_TURMADISCIPLINA_REQUEST': {
        draft.turmaDisciplina = action.payload;
        draft.loading = true;
        break;
      }
      case '@turma/GET_TURMADISCIPLINA_SUCCESS': {
        draft.turmaDisciplina = action.payload;
        draft.loading = false;
        break;
      }
      case '@turma/CLEAR_TURMA': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
