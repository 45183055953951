import styled, { css } from 'styled-components';

const types = {
  primary: css`
    background: #cce5ff;
    border-color: #b8daff;
    color: #004085;
  `,
  warning: css`
    background: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
  `,
  success: css`
    background: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  `,
  danger: css`
    background: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  `,
  info: css`
    background: #d1ecf1;
    border-color: #bee5eb;
    color: #0c5460;
  `,
  light: css`
    background: #fefefe;
    border-color: #ccc;
    color: #818182;
  `,
};

export const Container = styled.div`
  position: relative;
  padding: 1rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1.6rem;

  ${({ type }) =>
    types[type]}

  & .badge {
    font-size: 1rem;
    white-space: nowrap;
    padding: 0.25rem 0.4rem;
    font-weight: 500;
    border-radius: 0.25rem;
    line-height: 1;
    vertical-align: middle;

    &.save {
      background: rgba(0, 128, 0, 0.16);
      color: #000;
    }

    &.not-saved {
      background: #f8d7da;
      color: #721c24;
    }
  }
`;

export default Container;
