import { produce } from 'immer';

const INITIAL_STATE = {
  alunos: [],
  turmaDisciplina: [],
  dias: [],
  faltasAlunos: [],
  tipoFalta: [],
  modalFrequencia: false,
  loadingCalendarReport: true,
  highlightDates: [],
};

export default function frequencia(state = INITIAL_STATE, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    switch (action.type) {
      case '@frequencia/LOAD_TABLE': {
        const {
          alunos, turmaDisciplina, dias, faltasAlunos,
        } = action.payload;

        draft.alunos = alunos;
        draft.turmaDisciplina = turmaDisciplina;
        draft.dias = dias;
        draft.faltasAlunos = faltasAlunos;
        break;
      }
      case '@frequencia/GET_TIPO_FALTA': {
        const { tipoFalta } = action.payload;
        draft.tipoFalta = tipoFalta;
        break;
      }
      case '@frequencia/ADD': {
        const { index } = action.payload;
        if (process.env.NODE_ENV === 'development') {
          console.tron.log(action.payload);
        }

        let novo = {};
        if (action.payload.frequencia) {
          const {
            idTurmaDisc,
            idHorarioTurma,
            idPlanoAula,
            ra,
            data,
            hora,
            tipoCode,
            obs,
          } = action.payload.frequencia;
          novo = {
            IDTURMADISC: idTurmaDisc,
            IDPLANOAULA: idPlanoAula,
            IDHORARIOTURMA: idHorarioTurma,
            RA: ra,
            DATA: data,
            HORA: hora,
            PRESENCA: 'A',
            TIPO: tipoCode,
            OBS: obs,
          };
          if (index < 0) {
            draft.faltasAlunos.push(novo);
            break;
          }
        }

        if (draft.faltasAlunos[index].PRESENCA === 'A') {
          draft.faltasAlunos[index].PRESENCA = 'P';
          draft.faltasAlunos[index].TIPO = null;
          draft.faltasAlunos[index].OBS = '';
        } else {
          draft.faltasAlunos[index] = novo;
          draft.modalFrequencia = true;
        }
        break;
      }
      case '@frequencia/TOGGLE_MODAL': {
        draft.modalFrequencia = !draft.modalFrequencia;
        break;
      }
      case '@frequencia/CLEAR_TIPO': {
        draft.loadingCalendarReport = true;
        // eslint-disable-next-line no-param-reassign
        draft.faltasAlunos.map((falta) =>
          // eslint-disable-next-line no-param-reassign
          delete falta.TIPO);
        break;
      }
      case '@frequencia/LOAD_CALENDAR_REPORT_SUCCESS': {
        const [alunos, turmaDisciplina] = action.payload;
        draft.turmaDisciplina = turmaDisciplina.dados;
        draft.alunos = alunos.dados;
        break;
      }
      case '@frequencia/LOAD_MONTH_REPORT_REQUEST': {
        draft.loadingCalendarReport = true;
        break;
      }
      case '@frequencia/LOAD_MONTH_REPORT_SUCCESS': {
        const { dataMonth } = action.payload;
        draft.loadingCalendarReport = false;
        draft.highlightDates = [
          {
            'react-datepicker__day--highlighted-custom-1': dataMonth,
          },
        ];
        break;
      }
      case '@frequencia/TOGGLE_LOADING': {
        const { status } = action.payload;
        draft.loadingCalendarReport = status;
        break;
      }
      case '@frequencia/RESET': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
