export function getAlunosRequest(codTurma, idTurmaDisc) {
  return {
    type: '@aluno/GET_ALUNOS_REQUEST',
    payload: { codTurma, idTurmaDisc },
  };
}

export function getAlunosSuccess(payload) {
  return { type: '@aluno/GET_ALUNOS_SUCCESS', payload };
}

export function getAlunosConfig(payload) {
  return { type: '@aluno/GET_ALUNOS_CONFIG', payload };
}

export function clearAlunos() {
  return { type: '@aluno/CLEAR_ALUNOS' };
}
