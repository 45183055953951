import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Home from './pages/Home';
import AulasDiaFrequencia from './pages/AulasDiaFrequencia';
import FrequenciaDiaria from './pages/FrequenciaDiaria';
import FrequenciaGeral from './pages/FrequenciaGeral';
import AulasGeral from './pages/AulasGeral';
import RelatorioFrequencia from './pages/RelatorioFrequencia';
import Notas from './pages/Notas';
import LoginCallback from './pages/Login/LoginCallback';
import Login from './pages/Login';
import Livros from './pages/Livros';
import Master from './pages/Master';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/Home" component={Home} isPrivate />
      <Route
        path="/AulasDiaFrequencia"
        component={AulasDiaFrequencia}
        isPrivate
      />
      <Route path="/AulasGeral/faltas" component={AulasGeral} isPrivate />
      <Route path="/AulasGeral/notas" component={AulasGeral} isPrivate />
      <Route
        path="/AulasGeral/relatorioFrequencia"
        component={AulasGeral}
        isPrivate
      />
      <Route path="/FrequenciaGeral" component={FrequenciaGeral} isPrivate />
      <Route path="/FrequenciaDiaria" component={FrequenciaDiaria} isPrivate />
      <Route
        path="/RelatorioFrequencia"
        component={RelatorioFrequencia}
        isPrivate
      />
      <Route path="/Notas" component={Notas} isPrivate />
      <Route path="/LoginCallback" component={LoginCallback} />
      <Route path="/Master" component={Master} isPrivate />
    </Switch>
  );
}
