import { put, all, takeLatest } from 'redux-saga/effects';

import history from '../../../services/history';
import { selectAulaSuccess } from './actions';

function* selectAula({ payload }) {
  const { nextPage } = payload;
  yield put(selectAulaSuccess(payload));
  history.push(`/${nextPage}`);
}

export default all([takeLatest('@aula/SELECT_AULA_REQUEST', selectAula)]);
