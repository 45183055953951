import React from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Modal from '../../../Modal';
import { ModalContainer } from '../../../TableAlunos/styles';
import { setEscalaValues } from '../../../../store2/modules/rubrica/actions';

// import { Container } from './styles';

function ModalNivel({
  statusModal, toggleModal, data, indexRubrica,
}) {
  const dispatch = useDispatch();
  const escala = useSelector((state: RootStateOrAny) =>
    state.rubrica.escala);
  const escalaValues = useSelector((state: RootStateOrAny) =>
    state.rubrica.escalaValues);

  const handleNivel = (item) => {
    dispatch(setEscalaValues({ ...escalaValues, [item.IDPARAMAV]: Number(item.NOTA.replace(',', '.')) }));
    toggleModal();
  };

  return (
    <Modal
      showModal={statusModal}
      hide={toggleModal}
      width="50%"
      titulo="Nível do eixo"
    >

      <ModalContainer className="rubrica">
        <div className="">
          {escala.dados.filter((item) =>
            item.IDPARAMAV === data.instrumento.parametrosAvaliacao[indexRubrica].CODPROVA)
            .map((item) =>
              (
                <button
                  className="
                  block
                  w-full
                  py-2
                  px-4
                  border-[1px]
                  rounded-sm
                  mt-2
                  text-xl
                  text-left
                  hover:bg-slate-200
                  transition-colors
                "
                  onClick={() =>
                    handleNivel(item)}
                  type="button"
                  key={item.ID}
                >
                  {`${item.ROTULO} - ${item.DESCRICAO}` }
                </button>
              ))}
        </div>
      </ModalContainer>
    </Modal>
  );
}

export default ModalNivel;
