import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
/* React DatePicker */
.react-datepicker {
  width: 100% !important;
}

.react-datepicker__header {
  background: var(--color-primary) !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff !important;
  text-transform: capitalize;
}

.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker__day-names {
  font-size: 1.2rem !important;
  font-weight: 400;
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
}

.react-datepicker__day-name {
  color: #fff !important;
  font-weight: 600;
  width: auto;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
  font-size: 1.2rem !important;
  /* border-top: 1px solid #eee; */
}

.react-datepicker__day {
  width: 100% !important;
  padding: 0.8rem;
  background: #fff;
  color: var(--color-primary);
  font-weight: normal;
  margin: 1px;
}

.react-datepicker__day--today {
  border: 1px solid var(--color-primary);
  font-weight: 600;
}

.react-datepicker__day--selected {
  background-color: var(--color-primary);
  color: #fff;
  font-weight: 600;
}

.react-datepicker__day--disabled {
  color: #ccc;
}

.react-datepicker__today-button {
  background-color: var(--color-primary);
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
}

.react-datepicker__navigation {
  height: 8px;
  transform: scale(2.5);
  margin-top: 0.5rem;
}

.react-datepicker__navigation--previous {
  left: 20px;
  border-right-color: #fff;
}

.react-datepicker__navigation--next {
  right: 20px;
  border-left-color: #fff;
}

.react-datepicker-wrapper {
  display: block;
}
/* End React DatePicker */
`;
