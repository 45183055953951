import { useState } from 'react';

function useModal() {
  const [showModal, setShowModal] = useState(null);

  const toggleModal = () => {
    document.documentElement.classList.toggle('modal-opened');
    setShowModal(!showModal);
  };

  return [showModal, toggleModal];
}

export default useModal;
