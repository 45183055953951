export function getTurmaDisciplinaRequest(idTurmaDisc) {
  return {
    type: '@turma/GET_TURMADISCIPLINA_REQUEST',
    payload: { idTurmaDisc },
  };
}

export function getTurmaDisciplinaSuccess(payload) {
  return { type: '@turma/GET_TURMADISCIPLINA_SUCCESS', payload };
}

export function clearTurma() {
  return { type: '@turma/CLEAR_TURMA' };
}
