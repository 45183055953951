/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = {
  loadingEtapas: false,
  loadingAvaliacoes: false,
  loadingLiberarNotas: false,
  loadingCalc: false,
  loadingSaveRubrica: { loading: false, status: 0 },
  semestre: 1,
  etapas: [],
  avaliacoes: [],
  instrumento: [],
  notasEtapa: [],
  savedStatusNota: false,
};

export default function notas(state = INITIAL_STATE, action) {
  /* const printState = (draft) =>
    JSON.parse(JSON.stringify(draft)); */

  return produce(state, (draft) => {
    switch (action.type) {
      case '@notas/GET_ETAPAS_REQUEST': {
        draft.loadingEtapas = true;
        break;
      }
      case '@notas/GET_ETAPAS_SUCCESS': {
        draft.etapas = action.payload;
        draft.avaliacoes = [];
        draft.instrumento = [];
        draft.loadingEtapas = false;
        break;
      }
      case '@notas/GET_AVALIACOES_REQUEST': {
        draft.loadingAvaliacoes = true;
        break;
      }
      case '@notas/GET_AVALIACOES_SUCCESS': {
        draft.avaliacoes = [...draft.avaliacoes, ...action.payload];
        draft.instrumento = [];
        draft.loadingAvaliacoes = false;
        break;
      }
      case '@notas/SET_INSTRUMENTO': {
        const { instrumento } = action.payload;
        draft.instrumento = instrumento;
        break;
      }
      case '@notas/GET_NOTASETAPA_REQUEST': {
        draft.loadingAvaliacoes = true;
        break;
      }
      case '@notas/GET_NOTASETAPA_SUCCESS': {
        draft.notasEtapa = action.payload;
        draft.loadingAvaliacoes = false;
        break;
      }

      case '@notas/UPDATE_NOTA_REQUEST': {
        const { nota, instrumento, aluno } = action.payload;
        const { CODPROVA, CODETAPA, IDTURMADISC } = instrumento;
        const { RA } = aluno;
        if (draft.notasEtapa !== null) {
          const index = draft.notasEtapa.findIndex(
            (item) =>
              item.CODETAPA === CODETAPA
              && item.CODPROVA === CODPROVA
              && item.IDTURMADISC === IDTURMADISC
              && item.RA === RA,
          );
          if (index > -1) {
            draft.notasEtapa[index].NOTA = nota;
          }
        }
        break;
      }

      case '@notas/SET_SEMESTRE': {
        draft.semestre = action.payload;
        break;
      }

      case '@notas/CLEAR': {
        return { ...INITIAL_STATE, semestre: draft.semestre };
      }

      case '@rubrica/SAVE_RUBRICA_SUCCESS': {
        const { payload, savedNota } = action;
        const {
          codEtapa: CODETAPA,
          ra: RA,
          idTurmaDisc: IDTURMADISC,
          nomeAluno: NOME,
          codProva: CODPROVA,
        } = payload;

        if (draft.notasEtapa !== null) {
          const hasNota = draft.notasEtapa.findIndex(
            (item) =>
              item.CODETAPA === CODETAPA
              && item.CODPROVA === CODPROVA
              && item.IDTURMADISC === IDTURMADISC
              && item.RA === RA,
          );
          if (hasNota === -1) {
            draft.notasEtapa = [...draft.notasEtapa, savedNota];
          }
        } else {
          draft.notasEtapa = [savedNota];
        }

        const itemsFilter = draft.notasEtapa.filter(
          (item) =>
            item.RA === payload.ra,
        );
        const [itemModel] = itemsFilter;

        const provas = itemsFilter.map((item) =>
          item.CODPROVA);

        const hasRubricaItems = Object.keys(payload.notas).filter(
          (item) =>
            !provas.includes(item),
        );

        if (hasRubricaItems.length > 0) {
          const newNotas = [];
          Object.entries(payload.notas).forEach((item) => {
            const [codProva, nota] = item;
            const currentItem = {
              ...itemModel,
              CODETAPA,
              RA,
              IDTURMADISC,
              NOME,
            };
            currentItem.CODPROVA = codProva;
            currentItem.NOTA = nota.toString();
            newNotas.push(currentItem);
          });
          draft.notasEtapa = [...draft.notasEtapa, ...newNotas];
        }

        break;
      }

      case '@notas/LIBERAR_NOTAS_SUCCESS': {
        const { codEtapa, codProva, liberado } = action.payload;
        const indexAvaliacao = draft.avaliacoes.findIndex(
          (item) =>
            item.CODPROVA === codProva && item.CODETAPA === codEtapa,
        );
        const indexInstrumento = draft.instrumento.findIndex(
          (item) =>
            item.CODCOLIGADA && item.CODETAPA === codEtapa,
        );

        if (liberado) {
          draft.avaliacoes[indexAvaliacao].DTDEVOLUCAOAVALIACAO = new Date();
          draft.instrumento[indexInstrumento].DTDEVOLUCAOAVALIACAO = new Date();
        } else {
          delete draft.avaliacoes[indexAvaliacao].DTDEVOLUCAOAVALIACAO;
          delete draft.instrumento[indexInstrumento].DTDEVOLUCAOAVALIACAO;
        }

        draft.savedStatusNota = 'SUCCESS';
        draft.loadingLiberarNotas = false;
        break;
      }

      case '@notas/LIBERAR_NOTAS_FAILURE': {
        draft.savedStatusNota = 'FAIL';
        draft.loadingLiberarNotas = false;
        break;
      }

      case '@notas/LIBERAR_NOTAS': {
        draft.loadingLiberarNotas = true;
        break;
      }

      case '@rubrica/SET_ATIVIDADE_VALUE_SUCCESS': {
        const { payload } = action;
        if (payload.codEtapa && payload.codProva) {
          const mediaInstIndex = draft.instrumento.findIndex((item) =>
            Number(item.CODPROVA) === Number(payload.codProva));
          draft.instrumento[mediaInstIndex].NUMQUESTOES = payload.atividadeValue;
        }
        if (payload.newNota) {
          draft.notasEtapa = payload.newNota;
        }
        break;
      }

      case '@parametrosAvaliacao/SAVE_PARAMETROS_SUCCESS': {
        const { updateResponse, CODETAPA, CADERNO } = action.payload;
        const indexCaderno = draft.avaliacoes.findIndex(
          (item) =>
            item.CODETAPA === CODETAPA && item.DESCRICAO === CADERNO,
        );
        draft.avaliacoes[indexCaderno].parametrosAvaliacao = updateResponse.data.data
          ?? updateResponse.data.dados;
        draft.instrumento = [
          ...draft.avaliacoes[indexCaderno].parametrosAvaliacao,
          draft.avaliacoes[indexCaderno],
        ];
        break;
      }

      default:
    }
  });
}
