import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
/* React DatePicker */
.react-datepicker__day--highlighted-custom-1 {
  background: #7252a1 !important;
  color: #fff !important;
  border-radius: 6px;
}

.react-datepicker {
  width: 100% !important;
}

.react-datepicker__header {
  background: var(--color-primary) !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff !important;
  text-transform: capitalize;
}

.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker__day-names {
  font-size: 1.6rem !important;
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
}

.react-datepicker__day-name {
  color: #fff !important;
  font-weight: 600;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
  font-size: 14px !important;
  /* border-top: 1px solid #eee; */
}

.react-datepicker__day,
.react-datepicker__day--today {
  width: 100% !important;
  border: 1px solid #eee;
  padding: 10px;
  background: #eee;
  color: #aaa;
  font-weight: normal;
  margin: 1px;
}

.react-datepicker__navigation {
  height: 8px;
  transform: scale(3);
  margin-top: 0.5rem;
}

.react-datepicker__navigation--previous {
  left: 20px;
}

.react-datepicker__navigation--next {
  right: 20px;
}
/* End React DatePicker */
`;
