import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Loading from '../Loading';
import MessageBox from '../MessageBox';

import {
  ButtonSemestre,
  CardSemestre,
  SeparatorSemestre,
  HeaderEtapas,
  ButtonEtapasNotas,
  ButtonAvaliacoes,
} from './styles';

import { Card } from '../../styles/Defaults';

import * as NotasActions from '../../store2/modules/notas/actions';

export default function HeaderNotas({ idTurmaDisc }) {
  const COD_ETAPAS_USAM_PARAMETROS_AVALIACAO = [1, 3, 4, 6, 8, 9];
  // const COD_ETAPAS_CADERNOS = [1, 6];
  const COD_HABILITACAO_3EM = 3;
  const COD_CURSO_EM = 4;

  const semestre = useSelector((state) =>
    state.notas.semestre);
  const [etapa, setEtapa] = useState(0);
  const [selectedAvaliacao, setSelectedAvaliacao] = useState([]);

  const dispatch = useDispatch();
  const etapas = useSelector((state) =>
    state.notas.etapas);
  const loadingEtapas = useSelector((state) =>
    state.notas.loadingEtapas);
  const loadingAvaliacoes = useSelector(
    (state) =>
      state.notas.loadingAvaliacoes,
  );
  const avaliacoes = useSelector((state) =>
    state.notas.avaliacoes);
  const instrumento = useSelector((state) =>
    state.notas.instrumento);
  const { CODCURSO: codCurso, CODHABILITACAO: codHabilitacao } = useSelector(
    (state) =>
      state.turma.turmaDisciplina,
  );

  const setInstrumento = useCallback(
    (val) => {
      dispatch(NotasActions.setInstrumento(val));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(NotasActions.setSemestre(2));
  }, [dispatch]);

  useEffect(() => {
    setEtapa(0);
    setSelectedAvaliacao([]);
    if (idTurmaDisc.startsWith('ANESINTESE')) {
      dispatch(NotasActions.getEtapasAneSinteseRequest(idTurmaDisc, semestre));
    } else {
      dispatch(NotasActions.getEtapasRequest(idTurmaDisc, semestre));
    }

    return () =>
      dispatch(NotasActions.clear());
  }, [dispatch, idTurmaDisc, semestre]);

  useEffect(() => {
    setSelectedAvaliacao((old) => {
      if (old.length > 0) {
        const { CODETAPA } = old[0];

        return Object.values(avaliacoes).filter(
          (item) =>
            item.CODETAPA === CODETAPA,
        );
      }
      return [];
    });
  }, [avaliacoes]);

  const selectEtapa = useCallback(
    (key) => {
      setEtapa(key);
      const newAvals = avaliacoes.filter((aval) =>
        aval.CODETAPA === key);
      setSelectedAvaliacao(newAvals);
      dispatch(NotasActions.getNotasEtapaRequest(idTurmaDisc, key));
      setInstrumento([]);
    },
    [avaliacoes, dispatch, idTurmaDisc, setInstrumento],
  );

  const selectAvaliacao = (val) => {
    const posAval = instrumento.findIndex(
      (item) =>
        item.CODPROVA === val.CODPROVA && item.CODETAPA === val.CODETAPA,
    );
    let tempAvaliacoes = instrumento.slice();
    if (posAval > -1) {
      tempAvaliacoes.splice(posAval, 1);
    } else {
      tempAvaliacoes.push(val);
    }
    if (
      COD_ETAPAS_USAM_PARAMETROS_AVALIACAO.includes(Number(val.CODETAPA))
      && !(Number(codCurso) === COD_CURSO_EM && Number(codHabilitacao) === COD_HABILITACAO_3EM)
    ) {
      // atribui os parâmetros de avaliações ao instrumento
      tempAvaliacoes = [...val.parametrosAvaliacao, val];
    } else {
      tempAvaliacoes.sort((a, b) =>
        a.CODPROVA - b.CODPROVA);
    }
    setInstrumento(tempAvaliacoes);
  };

  const activateAval = (avaliacao) => {
    if (COD_ETAPAS_USAM_PARAMETROS_AVALIACAO.includes(Number(avaliacao.CODETAPA))) {
      return (
        instrumento.findIndex(
          (item) =>
            item.DESCRICAO === avaliacao.DESCRICAO,
        ) > -1 && 'active'
      );
    }

    const hasInstrumento = instrumento.findIndex(
      (item) =>
        item.CODPROVA === avaliacao.CODPROVA
        && item.CODETAPA === avaliacao.CODETAPA,
    );
    return hasInstrumento > -1 && 'active';
  };

  return (
    <>
      <Card>
        <CardSemestre>
          <ButtonSemestre
            type="button"
            className={`${semestre === 1 && 'active'}`}
            onClick={() =>
              dispatch(NotasActions.setSemestre(1))}
          >
            1º Semestre
          </ButtonSemestre>
          <SeparatorSemestre />
          <ButtonSemestre
            type="button"
            className={`${semestre === 2 && 'active'}`}
            onClick={() =>
              dispatch(NotasActions.setSemestre(2))}
          >
            2º Semestre
          </ButtonSemestre>
        </CardSemestre>
        {/* -------- Botões das etapas ----------- */}
        {!loadingEtapas && avaliacoes.length > 0 ? (
          <HeaderEtapas>
            {etapas.map((etapaMap) =>
              (
                <ButtonEtapasNotas
                  type="button"
                  key={etapaMap.CODETAPA}
                  active={etapa === etapaMap.CODETAPA}
                  onClick={() =>
                    selectEtapa(etapaMap.CODETAPA)}
                >
                  {etapaMap.DESCRICAO.substring(
                    0,
                    etapaMap.DESCRICAO.indexOf(' '),
                  )}
                </ButtonEtapasNotas>
              ))}
          </HeaderEtapas>
        ) : (
          <Loading />
        )}
        {/* -------- Fim Botões das etapas ------- */}

        {/* -------- Botões das avaliações ----------- */}
        {loadingAvaliacoes && etapa !== 0 && <Loading />}

        {etapa !== 0 && !loadingAvaliacoes && (
          <Card.Body>
            {Object.values(selectedAvaliacao).map((avaliacao) =>
              (
                <ButtonAvaliacoes
                  type="button"
                  key={avaliacao.CODPROVA}
                  className={activateAval(avaliacao)}
                  onClick={() =>
                    selectAvaliacao(avaliacao)}
                >
                  {avaliacao.DESCRICAO}
                </ButtonAvaliacoes>
              ))}
          </Card.Body>
        )}
      </Card>
      {/* -------- Fim Botões das avaliações ----------- */}

      <MessageBox type="light">
        <p>
          As notas digitadas são salvas automaticamente ao sair do campo. Se o
          fundo ficar
          {' '}
          <span className="badge save">verde</span>
          , nota salva com
          sucesso. Caso fique
          {' '}
          <span className="badge not-saved">vermelho</span>
          ,
          a nota não foi salva.
        </p>
      </MessageBox>
    </>
  );
}
