import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Loading from '../../../../Loading';
import { ContainerParametros, Button } from './styles';

import api from '../../../../../services/api';

function ItemParametro({
  item,
  index,
  listParametros,
  setListParametros,
  handleEdit,
}) {
  const [showEdit, toggleEdit] = useState(false);
  const [excluir, toggleExcluir] = useState(false);
  const itemRef = useRef();
  const buttonsRef = useRef();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { IDTURMADISC } = useSelector((state) =>
    state.aula.selected);

  const handleClick = useCallback(
    (e) => {
      if (showEdit && buttonsRef && !buttonsRef.current.contains(e.target)) {
        toggleEdit(false);
      }
    },
    [showEdit],
  );

  const handleHoverEnd = () => {
    if (excluir) {
      return;
    }
    toggleEdit(false);
  };

  const handleClickEdit = (e) => {
    if (itemRef && itemRef.current?.contains(e.target)) {
      toggleEdit(!showEdit);
    }
  };

  const handleClose = (e) => {
    if (buttonsRef.current === e.target) {
      toggleEdit(false);
    }
  };

  const handleExcluir = async () => {
    const {
      CADERNO: caderno,
      CODETAPA: codEtapa,
      IDHABILITACAOFILIAL: idHabilitacaoFilial,
    } = listParametros[index];
    const { IDTURMADISC: idTurmaDisc } = listParametros[index].ID < 0
      ? { IDTURMADISC } : listParametros[index];
    setLoadingDelete(true);
    const qtdeNotasParametro = await api.apiAxios.get(
      '/notas/QtdeNotasParametrosAvaliacao',
      {
        params: {
          caderno, codEtapa, idTurmaDisc, idHabilitacaoFilial,
        },
      },
    );
    setLoadingDelete(false);
    if (qtdeNotasParametro.data.data[0].nota > 0) {
      toast.error('Já há notas, não é possível excluir o parâmetro!');
      return;
    }
    const itemUpdated = { ...listParametros[index], ATIVO: 0 };
    toggleEdit(false);
    const newList = listParametros.slice();
    newList[index] = itemUpdated;
    toast.warn('Item excluído! Não esqueça de salvar as alterações!');
    setListParametros([...newList]);
  };

  useEffect(() => {
    const ulComponent = document.querySelector('#listParametros');
    ulComponent.addEventListener('click', handleClick);

    return () => {
      ulComponent.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <ContainerParametros.List.Item
      key={item.ID}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        height: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
      }}
      transition={{
        duration: 0.5,
      }}
      className={index % 2 !== 0 && 'bg-gray'}
      onHoverStart={() =>
        toggleEdit(true)}
      onHoverEnd={handleHoverEnd}
      onClick={handleClickEdit}
      ref={itemRef}
    >
      <p>{item.PARAMETRO}</p>
      <p>
        <b>
          Peso:
          {' '}
          {item.PESO}
          %
          {' '}
        </b>
      </p>
      <AnimatePresence>
        {showEdit && (
          <ContainerParametros.List.Item.Buttons
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleClose}
            ref={buttonsRef}
            key={`${item.ID}buttons`}
          >
            <ContainerParametros.List.Item.Buttons.Button
              initial={false}
              onClick={() =>
                handleEdit(item)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </ContainerParametros.List.Item.Buttons.Button>
            {(!excluir && (
              <ContainerParametros.List.Item.Buttons.Button
                onClick={() =>
                  toggleExcluir(true)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ContainerParametros.List.Item.Buttons.Button>
            )) || (
              <>
                {loadingDelete && <Loading />}
                <Button className="btn-danger" onClick={handleExcluir}>
                  Excluir
                </Button>
                <Button
                  className="btn-success"
                  onClick={() =>
                    toggleExcluir(false)}
                >
                  Cancelar
                </Button>
              </>
            )}
          </ContainerParametros.List.Item.Buttons>
        )}
      </AnimatePresence>
    </ContainerParametros.List.Item>
  );
}

export default ItemParametro;
