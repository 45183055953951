import React, { useState, useEffect } from 'react';
import GoogleButton from 'react-google-button';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import Loading from '../../components/Loading';

import { Container, Form, ContainerLogo } from './styles';

import logo from '../../assets/img/logo.png';

function Login() {
  const errorMsg = useSelector((state) =>
    state.login.message);
  const [url, setUrl] = useState('');

  useEffect(() => {
    async function getUrl() {
      const response = await api.getLoginUrl();
      setUrl(response);
    }
    getUrl();
  }, []);

  return (
    <Container>
      <ContainerLogo src={logo} alt="Alef Peretz" />

      <Form>
        <div>
          {(url !== '' && (
            <GoogleButton
              onClick={() => {
                window.location.href = url;
              }}
            />
          )) || <Loading />}

          {errorMsg && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}
        </div>
      </Form>
    </Container>
  );
}

export default Login;
