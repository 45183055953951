import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import api from '../../services/api';

import TableAlunos from '../../components/TableAlunos';
import Button from '../../components/Button';
import AnimateContainer from '../../components/AnimateContainer';

import * as FrequenciaActions from '../../store2/modules/frequencia/actions';
import * as TurmaActions from '../../store2/modules/turma/actions';

import { HeaderButtons, Titulo, Container } from '../../styles/Defaults';

export default function FrequenciaDiaria() {
  const [loading, setLoading] = useState(true);
  const selectedAula = useSelector((state) =>
    state.aula.selected);

  const titulo = useSelector((state) => {
    const { NOMEDISC: disciplina, NOMETURMA: turma } = state.turma.turmaDisciplina;

    if (disciplina && turma) {
      return `${disciplina} - ${turma}`;
    }
    return '';
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TurmaActions.getTurmaDisciplinaRequest(selectedAula.IDTURMADISC));
  }, [dispatch, selectedAula.IDTURMADISC]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await axios.all([
        api.getAlunos(selectedAula.CODTURMA, selectedAula.IDTURMADISC),
        api.getTurmaDisciplina(selectedAula.IDTURMADISC),
        api.getDias(selectedAula.IDTURMADISC),
        api.getFaltasAlunos(selectedAula.IDTURMADISC),
      ]);
      dispatch(
        FrequenciaActions.loadTableRequest(
          response[0].dados,
          response[1].dados,
          response[2].dados,
          response[3].dados,
        ),
      );
      setLoading(false);
    }

    fetchData();
  }, [dispatch, selectedAula.CODTURMA, selectedAula.IDTURMADISC]);

  return (
    <AnimateContainer loading={loading}>
      <Container key="container">
        <HeaderButtons key="buttons">
          <Button to="/AulasDiaFrequencia" role="button">
            Voltar
          </Button>
          <Button to="/Home" role="button">
            Início
          </Button>
        </HeaderButtons>
        <Titulo key="titulo">{titulo}</Titulo>
        <TableAlunos voltar="AulasDiaFrequencia" />
      </Container>
    </AnimateContainer>
  );
}
