import {
  call, put, all, takeLatest, takeEvery,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  getCronogramasRequest,
  getCronogramasSuccess,
  getLivrosRequest,
  getLivrosSuccess, saveCronogramaSuccess, saveLivroSuccess, deleteCronogramaSuccess,
} from './actions';

function* getLivros({ payload }) {
  const { field, value } = payload;
  const response = yield call(api.getLivros, { params: { field, value } });
  if (response.status === 200) {
    const responseData = response.data.dados;
    const dados = responseData.map((item) =>
      ({
        idLivro: item.IDLIVRO,
        codPessoa: item.CODPESSOA,
        livro: item.LIVRO,
        objetivos: item.OBJETIVOS,
        comotrabalhar: item.COMOTRABALHAR,
        cobranca: item.COBRANCA,
        qtdePaginas: item.QTDEPAGINAS,
        codDisc: item.CODDISC,
        idHabilitacaoFilial: item.IDHABILITACAOFILIAL,
        status: item.STATUS,
      }));
    yield put(getLivrosSuccess(dados));
  }
  if (response.status === 204) {
    yield put(getLivrosSuccess([]));
  }
}

function* saveLivro({ payload }) {
  const response = yield call(api.saveLivro, { ...payload });
  if (response.status === 200) {
    yield put(saveLivroSuccess(response.data.dados));
    yield put(getLivrosRequest('codPessoa', payload.codPessoa));
  }
  if (response.status === 204) {
    yield put(saveLivroSuccess([]));
  }
}

function* saveCronograma({ payload }) {
  const response = yield call(api.saveCronograma, { ...payload });
  if (response.status === 200) {
    yield put(saveCronogramaSuccess(response.data.dados));
    yield put(getCronogramasRequest('idLivro', payload.idLivro));
    toast.success('Leitura adicionada com sucesso!');
  }
  if (response.status === 204) {
    yield put(saveCronogramaSuccess([]));
  }
}

function* deleteCronograma({ payload }) {
  const response = yield call(api.saveCronograma, { ...payload });
  if (response.status === 200) {
    yield put(deleteCronogramaSuccess(response.data.dados));
    yield put(getCronogramasRequest('idLivro', payload.idLivro));
    toast.success('Leitura deletada com sucesso!');
  }
  if (response.status === 204) {
    yield put(deleteCronogramaSuccess([]));
  }
}

function* getCronogramas({ payload }) {
  const { field, value } = payload;
  const response = yield call(api.getCronogramas, { params: { field, value } });
  if (response.status === 200) {
    const responseData = response.data.dados;
    const dados = responseData.map((item) =>
      ({
        idCronograma: item.IDCRONOGRAMA,
        idLivro: item.IDLIVRO,
        inicio: item.INICIO,
        fim: item.FIM,
        paginaInicial: item.PAGINAINICIAL,
        paginaFinal: item.PAGINAFINAL,
        localLeitura: item.LOCALLEITURA,
        status: item.STATUS,
      }));
    yield put(getCronogramasSuccess(dados));
  }
  if (response.status === 204) {
    yield put(getCronogramasSuccess([]));
  }
}

export default all([takeLatest('@livros/GET_LIVROS_REQUEST', getLivros),
  takeLatest('@livros/SAVE_LIVROS_REQUEST', saveLivro),
  takeLatest('@livros/SAVE_CRONOGRAMA_REQUEST', saveCronograma),
  takeLatest('@livros/DELETE_CRONOGRAMA_REQUEST', deleteCronograma),
  takeEvery('@livros/GET_CRONOGRAMAS_REQUEST', getCronogramas),
]);
