import React, {
  FC, SetStateAction, useEffect, useState,
} from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';

import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import {
  Fieldset, Label, Input,
} from '../LivroCreate/styles';
import DateTimeStyle from './styles';
import Submit from '../../../components/Button/submit';
import SelectCmp from '../../../components/Select';
import * as Livros from '../../../store2/modules/livros/actions';

registerLocale('ptBR', ptBR);

const schema = yup.object({
  inicio: yup.date().required(),
  fim: yup.date().required(),
  paginaInicial: yup.number().positive().lessThan(yup.ref('paginaFinal')).required(),
  paginaFinal: yup.number().positive().moreThan(yup.ref('paginaInicial')).required(),
  localLeitura: yup.string().oneOf(['Casa', 'Sala de aula']).required(),
}).required();

interface Props {
  idLivro: number
  handleCronograma: (boolean) => void
}

const Cronograma: React.FC<Props> = ({ idLivro, handleCronograma }) => {
  // @ts-ignore
  ptBR.options.weekStartsOn = 0;
  setDefaultLocale('ptBR');
  const {
    control, handleSubmit, formState: { errors, isSubmitSuccessful }, reset,
  } = useForm(
    { resolver: yupResolver(schema) },
  );
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(Livros.saveCronogramaRequest({
      ...data, idLivro, idCronograma: 0, status: 1,
    }));
    handleCronograma(false);
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful, reset]);

  return (
    <motion.div
      className="my-5"
      key="cronograma"
      initial={{ height: 0, opacity: 0 }}
      animate={{
        height: 'auto',
        opacity: 1,
      }}
      exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
    >
      <DateTimeStyle />
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="border-[1px] p-5 border-slate-300">
          <legend><h1 className="text-4xl text-primary mx-auto">Adicionar leitura</h1></legend>
          <div className="grid grid-cols-2 gap-4">
            <fieldset>
              <Label htmlFor="cronograma-inicio">
                Inicio da leitura:
              </Label>
              <Controller
                control={control}
                name="inicio"
                render={({ field }) =>
                  (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) =>
                        field.onChange(date)}
                      dateFormat="dd/MM/yyyy"
                      todayButton="Hoje"
                      id="cronograma-inicio"
                      autoComplete="off"
                      className={`${!!errors.inicio && '!border-red-500'} text-2xl text-end`}
                    />
                  )}
              />
            </fieldset>
            <fieldset className="grid-col">
              <Label htmlFor="cronograma-final">
                Final da leitura:
              </Label>
              <Controller
                control={control}
                name="fim"
                render={({ field }) =>
                  (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) =>
                        field.onChange(date)}
                      dateFormat="dd/MM/yyyy"
                      todayButton="Hoje"
                      id="cronograma-final"
                      autoComplete="off"
                      className={`${!!errors.fim && '!border-red-500'} text-2xl text-end`}
                    />
                  )}
              />
            </fieldset>
            <Fieldset className="grid-col">
              <Label htmlFor="pagina-incial">
                Página incial:
              </Label>
              <Controller
                control={control}
                name="paginaInicial"
                defaultValue={0}
                render={({ field }) =>
                  <Input type="number" id="pagina-incial" {...field} className={`${!!errors.paginaInicial && '!border-red-500'} text-2xl text-end`} />}
              />

            </Fieldset>
            <Fieldset className="grid-col">
              <Label htmlFor="pagina-final">
                Página final:
              </Label>
              <Controller
                control={control}
                name="paginaFinal"
                defaultValue={0}
                render={({ field }) =>
                  <Input type="number" id="pagina-final" className={`${!!errors.paginaFinal && '!border-red-500'} text-2xl text-end`} {...field} />}
              />
            </Fieldset>
            <fieldset>
              <Label htmlFor="localLeitura">
                Local de leitura:
              </Label>
              <Controller
                control={control}
                name="localLeitura"
                render={({ field }) =>
                  (
                    <SelectCmp
                      items={[{ id: 'Casa', value: 'Casa' }, { id: 'Sala de aula', value: 'Sala de aula' }]}
                      valueField="id"
                      nameField="value"
                      placeholder="-"
                      onValueChange={field.onChange}
                      error={!!errors.localLeitura}
                      {...field}
                    />
                  )}
              />
            </fieldset>
          </div>
          <div className="mx-auto md:flex md:justify-end mt-5">
            <Submit>Salvar</Submit>
          </div>
        </fieldset>
      </form>
    </motion.div>
  );
};

export default Cronograma;

/* Inicio da leitura:
30/11/2022
Final da leitura:
14/12/2022
Página inicial:
1
Página Final:
2 */
