import { produce } from 'immer';

const INITIAL_STATE = {
  frequencia: false,
};

export default function modal(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@modal/ADD_FREQUENCIA': {
        const { status } = action.payload;
        draft.frequencia = status;
        break;
      }
      default:
    }
  });
}
