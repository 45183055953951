import { produce } from 'immer';

const INITIAL_STATE = {
  tipoLicao: { 22: 'fez', 23: 'nao', 24: 'parcial' },
};

export default function licao(state = INITIAL_STATE, action) {
  return produce(state, () => {
    switch (action.type) {
      case '@licao/GET_LICAO': {
        break;
      }
      default:
    }
  });
}
