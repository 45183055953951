/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = { list: [], config: [], loading: false };

export default function aluno(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@aluno/GET_ALUNOS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@aluno/GET_ALUNOS_SUCCESS': {
        draft.list = action.payload;
        draft.loading = false;
        break;
      }
      case '@aluno/GET_ALUNOS_CONFIG': {
        draft.config = action.payload;
        break;
      }
      case '@aluno/CLEAR_ALUNOS': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
