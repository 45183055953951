import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled(motion.select)`
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--color-primary);
  background-color: none;
`;

export default Container;
