import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import DatePicker, { setDefaultLocale, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import { format } from 'date-fns';

import api from '../../services/api';

import ListAlunos from '../../components/ListAlunos';
import ReactDatePicker from '../../styles/react-datepicker-custom';
import Button from '../../components/Button';
import AnimateContainer from '../../components/AnimateContainer';

import { Titulo, SubTitulo } from '../../styles/Typography';

import * as FrequenciaActions from '../../store2/modules/frequencia/actions';

import { Container } from './styles';

registerLocale('pt', pt);

export default function RelatorioFrequencia() {
  pt.options.weekStartsOn = 0;
  setDefaultLocale('pt');
  const [arrDates, setArrDates] = useState([]);
  const [dadosData, setDadosData] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const { IDTURMADISC: idTurmaDisc, CODTURMA: codTurma } = useSelector(
    (state) =>
      state.aula.selected,
    shallowEqual,
  );

  const titulo = useSelector((state) => {
    const { NOMEDISC: disciplina, NOMETURMA: turma } = state.frequencia.turmaDisciplina;
    if (disciplina && turma) return `${disciplina} - ${turma}`;
    return '';
  }, shallowEqual);

  const highlightDates = useSelector((state) => {
    if (
      state.frequencia.highlightDates[0]
      && state.frequencia.highlightDates[0][
        'react-datepicker__day--highlighted-custom-1'
      ].length > 0
    ) {
      return state.frequencia.highlightDates;
    }
    return [];
  }, shallowEqual);
  const alunos = useSelector((state) =>
    state.frequencia.alunos, shallowEqual);
  const loading = useSelector(
    (state) =>
      state.frequencia.loadingCalendarReport,
    shallowEqual,
  );

  const dispatch = useDispatch();

  const calendarRef = useRef();

  useEffect(() => {
    dispatch(
      FrequenciaActions.loadCalendarReportRequest(codTurma, idTurmaDisc),
    );

    return () =>
      dispatch(FrequenciaActions.reset());
  }, [dispatch, codTurma, idTurmaDisc]);

  const limpar = () => {
    setArrDates([]);
    setDadosData([]);
  };

  const checkDate = useCallback((date, arrDate) => {
    for (let i = 0; i < arrDate.length; i += 1) {
      if (date.toDateString() === arrDate[i].toDateString()) {
        return i;
      }
    }
    return -1;
  }, []);

  const toggleDate = async (e) => {
    if (loadingList) return;
    const newArrDates = arrDates.slice();
    const check = checkDate(e, arrDates);
    const checkHasDate = checkDate(
      e,
      highlightDates[0]['react-datepicker__day--highlighted-custom-1'],
    );

    if (checkHasDate < 0) {
      toast.warn(`Não há registros no dia ${format(e, 'dd/MM/yyyy')}`);
      return;
    }

    setLoadingList(true);
    if (check >= 0) {
      newArrDates.splice(check, 1);
    } else {
      const formatedDate = format(e, 'yyyy-MM-dd').toString();
      const response = await api.getOcorrenciasTurmaDisc(
        formatedDate,
        idTurmaDisc,
      );
      if (response.status === 200) {
        newArrDates.push(e);
        const arrOcorr = dadosData;
        arrOcorr[formatedDate] = [];
        response.dados.map((ocorr) =>
          arrOcorr[formatedDate].push(ocorr));
        setDadosData(arrOcorr);
      } else {
        toast.warn(`Não há registros no dia ${format(e, 'dd/MM/yyyy')}`);
      }
    }

    newArrDates.sort(
      (a, b) =>
        format(a, 'yyyyMMdd').toString() - format(b, 'yyyyMMdd').toString(),
    );

    setArrDates(newArrDates);
    setLoadingList(false);
    calendarRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const changeMonth = (date) => {
    dispatch(FrequenciaActions.loadMonthReportRequest(date, idTurmaDisc));
  };

  return (
    <>
      <ReactDatePicker />
      <AnimateContainer loading={loading}>
        <Container
          key="container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Container.HeaderButtons>
            <Button to="/AulasGeral/relatorioFrequencia" role="button">
              Voltar
            </Button>
            <Button to="/Home" role="button">
              Início
            </Button>
          </Container.HeaderButtons>
          <Titulo>{titulo}</Titulo>
          <Container.Header>
            <SubTitulo>Seleciona as datas desejadas no calendário:</SubTitulo>
            <Button ref={calendarRef} as="button" onClick={limpar}>
              Limpar
            </Button>
          </Container.Header>

          <DatePicker
            onSelect={toggleDate}
            highlightDates={highlightDates}
            inline
            onMonthChange={changeMonth}
          />
          {alunos.length > 0 && highlightDates.length > 0 && (
            <ListAlunos
              alunos={alunos}
              dias={arrDates}
              ocorrencias={dadosData}
              loading={loadingList}
            />
          )}
        </Container>
      </AnimateContainer>
    </>
  );
}
