/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = { selected: null };

export default function aula(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@aula/SELECT_AULA_SUCCESS': {
        draft.selected = action.payload;
        break;
      }
      case '@login/LOGOUT': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
