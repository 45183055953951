export function setRubricaData(payload) {
  return { type: '@rubrica/SET_RUBRICA_DATA', payload };
}

export function setAtividadeValueSuccess(payload) {
  return { type: '@rubrica/SET_ATIVIDADE_VALUE_SUCCESS', payload };
}

export function setAtividadeValueRequest(payload) {
  return { type: '@rubrica/SET_ATIVIDADE_VALUE_REQUEST', payload };
}

export function setAtividadeValueFailure() {
  return { type: '@rubrica/SET_ATIVIDADE_VALUE_FAILURE' };
}

export function setComments(payload) {
  return { type: '@rubrica/SET_COMMENTS', payload };
}

export function setLoadingComments(payload) {
  return { type: '@rubrica/SET_LOADING_COMMENTS', payload };
}

export function getDevolutivaRequest(payload) {
  return { type: '@rubrica/GET_DEVOLUTIVA_REQUEST', payload };
}

export function getDevolutivaSuccess(payload) {
  return { type: '@rubrica/GET_DEVOLUTIVA_SUCCESS', payload };
}

export function saveRubricaRequest(data) {
  return {
    type: '@rubrica/SAVE_RUBRICA_REQUEST',
    payload: data,
  };
}

export function saveRubricaSuccess(payload, getSavedNota) {
  return {
    type: '@rubrica/SAVE_RUBRICA_SUCCESS',
    payload,
    savedNota: getSavedNota,
  };
}

export function saveRubricaFailure(payload) {
  return {
    type: '@rubrica/SAVE_RUBRICA_FAILURE',
    payload,
  };
}

export function setModal(payload) {
  return {
    type: '@rubrica/SET_MODAL',
    payload,
  };
}

export function setEscalaValues(payload) {
  return {
    type: '@rubrica/SET_ESCALAVALUES',
    payload,
  };
}

export function clearStackSaving() {
  return {
    type: '@rubrica/CLEAR_STACKSAVING',
  };
}

export function setCreateParametros(payload) {
  return { type: '@parametrosAvaliacao/SET_CREATE_PARAMETROS', payload };
}

export function saveParametrosRequest(payload) {
  return { type: '@parametrosAvaliacao/SAVE_PARAMETROS_REQUEST', payload };
}

export function saveParametrosAneSinteseRequest(payload) {
  return { type: '@parametrosAvaliacao/SAVE_PARAMETROS_ANESINTESE_REQUEST', payload };
}

export function saveParametrosSuccess(payload) {
  return { type: '@parametrosAvaliacao/SAVE_PARAMETROS_SUCCESS', payload };
}

export function getEscalaRequest(payload) {
  return { type: '@parametrosAvaliacao/GET_ESCALA_REQUEST', payload };
}

export function getEscalaSuccess(payload) {
  return { type: '@parametrosAvaliacao/GET_ESCALA_SUCCESS', payload };
}

export function getNiveisRequest(payload) {
  return { type: '@rubrica/GET_NIVEIS_REQUEST', payload };
}

export function getNiveisSuccess(payload) {
  return { type: '@rubrica/GET_NIVEIS_SUCCESS', payload };
}
