import React, { useEffect, useState } from 'react';

import CardCurso from '../../components/CardCurso';
import MessageBox from '../../components/MessageBox';
import Button from '../../components/Button';
import AnimateContainer from '../../components/AnimateContainer';

import { Titulo, Container, SubTitulo } from '../../styles/SubjectScreen';

import api from '../../services/api';

export default function DisciplinasDia() {
  const [aulasDia, setAulasDia] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getAulasDia() {
      const response = await api.getAulasDia();
      if (response.status === 200) {
        if (response.dados) {
          setAulasDia(response.dados);
        }
      }
      setLoading(false);
    }

    getAulasDia();
  }, []);

  return (
    <AnimateContainer loading={loading}>
      <Container>
        <Button to="/Home" role="button">
          Voltar
        </Button>

        <Titulo>Aulas do dia</Titulo>
        <SubTitulo>
          Selecione a aula abaixo para lançamento das frequências:
        </SubTitulo>
        <Container.Cards>
          {Object.keys(aulasDia).map((curso) =>
            (
              <CardCurso
                curso={curso}
                tipo="dia"
                turmas={aulasDia[curso]}
                key={curso}
              />
            ))}
          {aulasDia.length === 0 && !loading && (
            <MessageBox type="primary" message="Nenhuma aula no dia." />
          )}
        </Container.Cards>
      </Container>
    </AnimateContainer>
  );
}
