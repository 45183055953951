import React, {
  useEffect, useState,
} from 'react';
import { AnimatePresence } from 'framer-motion';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { format } from 'date-fns';
import {
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
  AccordionHeader,
  CronogramaSeparator,
} from './styles';
import { ICronograma, ILivro } from '../types';
import { Input, Textarea } from '../LivroCreate/styles';
import SelectCmp from '../../../components/Select';
import Cronograma from '../Cronograma';
import * as LivrosActions from '../../../store2/modules/livros/actions';

  interface Props {
    livro: ILivro;
  }

const schema = yup.object({
  livro: yup.string().required(),
  qtdePaginas: yup.number().positive().required(),
  objetivos: yup.string().required(),
  comotrabalhar: yup.string().required(),
  cobranca: yup.string().required(),
}).required();

const LivroAccordionItem: React.FC<Props> = ({ livro }) => {
  const {
    handleSubmit, control, formState: { errors },
  } = useForm(
    { resolver: yupResolver(schema) },
  );

  const dispatch = useDispatch();
  const [showAddCronograma, setShowAddCronograma] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const cronogramas = useSelector((state: RootStateOrAny) =>
    state.livros.cronogramas[livro.idLivro]);
  const items = [
    { value: 'licao', name: 'Lição de Casa' },
    { value: 'prova', name: 'Prova' },
    { value: 'fichamento', name: 'Fichamento' },
    { value: 'discussoes', name: 'Discussões' },
  ];

  useEffect(() => {
    if (editMode) {
      setShowAddCronograma(false);
    }
  }, [editMode]);

  useEffect(() => {
    dispatch(LivrosActions.getCronogramasRequest('idLivro', livro.idLivro));
  }, [dispatch, livro.idLivro]);

  const editLivro = () => {
    setEditMode((state) =>
      !state);
  };

  const deleteCronograma = (cronograma: ICronograma) => {
    dispatch(LivrosActions.deleteCronogramaRequest({ ...cronograma, status: 0 }));
  };

  const onSubmit = (data) => {
    dispatch(LivrosActions.saveLivroRequest({ ...livro, ...data }));
    setEditMode(false);
  };

  return (
    <AnimatePresence>
      <AccordionItem value={livro.idLivro} key={livro.idLivro}>
        <AccordionHeader>
          <AccordionTrigger>
            <div>
              { editMode ? (
                <Controller
                  name="livro"
                  defaultValue={livro.livro}
                  control={control}
                  render={({ field }) =>
                    <Input type="text" id="livro" {...field} className={`${!!errors.livro && '!border-red-500'} text-2xl`} />}
                />
              ) : livro.livro}
            </div>
            <div className={`paginas ${editMode && 'flex items-center justify-between w-[40%]'}`}>
              <b>Total de páginas: </b>
              {editMode ? (
                <Controller
                  name="qtdePaginas"
                  control={control}
                  defaultValue={livro.qtdePaginas}
                  render={({ field }) =>
                    (
                      <Input
                        type="number"
                        id="qtdePaginas"
                        {...field}
                        className={`${!!errors.qtdePaginas && '!border-red-500'} text-end text-2xl !w-[35%] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                      />
                    )}
                />
              ) : livro.qtdePaginas}
            </div>
          </AccordionTrigger>
        </AccordionHeader>
        <AccordionContent>
          <div className="container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <button onClick={editLivro} type="button" className={`text-${editMode ? 'red-500 hover:border-red-500' : 'primary hover:border-primary '} underline rounded-md px-3 py-2 border-[1px] border-transparent transition-all`}>{editMode ? 'Cancelar' : 'Editar'}</button>
                {editMode && <button type="submit" className="text-green-700 underline rounded-md px-3 py-2 border-[1px] border-transparent hover:border-green-700 transition-all">Salvar</button>}
              </div>
              <div className="item-container">
                <h4 className="font-semibold">Objetivos do livro dentro do curso: </h4>
                <p>
                  { editMode ? (
                    <Controller
                      name="objetivos"
                      control={control}
                      defaultValue={livro.objetivos}
                      render={({ field }) =>
                        <Textarea id="objetivos" rows="5" {...field} className={`${!!errors.objetivos && '!border-red-500'} text-2xl`} />}
                    />
                  ) : livro.objetivos}

                </p>
              </div>
              <div className="item-container">
                <h4 className="font-semibold">Como trabalhar o livro no curso: </h4>
                <p>
                  {editMode ? (
                    <Controller
                      name="comotrabalhar"
                      defaultValue={livro.comotrabalhar}
                      control={control}
                      render={({ field }) =>
                        <Textarea id="comotrabalhar" rows="5" {...field} className={`${!!errors.comotrabalhar && '!border-red-500'} text-2xl`} />}
                    />
                  ) : livro.comotrabalhar}

                </p>
              </div>
              <div className="item-container">
                <h4 className="font-semibold">Como será cobrado dos alunos: </h4>
                <p>
                  {editMode ? (
                    <Controller
                      name="cobranca"
                      defaultValue={livro.cobranca}
                      control={control}
                      render={({ field }) =>
                        (
                          <SelectCmp
                            items={items}
                            valueField="value"
                            nameField="name"
                            placeholder="-"
                            onValueChange={field.onChange}
                            error={!!errors.cobranca}
                            defaultValue={livro.cobranca}
                            {...field}
                          />
                        )}
                    />
                  ) : items.find((item) =>
                    item.value === livro.cobranca)?.name}

                </p>
              </div>
            </form>
            <div className="item-container">
              <AnimatePresence>
                {showAddCronograma && (
                <Cronograma
                  idLivro={livro.idLivro}
                  handleCronograma={setShowAddCronograma}
                />
                )}
              </AnimatePresence>
              <div className="flex items-center gap-4">
                <h4 className="font-semibold">Cronograma: </h4>
                <button
                  type="button"
                  className="text-primary underline rounded-md px-3 py-2 border-[1px] border-transparent hover:border-primary transition-all"
                  onClick={() =>
                    setShowAddCronograma((state) =>
                      !state)}
                >
                  Adicionar leitura
                </button>
              </div>
              <div className="cronograma">
                {
                cronogramas?.map((crono: ICronograma, index) =>
                  (
                    <div key={crono.idCronograma}>
                      <div>
                        <button
                          onClick={() =>
                            deleteCronograma(crono)}
                          type="button"
                          className="text-red-500 border-red-500 underline rounded-md px-3 py-2 border-[1px] transition-all mb-3"
                        >
                          Excluir
                        </button>
                      </div>
                      <div>
                        <h4 className="font-semibold">Inicio da leitura: </h4>
                        <span>{format(new Date(crono.inicio), 'dd/MM/y')}</span>
                      </div>
                      <div>
                        <h4 className="font-semibold">Final da leitura: </h4>
                        <span>{format(new Date(crono.fim), 'dd/MM/y')}</span>
                      </div>
                      <div>
                        <h4 className="font-semibold">Página inicial: </h4>
                        <span>{crono.paginaInicial}</span>
                      </div>
                      <div>
                        <h4 className="font-semibold">Página Final: </h4>
                        <span>{crono.paginaFinal}</span>
                      </div>
                      <div>
                        <h4 className="font-semibold">Local de leitura: </h4>
                        <span>{crono.localLeitura}</span>
                      </div>
                      { cronogramas?.length !== index + 1 && <CronogramaSeparator />}
                    </div>
                  ))
}
              </div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </AnimatePresence>
  );
};

export default LivroAccordionItem;
