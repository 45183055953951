import {
  call, put, all, takeLatest, takeEvery,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  getEtapasSuccess,
  getAvaliacoesSuccess,
  getAvaliacoesRequest,
  getAvaliacoesAneSinteseRequest,
  getNotasEtapaSuccess,
  liberarNotasSuccess,
  liberarNotasFailure,
} from './actions';

function* getEtapas({ payload }) {
  const { idTurmaDisc, semestre } = payload;
  const response = yield call(api.getEtapas, idTurmaDisc);
  if (response.status === 200) {
    const etapasFiltro = response.dados.filter(
      (etapa) =>
        etapa.DESCRICAO.indexOf(`${semestre}º SEMESTRE`) > -1
        && etapa.DESCRICAO.indexOf('MÉDIA') === -1
        && etapa.DESCRICAO.indexOf('SÍNTESE') === -1
        && etapa.DESCRICAO.indexOf('ANE') === -1
        && etapa.EXIBENAWEB === 'S',
    );
    yield put(getEtapasSuccess(etapasFiltro));
    const avaliacoes = [];
    for (let i = 0; i < etapasFiltro.length; i += 1) {
      avaliacoes.push(
        put(getAvaliacoesRequest(etapasFiltro[i].CODETAPA, idTurmaDisc)),
      );
    }
    yield all(avaliacoes);
  }
}

function* getEtapasAneSintese({ payload }) {
  const { semestre, idTurmaDisc } = payload;
  const response = yield call(api.getEtapasAneSintese, idTurmaDisc);

  const etapasFiltro = response.dados.filter(
    (etapa) =>
      etapa.DESCRICAO.indexOf(`${semestre}º SEMESTRE`) > -1
      && etapa.DESCRICAO.indexOf('MÉDIA') === -1
      && etapa.EXIBENAWEB === 'S',
  );
  yield put(getEtapasSuccess(etapasFiltro));
  const avaliacoes = [];
  for (let i = 0; i < etapasFiltro.length; i += 1) {
    avaliacoes.push(
      put(getAvaliacoesAneSinteseRequest(etapasFiltro[i].CODETAPA, idTurmaDisc)),
    );
  }
  yield all(avaliacoes);
}

function* getAvaliacoes({ payload }) {
  const { etapa, idTurmaDisc } = payload;
  const response = yield call(api.getAvaliacoes, etapa, idTurmaDisc);

  if (response.status === 200) {
    yield put(getAvaliacoesSuccess(response.dados));
  }
}

function* getAvaliacoesAneSintese({ payload }) {
  const { etapa, idTurmaDisc } = payload;
  const [, codPessoa, idHabilitacaoFilial] = idTurmaDisc.split('-');
  const response = yield call(api.getAvaliacoesAneSintese, etapa, codPessoa, idHabilitacaoFilial);

  if (response.status === 200) {
    yield put(getAvaliacoesSuccess(response.dados));
  }
}

function* getNotasEtapa({ payload }) {
  const { idTurmaDisc, codEtapa } = payload;
  const response = yield call(api.getNotasEtapa, idTurmaDisc, codEtapa);
  if (response.status === 200 || response.status === 204) {
    yield put(getNotasEtapaSuccess(response.dados));
  }
}

function* setLiberarNotas({ payload }) {
  let acao = ['liberadas', 'liberar'];
  const { codEtapa, codProva, liberado } = payload;
  if (!liberado) {
    acao = ['bloqueadas', 'bloquear'];
  }
  try {
    const response = yield call(api.apiAxios.put, 'liberarNotas', {
      payload,
    });

    if (response.data.status === 200) {
      yield put(liberarNotasSuccess(liberado, codEtapa, codProva));
      toast.success(`Notas ${acao[0]} com sucesso!`);
    } else {
      throw new Error();
    }
  } catch (e) {
    console.log(e);
    toast.error(`Erro ao ${acao[1]} as notas!`);
    yield put(liberarNotasFailure());
  }
}

export default all([
  takeLatest('@notas/GET_ETAPAS_REQUEST', getEtapas),
  takeLatest('@notas/GET_ETAPASANESINTESE_REQUEST', getEtapasAneSintese),
  takeEvery('@notas/GET_AVALIACOES_REQUEST', getAvaliacoes),
  takeEvery('@notas/GET_AVALIACOESANESINTESE_REQUEST', getAvaliacoesAneSintese),
  takeLatest('@notas/GET_NOTASETAPA_REQUEST', getNotasEtapa),
  takeLatest('@notas/LIBERAR_NOTAS', setLiberarNotas),
]);
