export function getLivrosRequest(field, value) {
  return {
    type: '@livros/GET_LIVROS_REQUEST',
    payload: { field, value },
  };
}

export function getLivrosSuccess(payload) {
  return { type: '@livros/GET_LIVROS_SUCCESS', payload };
}

export function saveLivroRequest(payload) {
  return { type: '@livros/SAVE_LIVROS_REQUEST', payload };
}
export function saveLivroSuccess(payload) {
  return { type: '@livros/SAVE_LIVROS_SUCCESS', payload };
}

export function saveCronogramaRequest(payload) {
  return { type: '@livros/SAVE_CRONOGRAMA_REQUEST', payload };
}
export function saveCronogramaSuccess(payload) {
  return { type: '@livros/SAVE_CRONOGRAMA_SUCCESS', payload };
}

export function deleteCronogramaRequest(payload) {
  return { type: '@livros/DELETE_CRONOGRAMA_REQUEST', payload };
}
export function deleteCronogramaSuccess(payload) {
  return { type: '@livros/DELETE_CRONOGRAMA_SUCCESS', payload };
}

export function getCronogramasRequest(field, value) {
  return { type: '@livros/GET_CRONOGRAMAS_REQUEST', payload: { field, value } };
}

export function getCronogramasSuccess(payload) {
  return { type: '@livros/GET_CRONOGRAMAS_SUCCESS', payload };
}

export function setActiveTabLivro(activeTab) {
  return { type: '@livros/SET_ACTIVE_TAB', payload: { activeTab } };
}
