/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { Container, LicaoFarol } from './styles';

export default function LicaoDeCasa({ setLicao, aluno, selected }) {
  const paramsButton = [aluno];

  return (
    <Container>
      <LicaoFarol>
        <LicaoFarol.Button
          type="button"
          onClick={(e) =>
            setLicao(e, ...paramsButton, 'fez')}
          className={`green ${selected === 'fez' ? 'selected' : ''}`}
        />
        <LicaoFarol.Button
          type="button"
          onClick={(e) =>
            setLicao(e, ...paramsButton, 'parcial')}
          className={`yellow ${selected === 'parcial' ? 'selected' : ''}`}
          key="parcial"
        />
        <LicaoFarol.Button
          type="button"
          onClick={(e) =>
            setLicao(e, ...paramsButton, 'nao')}
          className={`red ${selected === 'nao' ? 'selected' : ''}`}
          key="nao"
        />
      </LicaoFarol>
    </Container>
  );
}
