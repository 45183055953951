import styled from 'styled-components';

export const LogoutButton = styled.button`
  background: transparent;
  border: 0;
  color: #032b50;
  font-weight: 400;
  font-size: 1.8rem;
  margin-right: 1.5rem;
  cursor: pointer;
  z-index: 1;
`;

export const Container = styled.header`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2.5rem;
`;
