import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 4rem;
  background: var(--color-primary);
  border-radius: 0.5rem;
  padding: 0 1rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 2rem;
  margin-right: 0.5rem;
  border: 0;
  font-size: 1.5rem;
  cursor: pointer;
`;
