import React from 'react';
import * as Select from '@radix-ui/react-select';
import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons';

interface Props extends Select.SelectProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[]
    nameField: string
    valueField: string
    placeholder: string
    error: boolean
  }

const SelectCmp = React.forwardRef<HTMLButtonElement, Props>(({
  items, nameField, valueField, placeholder, error = false, ...rest
}: Props, forwardedRef) =>
  (
    <Select.Root {...rest}>
      <Select.Trigger
        ref={forwardedRef}
        className={`data-[placeholder]:text-primary inline-flex items-center justify-center rounded py-0 px-5 text-lg h-12 gap-2 bg-white text-primary cursor-pointer border-[1px] ${error ? 'border-red-500' : 'border-gray-300'}`}
      >
        <Select.Value placeholder={placeholder} />
        <Select.Icon className="SelectIcon flex self-center">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="overflow-hidden bg-white rounded-md shadow-lg">
          <Select.Viewport>
            {items.map((item) =>
              (
                <Select.Item
                  value={item[valueField]}
                  key={item[valueField]}
                  className="text-lg text-primary rounded-sm flex h-8 justify-center items-center relative py-0 pl-6 pr-9 select-none cursor-pointer data-[highlighted]:bg-primary data-[highlighted]:text-white data-[highlighted]:outline-none"
                >
                  <Select.ItemText>
                    {item[nameField]}
                  </Select.ItemText>
                  <Select.ItemIndicator className="absolute left-0 w-6 inline-flex items-center justify-center">
                    <CheckIcon />
                  </Select.ItemIndicator>
                </Select.Item>
              ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  ));

SelectCmp.displayName = 'Select';
export default SelectCmp;
