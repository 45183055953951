export function selectAulaRequest(payload, nextPage, idTurmaDisc) {
  return {
    type: '@aula/SELECT_AULA_REQUEST',
    payload: { ...payload, nextPage, idTurmaDisc },
  };
}

export function selectAulaSuccess(payload) {
  return { type: '@aula/SELECT_AULA_SUCCESS', payload };
}
