import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function MessageBox({ type, message, children }) {
  return <Container type={type}>{message || children}</Container>;
}

MessageBox.defaultProps = {
  message: '',
};

MessageBox.propTypes = {
  type: PropTypes.oneOf([
    'success',
    'danger',
    'primary',
    'warning',
    'light',
    'info',
  ]).isRequired,
  message: PropTypes.string,
};
