/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "React" }] */

import React from 'react';
import PropTypes from 'prop-types';

export default function LoginLayout({ children }) {
  return <>{children}</>;
}

LoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
