import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as LoginActions from '../../store2/modules/login/actions';
import { LogoutButton, Container } from './styles';
import Avatar from '../Avatar';

export default function Header() {
  const foto = useSelector((state) =>
    state.login.user.picture);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleLogout = () => {
    dispatch(LoginActions.logout());
  };

  return (
    <Container>
      <LogoutButton onClick={handleLogout}>SAIR</LogoutButton>
      {location.pathname !== '/Home' && <Avatar src={foto} size={4} />}
    </Container>
  );
}
