import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)``;

export const Table = styled.table`
  position: relative;
  background: #fff;
  border-collapse: collapse;
  margin: auto;
  width: 100%;
`;

Table.Header = styled.thead``;

Table.Header.Row = styled.tr``;

Table.Header.Col = styled.th`
  position: sticky;
  top: -1px;
  border-top: 0;
  z-index: 5;
  font-size: 1.5rem;
  font-weight: 500;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 0.75rem;
  transition: all ease-in-out 0.3s;
  background: var(--color-primary);
  color: var(--color-white);

  &.col-photo {
    z-index: 6;
    min-width: 8rem;
  }

  &.col-name {
    text-align: left;
    padding-left: 2rem;
  }

  &:last-child {
    border-right: 0;
  }
`;

Table.Body = styled.tbody``;

Table.Body.Row = styled.tr`
  font-size: 1.8rem;
  text-transform: capitalize;
`;

Table.Body.Cell = styled.td`
  text-align: center;
  padding: 0.75rem;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;

  &.col-photo {
    position: sticky;
    left: 0;
    width: 10%;
    min-width: 8rem;
    padding: 0;
    border-bottom: 0;
    background: #e3e5e6;
  }

  &:last-child {
    border-right: 0;
  }

  &.col-name {
    text-align: left;
    padding-left: 2rem;
  }

  &.col-ocorrencias {
    ul {
      font-size: 1.4rem;
      list-style: none;

      button {
        border: 0;
        text-decoration: underline;
        color: #032b50;
        background: transparent;
        text-align: left;
        cursor: pointer;
      }
    }
  }
`;

export const ContainerTable = styled.div`
  margin: 2rem auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  width: 100%;

  overflow: auto;
  height: ${() =>
    `${document.documentElement.clientHeight
    - document.documentElement.clientHeight * 0.18
    }px`};
`;
