import React, { useEffect, useState } from 'react';

import { Spinner } from '../Loading';
import api from '../../services/api';

import { Img } from './styles';

export default function FotoAluno({ nomeAluno }) {
  const [foto, setFoto] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;
    setLoading(true);
    api.getFoto(nomeAluno).then((response) => {
      const value = `data:image/jpeg;base64,${response}`;
      if (!isCancelled) {
        setFoto(value);
        setLoading(false);
      }
    });

    return () => {
      isCancelled = true;
      setLoading(false);
      setFoto('');
    };
  }, [nomeAluno]);

  return loading ? <Spinner /> : <Img src={foto} alt={nomeAluno} />;
}
