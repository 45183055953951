export function loadTableRequest(
  alunos,
  turmaDisciplina,
  dias,
  faltasAlunos,
  idTurmaDisc,
) {
  return {
    type: '@frequencia/LOAD_TABLE',
    payload: {
      alunos, turmaDisciplina, dias, faltasAlunos, idTurmaDisc,
    },
  };
}

export function loadTableSuccess() {
  return { type: '@frequencia/LOAD_TABLE_SUCCESS' };
}

export function getTipoFalta(tipoFalta) {
  return { type: '@frequencia/GET_TIPO_FALTA', payload: { tipoFalta } };
}

export function addFrequencia(index, frequencia) {
  return { type: '@frequencia/ADD', payload: { index, frequencia } };
}

export function toggleModal() {
  return { type: '@frequencia/TOGGLE_MODAL' };
}

export function clearTipo() {
  return { type: '@frequencia/CLEAR_TIPO' };
}

export function loadCalendarReportRequest(codTurma, idTurmaDisc) {
  return {
    type: '@frequencia/LOAD_CALENDAR_REPORT_REQUEST',
    payload: { codTurma, idTurmaDisc },
  };
}

export function loadCalendarReportSuccess(response) {
  return {
    type: '@frequencia/LOAD_CALENDAR_REPORT_SUCCESS',
    payload: response,
  };
}

export function loadMonthReportRequest(date, idTurmaDisc) {
  return {
    type: '@frequencia/LOAD_MONTH_REPORT_REQUEST',
    payload: { date, idTurmaDisc },
  };
}

export function loadMonthReportSuccess(dataMonth) {
  return {
    type: '@frequencia/LOAD_MONTH_REPORT_SUCCESS',
    payload: { dataMonth },
  };
}

export function toggleLoading(status) {
  return {
    type: '@frequencia/TOGGLE_LOADING',
    payload: { status },
  };
}

export function reset() {
  return {
    type: '@frequencia/RESET',
    payload: {},
  };
}
