import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';

import api from '../../../services/api';
import { getProfessorSuccess, getSeriesProfessorSuccess, getDisciplinasSerieProfessorSuccess } from './actions';

function* getProfessor() {
  const response = yield call(api.getProfessor);
  const {
    CODPROF: codProf,
    CODPESSOA: codPessoa,
    EMAIL: email,
  } = response.dados[0];
  yield put(getProfessorSuccess({ codProf, codPessoa, email }));
}

function* getSeriesProfessor({ payload }) {
  const {
    codPessoa,
    perlet,
  } = payload;
  const response = yield call(api.getSeriesProfessor, { params: { codPessoa, perlet } });
  yield put(getSeriesProfessorSuccess({ series: response.data.dados }));
}

function* getDisciplinasSerieProfessor({ payload }) {
  const {
    codPessoa,
    idHabilitacaoFilial,
  } = payload;
  const response = yield call(
    api.getDisciplinasSerieProfessor,
    { params: { codPessoa, idHabilitacaoFilial } },
  );
  yield put(getDisciplinasSerieProfessorSuccess({ disciplinasSerie: response.data.dados }));
}

export default all([
  takeLatest('@login/LOGIN_SUCCESS', getProfessor),
  takeLatest('@professor/GET_SERIES_REQUEST', getSeriesProfessor),
  takeLatest('@professor/GET_DISCIPLINAS_SERIE_REQUEST', getDisciplinasSerieProfessor),
]);
