import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AnimatePresence, motion } from 'framer-motion';
import { toast } from 'react-toastify';

import {
  Container,
  Header,
  Body,
  TextArea,
  ContainerParametros,
  Button,
} from './styles';
import { Button as BtnLink } from '../../../../../styles/Notas';
import { saveParametrosRequest } from '../../../../../store2/modules/rubrica/actions';
import Loading from '../../../../Loading';
import ItemParametro from './ItemParametro';
import { isMedia } from '../../../../../helpers';

function CadastroParametros() {
  const [addStatus, setAddStatus] = useState(false);
  const [listParametros, setListParametros] = useState([]);
  const [qtyParametros, setQtyParametros] = useState(0);
  const [currentParametro, setCurrentParametro] = useState(null);
  const [serieData, setSerieData] = useState();

  const { instrumento } = useSelector((state) =>
    state.notas);

  const { loadingSavingParametros } = useSelector((state) =>
    state.rubrica);
  const { parametros } = useSelector((state) =>
    state.login);

  const refTextArea = useRef();
  const refContainer = useRef();
  const { anoAtivo } = parametros;

  // Pega os valores máximo e mínimo de quantidade de parâmetros
  const { MAX_PARAMETROS, MIN_PARAMETROS } = parametros[anoAtivo];

  const dispatch = useDispatch();

  const animations = {
    visible: {
      height: 'auto',
      transition: { when: 'beforeChildren', staggerChildren: 1, duration: 0.1 },
    },
    hidden: {
      height: 0,
      transition: {
        when: 'afterChildren',
        delay: 0.2,
        duration: 0.1,
      },
    },
  };

  // Função para inserir o parâmetro no array
  const addParametro = useCallback(() => {
    if (refTextArea.current.value === '') {
      return;
    }
    if (refTextArea.current.value.length >= 5000) {
      toast.error('Limite de 5 mil caracteres');
      return;
    }

    setAddStatus(false);

    let newParametro;

    // Faz uma cópia do array
    const tempParametros = listParametros.slice();

    // Verifica se é uma edição
    if (currentParametro) {
      const indexParametro = tempParametros.findIndex(
        (item) =>
          item.ID === currentParametro.ID,
      );
      tempParametros[indexParametro] = {
        ...tempParametros[indexParametro],
        PARAMETRO: refTextArea.current.value,
        PESO: null,
      };
    } else {
      // Se não for, adiciona
      // Pega o próximo id da lista
      let nextId = -1;
      if (qtyParametros > 0) {
        const menorId = Math.min(...listParametros.map((item) =>
          item.ID));
        if (menorId < 1) {
          nextId = menorId - 1;
        }
      }

      const { CODETAPA, DESCRICAO } = serieData;

      // Cria o novo objeto para ser inserido no array
      newParametro = {
        ID: nextId,
        PARAMETRO: refTextArea.current.value,
        PESO: null,
        CODETAPA,
        CADERNO: DESCRICAO,
        ATIVO: 1,
      };

      // Insere o objeto no array
      tempParametros.push(newParametro);
    }

    // Atualiza o estado
    setListParametros(tempParametros);

    // Limpa o textarea
    refTextArea.current.value = '';
    setCurrentParametro(null);
  }, [currentParametro, listParametros, qtyParametros, serieData]);

  // Mostra a text area para incluir as rubricas
  const showCadastro = useCallback(() => {
    if (qtyParametros >= MAX_PARAMETROS) {
      toast.warn(`Máximo de ${MAX_PARAMETROS} rubricas atingido!`);
      return;
    }
    setAddStatus(true);
  }, [MAX_PARAMETROS, qtyParametros]);

  // Cadastra listas
  const salvaLista = useCallback(() => {
    const { IDTURMADISC } = serieData;
    dispatch(
      saveParametrosRequest({
        IDTURMADISC,
        parametros: listParametros,
      }),
    );
  }, [dispatch, listParametros, serieData]);

  // Seleciona o item para editar
  const editarParametro = (item) => {
    refTextArea.current?.focus();
    refContainer.current.scrollIntoView();
    setAddStatus(true);
    setCurrentParametro(item);
  };

  // Preenche os parâmetros já cadastrados
  useEffect(() => {
    const parametrosAvaliacao = instrumento.filter(
      (item) =>
        item.PARAMETRO !== 'ATIVIDADE' && !isMedia(item.CODPROVA),
    );
    if (parametrosAvaliacao.length < 3) {
      toast.warn(
        'Não há rubricas suficientes cadastradas para essa série e disciplina!',
      );
    }
    setListParametros(parametrosAvaliacao);
  }, [MIN_PARAMETROS, instrumento]);

  // Salva os dados de códigos no estado
  useEffect(() => {
    const dadosMedia = instrumento.filter((item) =>
      isMedia(item.CODPROVA));
    if (dadosMedia.length === 1) {
      setSerieData(dadosMedia[0]);
    }
  }, [instrumento]);

  // altera o textarea com valores do currentParametro
  useEffect(() => {
    if (addStatus && currentParametro) {
      refTextArea.current.value = currentParametro.PARAMETRO;
    }
  }, [addStatus, currentParametro]);

  useEffect(() => {
    const parametrosAvaliacao = listParametros.filter(
      (item) =>
        item.PARAMETRO !== 'ATIVIDADE' && item.ATIVO,
    );
    setQtyParametros(parametrosAvaliacao.length);
  }, [listParametros]);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.3 }}
      ref={refContainer}
    >
      <AnimatePresence>
        {loadingSavingParametros.loading && <Loading />}
      </AnimatePresence>
      <Header>
        {!addStatus && (
          <BtnLink onClick={showCadastro}>+ Incluir parâmetro</BtnLink>
        )}
      </Header>
      <Body>
        <AnimatePresence>
          {/* Se for para exibir o textarea de cadastro */}
          {addStatus && (
            <motion.div
              variants={animations}
              initial="hidden"
              animate="visible"
              exit="hidden"
              key="addParamContainer"
            >
              <TextArea
                ref={refTextArea}
                rows="5"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              />
              <Body.Button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <Button
                  className="btn-danger"
                  onClick={() =>
                    setAddStatus(false)}
                >
                  Cancelar
                </Button>
                <Button onClick={addParametro}>Incluir</Button>
              </Body.Button>
            </motion.div>
          )}

          <ContainerParametros key="parametrosCadastrados">
            <ContainerParametros.Title>
              Parâmetros cadastrados
            </ContainerParametros.Title>
            {/* Checa se há parâmetros cadastrados */}
            {qtyParametros > 0 && (
              <ContainerParametros.List
                animate={{ height: 'auto' }}
                initial={{ opacity: 1 }}
                id="listParametros"
              >
                <AnimatePresence>
                  {/*  Se há parâmetros cadastrados, itera o array para exibir */}
                  {listParametros.map((item, index) =>
                    (item.ATIVO ? (
                      <ItemParametro
                        item={item}
                        index={index}
                        key={item.ID}
                        listParametros={listParametros}
                        setListParametros={setListParametros}
                        handleEdit={editarParametro}
                      />
                    ) : (
                      ''
                    )))}
                </AnimatePresence>
              </ContainerParametros.List>
            )}
          </ContainerParametros>
          {qtyParametros >= MIN_PARAMETROS && (
            <Body.Button exit={{ opacity: 0 }}>
              <Button onClick={salvaLista}>Salvar parâmetros</Button>
            </Body.Button>
          )}
        </AnimatePresence>
      </Body>
    </Container>
  );
}

export default CadastroParametros;
