import {
  put, all, call, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import {
  getDevolutivaSuccess,
  saveRubricaSuccess,
  setAtividadeValueSuccess,
  setAtividadeValueFailure,
  saveRubricaFailure,
  saveParametrosSuccess,
  getEscalaSuccess,
  clearStackSaving,
} from './actions';
import { logout } from '../login/actions';
import { clear, updateNotaRequest } from '../notas/actions';

const { apiAxios } = api;

function* getDevolutiva({ payload }) {
  const { aluno, inst, semestre } = payload;
  try {
    const params = {
      ra: aluno.RA,
      caderno: inst.DESCRICAO,
      semestre,
      idTurmaDisc: inst.IDTURMADISC,
      codDisc: inst.parametrosAvaliacao[0].CODDISC ?? '',
    };
    const response = yield call(api.apiAxios.get, '/devolutiva', { params });
    if (response.status !== 200 && response.status !== 204) {
      throw new Error();
    }
    yield put(getDevolutivaSuccess({ ...response, params }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    toast.error(
      `Erro ao recuperar comentário do(a) aluno(a)  ${aluno.NOMEALUNO}!`,
    );
    yield put(logout());
  }
}

function* saveRubrica({ payload }) {
  try {
    const response = yield call(apiAxios.post, '/notaRubrica', payload);
    const {
      notas, codEtapa, ra, nomeAluno, codDisc,
    } = payload;

    if (response.status === 200) {
      const returnResp = response.data.savedNotas
        .split(/\d{7}/)
        .map((item) =>
          item.split(';'));

      // eslint-disable-next-line no-restricted-syntax
      for (const item of returnResp) {
        const [, codProva, , , idTurmaDisc] = item;
        const val = Object.keys(notas).findIndex((i) =>
          i === codProva);
        const nota = Object.values(notas)[val];
        if (nota >= 0) {
          const instUpdate = {
            CODPROVA: codProva,
            CODETAPA: codEtapa,
            IDTURMADISC: idTurmaDisc,
            CODDISC: codDisc,
          };
          const aluno = { RA: ra };
          yield put(updateNotaRequest(nota, instUpdate, aluno));
        }
      }
      const {
        NOTA, CODPROVA, CODETAPA, IDTURMADISC, RA,
      } = response.data.newNota;
      const instUpdate = {
        CODPROVA, CODETAPA, IDTURMADISC, CODDISC: codDisc,
      };
      const aluno = { RA };
      yield put(updateNotaRequest(NOTA, instUpdate, aluno));
      yield put(saveRubricaSuccess(payload, response.data.newNota));
      toast.success(`Rubrica do(a) aluno(a) ${nomeAluno} salva com sucesso!`);
    }
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data);
    } else {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    yield put(saveRubricaFailure(payload));
  }
}

function* setAtividade({ payload }) {
  try {
    const response = yield call(apiAxios.post, '/atividade', {
      ...payload,
    });
    if (response.status !== 200) {
      throw new Error();
    }
    yield put(
      setAtividadeValueSuccess({
        ...response.data,
        atividadeValue: payload.atividadeValue,
        codEtapa: payload.CODETAPA,
        codProva: payload.CODPROVA,
      }),
    );
  } catch (e) {
    yield put(setAtividadeValueFailure());
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

function setCreateParametros({ payload }) {
  if (payload) {
    // toast.warn(`Não há rubricas cadastradas para essa série e disciplina!`);
  }
}

function* saveParametros({ payload }) {
  try {
    const response = yield call(apiAxios.post, '/parametrosavaliacao', payload);
    if (response.status !== 200) {
      throw new Error('Erro ao salvar os parâmetros');
    }

    const { CODETAPA, CADERNO } = payload.parametros[0];
    const updateResponse = yield call(apiAxios.get, '/parametrosavaliacao', {
      params: {
        CODETAPA,
        IDTURMADISC: payload.IDTURMADISC,
        CADERNO,
      },
    });
    yield put(saveParametrosSuccess({ updateResponse, CODETAPA, CADERNO }));
    toast.success('Parâmetros salvos com sucesso!');
  } catch (e) {
    toast.error('Erro ao salvar os parâmetros');
  }
}

function* saveParametrosAneSintese({ payload }) {
  try {
    const response = yield call(apiAxios.post, '/parametros-anesintese', payload);
    if (response.status !== 200) {
      throw new Error('Erro ao salvar os parâmetros');
    }
    const {
      CODPESSOA: codPessoa,
      IDHABILITACAOFILIAL: idHabilitacaoFilial,
      CODETAPA,
      CADERNO,
    } = payload.parametros[0];
    const updateResponse = yield call(apiAxios.get, '/parametros-anesintese', {
      params: {
        codPessoa,
        idHabilitacaoFilial,
        codEtapa: CODETAPA,
        avaliacao: CADERNO,
      },

    });
    yield put(saveParametrosSuccess({ updateResponse, CODETAPA, CADERNO }));
    toast.success('Parâmetros salvos com sucesso!');
  } catch (e) {
    toast.error('Erro ao salvar os parâmetros');
  }
}

function* wrapperSaveParametros({ payload }) {
  if (payload.IDTURMADISC) {
    yield put({ type: '@parametrosAvaliacao/SAVE_PARAMETROS_CADERNO', payload });
  } else {
    yield put({ type: '@parametrosAvaliacao/SAVE_PARAMETROS_ANESINTESE', payload });
  }
}

function* getEscala({ payload }) {
  try {
    const { escalaIndex } = payload;
    const response = yield call(apiAxios.get, '/parametros/escala', {
      params: { escalaIndex },
    });
    if (response.status !== 200) {
      throw new Error('Ocorreu um erro');
    }
    yield put(getEscalaSuccess(response));
  } catch (e) {
    toast.error('Ocorreu um erro');
    clear();
  }
}

function* clearStack() {
  yield put(clearStackSaving());
}

function* getNiveis({ payload }) {
  try {
    const { codDisc, codEtapa } = payload;
    const response = yield call(apiAxios.get, '/rubricas/niveis', {
      params: { codDisc, codEtapa },
    });
    if (response.status !== 200) {
      throw new Error('Ocorreu um erro');
    }
    yield put(getEscalaSuccess(response));
  } catch (e) {
    toast.error('Ocorreu um erro');
    clear();
  }
}

export default all([
  takeEvery('@rubrica/GET_DEVOLUTIVA_REQUEST', getDevolutiva),
  takeEvery('@rubrica/SAVE_RUBRICA_REQUEST', saveRubrica),
  takeLatest('@rubrica/SET_ATIVIDADE_VALUE_REQUEST', setAtividade),
  takeLatest('@parametrosAvaliacao/SET_CREATE_PARAMETROS', setCreateParametros),
  takeLatest('@parametrosAvaliacao/SAVE_PARAMETROS_REQUEST', wrapperSaveParametros),
  takeLatest('@parametrosAvaliacao/SAVE_PARAMETROS_ANESINTESE', saveParametrosAneSintese),
  takeLatest('@parametrosAvaliacao/SAVE_PARAMETROS_CADERNO', saveParametros),
  takeLatest('@parametrosAvaliacao/GET_ESCALA_REQUEST', getEscala),
  takeLatest('@notas/SET_INSTRUMENTO', clearStack),
  takeLatest('@rubrica/GET_NIVEIS_REQUEST', getNiveis),
]);
