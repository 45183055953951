import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FotoAluno from '../FotoAluno';
import useNotas from '../../hooks/useNotas';
import useCadernos from '../../hooks/useCadernos';
import SwitchButton from '../SwitchButton';
import { COD_CURSO_EM, COD_HABILITACAO_3EM } from '../../helpers/consts';

import {
  TextFiltrarAluno,
  TextNota,
  Container,
  ContainerTable,
  Table,
  ButtonPhotos,
  ContainerButtons,
} from '../../styles/Notas';

export default function ListAlunosNotas({ alunos, nomeDisc }) {
  const [alunosList, setAlunosList] = useState(alunos);
  const instrumento = useSelector((state) =>
    state.notas.instrumento);
  const { CODCURSO, CODHABILITACAO } = useSelector((state) =>
    state.turma.turmaDisciplina);
  const [showPhotos, setShowPhotos] = useState(false);
  const {
    pressEnter, saveNota, getNota, filtro, setFiltro, setCurrentNota,
  } = useNotas();

  const { handleLiberarNotas, notaLiberada } = useCadernos();

  useEffect(() => {
    setAlunosList(
      alunos.filter((aluno) =>
        aluno.NOMEALUNO.startsWith(filtro.toUpperCase())),
    );

    return () =>
      setAlunosList([]);
  }, [filtro, alunos]);

  const HandleNotas = () => {
    if (instrumento.length === 1
      && [1, 2, 6, 7].includes(Number(instrumento[0].CODETAPA))
      && !(Number(CODCURSO) === Number(COD_CURSO_EM)
      && Number(CODHABILITACAO) === Number(COD_HABILITACAO_3EM))) {
      return (
        <SwitchButton
          itemOff="Notas bloqueadas"
          itemOn="Notas liberadas"
          width={30}
          height={4}
          id="btnLiberarNotas"
          onClick={handleLiberarNotas}
          status={notaLiberada}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <Container>
        {instrumento.length > 0 && (
          <>
            <TextFiltrarAluno.Label>
              <TextFiltrarAluno
                onChange={(e) =>
                  setFiltro(e.currentTarget.value)}
                value={filtro}
              />
              <TextFiltrarAluno.Span>Filtrar aluno</TextFiltrarAluno.Span>
            </TextFiltrarAluno.Label>
            <ContainerButtons>
              <ButtonPhotos onClick={() =>
                setShowPhotos((old) =>
                  !old)}
              >
                {(!showPhotos && 'Mostrar ') || 'Ocultar '}
                fotos
              </ButtonPhotos>
              <ContainerButtons.Right>
                <HandleNotas />
              </ContainerButtons.Right>
            </ContainerButtons>
            <ContainerTable>
              <Table>
                <Table.Header>
                  <Table.Header.Row>
                    {showPhotos && (
                      <Table.Header.Col className="col-photo">
                        #
                      </Table.Header.Col>
                    )}
                    <Table.Header.Col className="col-name">
                      Nome
                    </Table.Header.Col>
                    {instrumento.map((inst) =>
                      (
                        <Table.Header.Col
                          key={inst.CODPROVA}
                          style={{ maxWidth: '5%' }}
                        >
                          {inst.DESCRICAO}
                        </Table.Header.Col>
                      ))}
                  </Table.Header.Row>
                </Table.Header>
                <Table.Body>
                  {alunosList.map((aluno) =>
                    (
                      <Table.Body.Row key={aluno.RA} className="linha-nota">
                        {showPhotos && (
                        <Table.Body.Cell className="col-photo">
                          <FotoAluno nomeAluno={aluno.NOMEALUNO} />
                        </Table.Body.Cell>
                        )}
                        <Table.Body.Cell className="col-name">
                          {aluno.NOMEALUNO}
                        </Table.Body.Cell>
                        {instrumento.map((inst) =>
                          (
                            <Table.Body.Cell key={inst.CODPROVA}>
                              <TextNota
                                defaultValue={getNota(
                                  inst.CODPROVA,
                                  inst.CODETAPA,
                                  aluno.RA,
                                )}
                                status=""
                                onKeyDown={pressEnter}
                                onBlur={(e) =>
                                  saveNota(inst, aluno, nomeDisc, e)}
                                onFocus={(e) =>
                                  setCurrentNota(e.currentTarget.value)}
                              />
                            </Table.Body.Cell>
                          ))}
                      </Table.Body.Row>
                    ))}
                </Table.Body>
              </Table>
            </ContainerTable>
          </>
        )}
      </Container>
    </>
  );
}
