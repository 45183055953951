import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import useNotas from '../../hooks/useNotas';
import useCadernos from '../../hooks/useCadernos';
import SwitchButton from '../SwitchButton';
import { isMedia } from '../../helpers';

import * as RubricaActions from '../../store2/modules/rubrica/actions';

import {
  TextFiltrarAluno,
  TextNota,
  Container,
  ContainerTable,
  Table,
  ButtonPhotos,
  Button,
  ContainerButtons,
} from '../../styles/Notas';

import AnimateContainer from '../AnimateContainer';
import ListRow from './ListRowCaderno';
import ModalParametrosAvaliacao from './ModalParametrosAvaliacao-2024-1SEM';
import ModalParametrosAvaliacaoC120221 from './ModalParametrosAvaliacao-C1-2022-1';

export default function ListAlunosNotas({ alunos }) {
  // const [alunosList, setAlunosList] = useState(alunos);
  const codPerlet = alunos[0].CODPERLET;
  const [showPhotos, setShowPhotos] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasAtividade, setHasAtividade] = useState(false);
  const [currentInstrumento, setCurrentInstrumento] = useState({});

  const instrumento = useSelector(
    (state) =>
      state.notas.instrumento, shallowEqual,
  );

  const loadingLiberarNotas = useSelector(
    (state) =>
      state.notas.loadingLiberarNotas,
  );

  const atividadeValue = useSelector(
    (state) =>
      state.rubrica.atividadeValue,
  );

  const loadingSavingAtividade = useSelector(
    (state) =>
      state.rubrica.loadingSavingAtividade, shallowEqual,
  );

  const { pressEnter, filtro, setFiltro } = useNotas();
  const {
    setAtividadeValue, handleLiberarNotas, notaLiberada,
  } = useCadernos();

  const alunosList = alunos.filter((aluno) =>
    aluno.NOMEALUNO.includes(filtro.toUpperCase()));
  // const [downloadingCadernos, setDownloadingCadernos] = useState(false);

  const dispatch = useDispatch();

  /*
  ******** FUNÇÃO DESABILITADA *********
  const getCadernos = useCallback(async () => {
    setLoading(true);
    const result = await api.hasCadernos({
      alunos,
      instrumento,
      turmaDisciplina,
    });
    setCadernos(result);
    setLoading(false);
  }, [alunos, instrumento, turmaDisciplina]); */

  useEffect(() => {
    setLoading(true);
    const mediaInst = instrumento.find((item) =>
      isMedia(item.CODPROVA));
    setCurrentInstrumento(mediaInst);
    if (mediaInst.NUMQUESTOES) {
      dispatch(
        RubricaActions.setAtividadeValueSuccess({
          atividadeValue: mediaInst.NUMQUESTOES,
        }),
      );

      setHasAtividade(true);
    } else {
      dispatch(RubricaActions.setAtividadeValueSuccess(''));
      setHasAtividade(false);
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumento]);

  const handleAtividade = () => {
    if (hasAtividade) {
      setHasAtividade(false);
      if (atividadeValue !== '') {
        setAtividadeValue('');
      }
    } else {
      setHasAtividade(true);
    }
  };

  const classAtividadeValue = () => {
    if (loadingSavingAtividade.loading) {
      return 'jump';
    }

    if (loadingSavingAtividade.status === 200) {
      return 'saveNota';
    }

    if (loadingSavingAtividade.status === 500) {
      return 'errorNota';
    }

    return '';
  };

  const ModalParametrosAvaliacaoWrapper = useCallback(() => {
    if (Object.keys(currentInstrumento).length === 0) {
      return <></>;
    }
    if (
      codPerlet === '2022'
      && currentInstrumento.CODETAPA === '1'
      && currentInstrumento.CODPROVA === '1'
    ) {
      return <ModalParametrosAvaliacaoC120221 />;
    }
    return <ModalParametrosAvaliacao />;
  }, [codPerlet, currentInstrumento]);

  const handleBlurAtividade = (e) => {
    setAtividadeValue(e.currentTarget.value, e);
  };
  return (
    <>
      <AnimateContainer loading={loading || loadingLiberarNotas}>
        <Container>
          {instrumento.length > 0 && (
            <>
              <TextFiltrarAluno.Label>
                <TextFiltrarAluno
                  onChange={(e) =>
                    setFiltro(e.currentTarget.value)}
                  value={filtro}
                />
                <TextFiltrarAluno.Span>Filtrar aluno</TextFiltrarAluno.Span>
              </TextFiltrarAluno.Label>
              <ContainerButtons>
                <ContainerButtons.Left>
                  <ButtonPhotos onClick={() =>
                    setShowPhotos((old) =>
                      !old)}
                  >
                    {(!showPhotos && 'Mostrar ') || 'Ocultar '}
                    fotos
                  </ButtonPhotos>
                  <SwitchButton
                    itemOff="Notas bloqueadas"
                    itemOn="Notas liberadas"
                    width={30}
                    height={4}
                    status={notaLiberada}
                    onClick={handleLiberarNotas}
                  />
                </ContainerButtons.Left>
                <ContainerButtons.Right>
                  <Button onClick={handleAtividade}>
                    {hasAtividade ? 'Remover Atividade' : 'Atividade'}
                  </Button>
                  {hasAtividade && (
                    <TextNota
                      defaultValue={atividadeValue}
                      status=""
                      type="number"
                      min={1}
                      max={99}
                      step={1}
                      onKeyDown={pressEnter}
                      className={classAtividadeValue()}
                      onBlur={handleBlurAtividade}
                    />
                  )}
                  {/*
                  ******** FUNÇÃO DESABILITADA *********
                  downloadingCadernos ? (
                    <button
                      type="button"
                      style={{ background: 'transparent', border: 'none' }}
                    >
                      <Spinner size={0.3} style={{ margin: '0 1.5rem' }} />
                    </button>
                  ) : (
                    <Button onClick={downloadAllCadernos}>
                      Download cadernos
                    </Button>
                  ) */}
                </ContainerButtons.Right>
              </ContainerButtons>
              <ContainerTable>
                <Table>
                  <Table.Header>
                    <Table.Header.Row>
                      {showPhotos && (
                        <Table.Header.Col className="col-photo">
                          #
                        </Table.Header.Col>
                      )}
                      <Table.Header.Col className="col-name">
                        Nome
                      </Table.Header.Col>
                      {/*
                      ******** FUNÇÃO DESABILITADA *********
                       <Table.Header.Col className="col-name">
                        Caderno
                      </Table.Header.Col> */}
                      {hasAtividade && (
                        <>
                          <Table.Header.Col>Parâmetros</Table.Header.Col>
                          <Table.Header.Col>Atividade</Table.Header.Col>
                        </>
                      )}
                      {instrumento.map((inst) =>
                        (
                          isMedia(inst.CODPROVA) && (
                          <Table.Header.Col
                            key={`${inst.CADERNO}-${inst.CODPROVA}`}
                            className="media"
                          >
                            {inst.DESCRICAO}
                          </Table.Header.Col>
                          )
                        ))}
                    </Table.Header.Row>
                  </Table.Header>
                  <Table.Body>
                    {/* LISTA OS ALUNOS */}
                    {alunosList.map((aluno) =>
                      (
                        <ListRow
                          aluno={aluno}
                          showPhotos={showPhotos}
                          key={aluno.RA}
                          hasAtividade={hasAtividade}
                          instrumento={instrumento}
                        />
                      ))}
                  </Table.Body>
                </Table>
              </ContainerTable>
            </>
          )}
        </Container>
      </AnimateContainer>
      {!loading && <ModalParametrosAvaliacaoWrapper />}
    </>
  );
}
