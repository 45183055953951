/*

Modal que gerência os parâmetros de avaliação
Verifica se existem os parâmetros cadastrados, caso não existe abre a tela de cadastro

*/

import React, { useEffect } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import Modal from '../../Modal';
import useModal from '../../../hooks/useModal';
import { Spinner } from '../../Loading';
import SelectField from '../../SelectField';

import { ModalContainer, Button } from '../../../styles/Notas';
import useCadernos from '../../../hooks/useCadernos';
import * as RubricaActions from '../../../store2/modules/rubrica/actions';

import CadastroParametros from './Components/CadastroParametros';
import { isMedia } from '../../../helpers';

function ModalParametrosAvaliacao() {
  const dispatch = useDispatch();
  const [statusModal, toggleModal] = useModal();

  const {
    handleSelect,
    setComments,
    saveRubrica,
    validateInput,
    rubricaTotal,
  } = useCadernos();

  const rubricaData = useSelector((state) =>
    state.rubrica.rubricaData);
  const loadingSaveRubrica = useSelector((state) =>
    state.rubrica.loadingSaveRubrica);
  const escala = useSelector((state) =>
    state.rubrica.escala);
  const loadingComments = useSelector((state) =>
    state.rubrica.loadingComments);
  const comments = useSelector((state) =>
    state.rubrica.comments);
  const escalaValues = useSelector((state) =>
    state.rubrica.escalaValues);
  const showCreateParametros = useSelector((state) =>
    state.rubrica.showCreateParametros);
  const loadingSavingParametros = useSelector((state) =>
    state.rubrica.loadingSavingParametros);
  const loadingEscala = useSelector((state) =>
    state.rubrica.loadingEscala);
  const toggleModalSelector = useSelector((state) =>
    state.rubrica.modalRubrica.toggleModal);
  const statusModalSelector = useSelector((state) =>
    state.rubrica.modalRubrica.statusModal);

  const instrumento = useSelector((state) =>
    state.notas.instrumento, shallowEqual);

  useEffect(() => {
    if (
      loadingSaveRubrica.loading === false
      && loadingSaveRubrica.status === 200
      && statusModal === true
    ) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSaveRubrica.loading, loadingSaveRubrica.status]);

  useEffect(() => {
    if (toggleModalSelector !== toggleModal || statusModalSelector !== statusModal) {
      dispatch(RubricaActions.setModal({ statusModal, toggleModal }));
    }

    // Verifica se o modal mudou para "close" e seta para false a tela de criação de parâmetros
    if (!statusModal) {
      dispatch(RubricaActions.setCreateParametros(false));
    } else {
      // pega os parâmetros que não são atividade e conta
      const qtdeParametros = instrumento.filter((item) =>
        item.PARAMETRO !== 'ATIVIDADE').length - 1;
      dispatch(
        RubricaActions.getEscalaRequest({ escalaIndex: qtdeParametros }),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusModal]);

  useEffect(() => {
    if (
      loadingSavingParametros.loading === false
      && loadingSavingParametros.status === 200
      && statusModal === true
    ) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSavingParametros.loading, loadingSavingParametros.status]);

  const handleSaveRubrica = () => {
    saveRubrica();
    toggleModal();
  };

  return (
    <Modal
      showModal={statusModal}
      hide={toggleModal}
      width="80%"
      titulo={`Nome: ${rubricaData.nomeAluno} - Turma: ${rubricaData.turma}`}
      createParametros={showCreateParametros}
    >
      <ModalContainer className="rubrica">
        <AnimatePresence>
          {(showCreateParametros && (
            <CadastroParametros
              key="cadastroParametros"
              initial={{ opacity: 1 }}
            />
          )) || (
            <motion.div key="parametrosNotas" exit={{ opacity: 0 }}>
              <h2>{rubricaData.disciplina}</h2>
              <Button
                onClick={() =>
                  dispatch(RubricaActions.setCreateParametros(true))}
              >
                Editar parâmetros
              </Button>
              <table>
                <thead>
                  <tr className="header">
                    <th>Parâmetro de avaliação</th>
                    <th>Escala de proficiência</th>
                  </tr>
                </thead>
                <tbody>
                  {instrumento.map((inst) => {
                    let defaultValue = '-';
                    if (
                      inst.PARAMETRO !== 'ATIVIDADE'
                      && !isMedia(inst.CODPROVA)
                    ) {
                      const notaIndex = Object.keys(escalaValues).findIndex(
                        (i) =>
                          i === inst.CODPROVA,
                      );
                      if (notaIndex > -1) {
                        defaultValue = Object.values(escalaValues)[notaIndex].toString()
                          .replace('.', ',');
                      }
                    }

                    return (
                      statusModal
                      && inst.PARAMETRO !== 'ATIVIDADE'
                      && !isMedia(inst.CODPROVA) && (
                        <tr key={inst.CODPROVA}>
                          <td>{inst.PARAMETRO}</td>
                          <td>
                            {(loadingEscala && <Spinner size={0.3} />) || (
                              <SelectField
                                items={escala}
                                onChange={(e) =>
                                  handleSelect(
                                    inst.CODPROVA,
                                    e.currentTarget.value,
                                  )}
                                defaultValue={defaultValue}
                              />
                            )}
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td />
                    <td>{rubricaTotal >= 9.8 ? 10 : rubricaTotal}</td>
                  </tr>
                </tfoot>
              </table>
              <div className="comentario-aluno">
                <label htmlFor="comments">
                  <span>Comentários para o aluno</span>
                  {loadingComments ? (
                    <Spinner />
                  ) : (
                    <textarea
                      id="comments"
                      style={{ padding: '1rem' }}
                      rows="5"
                      value={comments.observacoes}
                      onChange={(e) =>
                        setComments({
                          ...comments,
                          observacoes: e.currentTarget.value,
                        })}
                      disabled={loadingComments}
                    />
                  )}
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                {validateInput() && (
                  <Button onClick={handleSaveRubrica}>Salvar</Button>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ModalContainer>
    </Modal>
  );
}

export default ModalParametrosAvaliacao;
