import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const apiAxios = axios.create({
  baseURL: apiUrl,
});

const cancelToken = axios.CancelToken;
const source = cancelToken.source();

const login = (user, pass) =>
  apiAxios
    .post(
      'Login',
      { user, pass },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) =>
      response.data)
    .catch((e) =>
      e.response);

const getData = (url) =>
  apiAxios
    .get(url, { cancelToken: source.token })
    .then((response) =>
      response.data)
    .catch((response) => {
      sessionStorage.clear();
      return response.response ? response.response.data : null;
    });

const deleteData = (url, data) =>
  apiAxios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then((response) =>
      response.data);

const setData = (url, data) =>
  apiAxios.post(url, data).then((response) =>
    response.data);

const getAulasDia = () =>
  getData('aulasDia');

const getAulasGeral = (tipo) =>
  getData(`aulasGeral?tipo=${tipo}`);

const getProfessor = () =>
  getData('Professor');

const getDias = (idTurmaDisc) =>
  getData(`aulasDiaTurmaDisc?idTurmaDisc=${idTurmaDisc}`);

const getDiasGeral = (idTurmaDisc, dataInicio, dataFinal) =>
  getData(
    `aulasGeralTurmaDisc?idTurmaDisc=${idTurmaDisc}&dataInicio=${dataInicio}&dataFinal=${dataFinal}`,
  );

const getEtapas = (idTurmaDisc) =>
  getData(`etapa?idTurmaDisc=${idTurmaDisc}`);

const getEtapasAneSintese = (idTurmaDisc) =>
  getData(`etapaAneSintese?idTurmaDisc=${idTurmaDisc}`);

const getAvaliacoes = (codEtapa, idTurmaDisc) =>
  getData(`avaliacoes?codEtapa=${codEtapa}&idTurmaDisc=${idTurmaDisc}`);

const getAvaliacoesAneSintese = (codEtapa, codPessoa, idHabilitacaoFilial) =>
  getData(
    `avaliacoesAneSintese?codEtapa=${codEtapa}&codPessoa=${codPessoa}&idHabilitacaoFilial=${idHabilitacaoFilial}`,
  );

const getAlunos = (codTurma, idTurmaDisc) =>
  getData(`alunos?codTurma=${codTurma}&idTurmaDisc=${idTurmaDisc}`);

const getTurmaDisciplina = (idTurmaDisc) =>
  getData(`turmaDisciplina?idTurmaDisc=${idTurmaDisc}`);

const getFaltasAlunos = (idTurmaDisc) =>
  getData(`FrequenciaDiaria?idTurmaDisc=${idTurmaDisc}`);

const getFaltasAlunosGeral = (idTurmaDisc, dataInicio, dataFinal) =>
  getData(
    `FrequenciaGeral?idTurmaDisc=${idTurmaDisc}&dataInicio=${dataInicio}&dataFinal=${dataFinal}`,
  );

const getOcorrenciasAluno = (ra, data) =>
  getData(`OcorrenciasAluno?ra=${ra}&data=${data}`);

const getTiposOcorrencia = (codGrupo) =>
  getData(`OcorrenciaTipo?codGrupo=${codGrupo}`);

const getOcorrenciasAlunoTurmaDisc = (ra, data, idTurmaDisc) =>
  getData(
    `OcorrenciasAlunoTurmaDisc?ra=${ra}&data=${data}&idTurmaDisc=${idTurmaDisc}`,
  );

const getTodasOcorrenciasAlunoTurmaDisc = (ra, data, idTurmaDisc) =>
  getData(
    `OcorrenciasGeralAlunoTurmaDisc?ra=${ra}&data=${data}&idTurmaDisc=${idTurmaDisc}`,
  );

const getOcorrenciasTurmaDisc = (data, idTurmaDisc) =>
  getData(
    `OcorrenciasTurmaDisc?data=${data}&idTurmaDisc=${idTurmaDisc}`,
  );

const getOcorrenciasTurmaDiscRange = (data, dataFinal, idTurmaDisc) =>
  getData(
    `OcorrenciasTurmaDisc?data=${data}&dataFinal=${dataFinal}&idTurmaDisc=${idTurmaDisc}`,
  );

const getNotasEtapa = (idTurmaDisc, codEtapa) =>
  getData(`notasEtapa?idTurmaDisc=${idTurmaDisc}&codEtapa=${codEtapa}`);

const getTiposFalta = () =>
  getData('tiposFalta');

const setNota = (data) =>
  setData('nota', data);

const deleteOcorrencia = (idOcorr) =>
  deleteData('ocorrenciasAluno', idOcorr);

const setFrequencia = (data) =>
  setData('FrequenciaDiaria', data);

const setOcorrencia = (data) =>
  setData('ocorrenciasAluno', data);

const getUserToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

const getLoginUrl = () =>
  getData('loginUrl');

const getLicao = (idTurmaDisc, diaLicao, idHabilitacaoFilial, codDisc, codPessoa) =>
  getData(
    `licao?idTurmaDisc=${idTurmaDisc}&data=${diaLicao}&dataEntrega=${diaLicao}&idHabilitacaoFilial=${idHabilitacaoFilial}&codDisc=${codDisc}&codPessoa=${codPessoa}`,
  );

const getLicoes = (codLicao, idLicao, dataEntrega) =>
  getData(`licoes?codLicao=${codLicao}&idLicao=${idLicao}&dataEntrega=${dataEntrega}`);

const checkUser = (data) =>
  setData('checkUser', data);

const getFoto = (aluno) =>
  apiAxios.get(`alunos/fotos/${aluno}.jpg`).then((response) =>
    response.data);

const hasCadernos = (data) =>
  apiAxios.post('hasCadernos', data);

const isMaster = (data) =>
  apiAxios.get('check-master-user', data);

const setUserMaster = (data) =>
  apiAxios.post('select-user-master', data);

const getLivros = (data) =>
  apiAxios.get('livros', data);

const saveLivro = (data) =>
  apiAxios.post('livro', data);

const getSeriesProfessor = (data) =>
  apiAxios.get('series-professor', data);

const getDisciplinasSerieProfessor = (data) =>
  apiAxios.get('disciplinas-serie-professor', data);

const saveCronograma = (data) =>
  apiAxios.post('cronograma', data);

const getCronogramas = (data) =>
  apiAxios.get('cronogramas', data);

const api = {
  getCronogramas,
  saveCronograma,
  getDisciplinasSerieProfessor,
  getSeriesProfessor,
  saveLivro,
  getLivros,
  setUserMaster,
  isMaster,
  getAulasDia,
  getAlunos,
  getTurmaDisciplina,
  getDias,
  getFaltasAlunos,
  setFrequencia,
  setOcorrencia,
  login,
  getProfessor,
  getUserToken,
  getAulasGeral,
  getDiasGeral,
  getFaltasAlunosGeral,
  getOcorrenciasAluno,
  getOcorrenciasAlunoTurmaDisc,
  getTodasOcorrenciasAlunoTurmaDisc,
  getTiposOcorrencia,
  deleteOcorrencia,
  getOcorrenciasTurmaDisc,
  getOcorrenciasTurmaDiscRange,
  getEtapas,
  getEtapasAneSintese,
  getAvaliacoes,
  getAvaliacoesAneSintese,
  getNotasEtapa,
  setNota,
  getLoginUrl,
  getTiposFalta,
  checkUser,
  getLicao,
  getLicoes,
  apiAxios,
  getFoto,
  source,
  hasCadernos,
};

export default api;
