import styled from 'styled-components';

export const Titulo = styled.h1`
  font-size: 3rem;
  font-weight: 300;
`;

export const SubTitulo = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
`;
