import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import FotoAluno from '../FotoAluno';
import Modal from '../Modal';
import useModal from '../Modal/hooks/useModal';
import MessageBox from '../MessageBox';
import Loading from '../Loading';

import { Container, Table, ContainerTable } from './styles';

export default function ListAlunos({
  alunos, dias, ocorrencias, loading,
}) {
  const [obs, setObs] = useState();
  const [alunosList, setAlunosList] = useState(alunos);
  const [statusModal, toggleModal] = useModal();

  useEffect(() => {
    const ras = [];
    dias
      .map((dia) =>
        ocorrencias[format(dia, 'yyyy-MM-dd').toString()])
      .map((ocorrencia) =>
        ocorrencia.map((aluno) =>
          ras.push(aluno.RA)));

    setAlunosList(alunos.filter((aluno) =>
      ras.includes(aluno.RA)));
  }, [alunos, dias, ocorrencias]);

  const getObservacoes = (id, data) => {
    setObs(
      ocorrencias[data].filter((ocorrencia) =>
        ocorrencia.IDOCORALUNO === id),
    );
    toggleModal();
  };

  const getOcorrenciasAlunoDia = (ra, data) =>
    ocorrencias[format(data, 'yyyy-MM-dd').toString()]
      .filter((ocorrencia) =>
        ocorrencia.RA === ra)
      .map((ocorrencia) =>
        (
          <li key={ocorrencia.IDOCORALUNO}>
            {(ocorrencia.OBSERVACOES && (
            <button
              type="button"
              onClick={() =>
                getObservacoes(
                  ocorrencia.IDOCORALUNO,
                  format(data, 'yyyy-MM-dd').toString(),
                )}
            >
              {`${ocorrencia.DESCGRUPOOCOR} - ${ocorrencia.DESCTIPOOCOR}`}
            </button>
            ))
            || `${ocorrencia.DESCGRUPOOCOR} - ${ocorrencia.DESCTIPOOCOR}`}
          </li>
        ));

  if (loading) {
    return <Loading />;
  }

  if (alunosList.length <= 0) {
    return <MessageBox type="primary" message="Nenhuma data selecionada" />;
  }

  return (
    <>
      <Container>
        <ContainerTable>
          <Table>
            <Table.Header>
              <Table.Header.Row>
                <Table.Header.Col>#</Table.Header.Col>
                <Table.Header.Col className="col-name">Nome</Table.Header.Col>
                {dias.length > 0
                  && dias.map((dia) =>
                    (
                      <Table.Header.Col key={dia.toDateString()}>
                        {format(dia, 'dd/MM/yyyy')}
                      </Table.Header.Col>
                    ))}
              </Table.Header.Row>
            </Table.Header>
            <Table.Body>
              {alunosList.map((aluno) =>
                (
                  <Table.Body.Row key={aluno.RA}>
                    <Table.Body.Cell as="th" className="col-photo">
                      <FotoAluno nomeAluno={aluno.NOMEALUNO} />
                    </Table.Body.Cell>
                    <Table.Body.Cell className="col-name">
                      {aluno.NOMEALUNO}
                    </Table.Body.Cell>
                    {dias.length > 0
                    && dias.map((dia) =>
                      (
                        <Table.Body.Cell
                          className="col-ocorrencias"
                          key={dia.toDateString()}
                        >
                          <ul>{getOcorrenciasAlunoDia(aluno.RA, dia)}</ul>
                        </Table.Body.Cell>
                      ))}
                  </Table.Body.Row>
                ))}
            </Table.Body>
          </Table>
        </ContainerTable>
      </Container>
      <Modal
        showModal={statusModal}
        hide={toggleModal}
        titulo="Justificativa da ausência"
      >
        <h2>{(obs && obs[0].OBSERVACOES) || null}</h2>
      </Modal>
    </>
  );
}
