import styled from 'styled-components';

export const CenterDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content  : center ;
    align-items: center;
    flex-direction: column;
`;

export const InputLabel = styled.label`
    display: block;
    font-size: 1.6rem;
`;

export const Input = styled.input`
    display: block;
    border: none;
    padding: 5px;
    margin: 0;
    border-bottom: 1px solid #ccc;
    font-size: 1.6rem;
    color: #aaa;
    width: 40vw;
    min-width: 300px;
    max-width: 500px;
`;

export const SuggestionList = styled.ul`
    width: 40vw;
    min-width: 300px;
    max-width: 500px;
    list-style: none;
    padding-top: 2px;
    border-radius: 5px;
    max-height: 50vh;

    button {
        background: none;
        border: none;
        text-align: left;
        cursor: pointer;
    }
`;

SuggestionList.Item = styled.li`
    padding: 0.8rem;
    font-size: 1.2rem;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    cursor: pointer;
    transition: 0.3s linear all;
    border-radius: 0.3rem;

    &:nth-child(1) {
        border-top: 1px solid #ccc;
    }

    &:hover {
        background-color: rgba(150,150,150,0.1);
    }
`;
