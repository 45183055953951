import styled from 'styled-components';

export default styled.div`
  border-radius: 50%;
  box-shadow: 0 0 1em #ccc;
  display: inline-block;
  position: relative;
  width: ${({ size }) =>
    size}rem;
  height: ${({ size }) =>
    size}rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;
