/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = {
  rubricaData: {
    ra: '', nomeAluno: '', turma: '', disciplina: '', notas: {},
  },
  atividadeValue: '',
  loadingSaveRubrica: { loading: false, status: 0 },
  loadingComments: false,
  loadingSavingAtividade: { loading: false, status: 0 },
  escala: [
    { value: '0', label: 0 },
    { value: '1', label: 1 },
    { value: '2', label: 2 },
    { value: '3', label: 3 },
    { value: '4', label: 4 },
    { value: '5', label: 5 },
    { value: '6', label: 6 },
    { value: '7', label: 7 },
    { value: '8', label: 8 },
    { value: '9', label: 9 },
    { value: '10', label: 10 },
  ],
  comments: {
    idocorrencia: 0,
    observacoes: '',
    semestre: 0,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  modalRubrica: { statusModal: false, toggleModal() { } },
  escalaValues: {},
  stackSavingRubrica: [],
  showCreateParametros: false,
  loadingSavingParametros: { loading: false, status: 0 },
  loadingEscala: false,

};

export default function rubrica(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@rubrica/SET_RUBRICA_DATA': {
        draft.rubricaData = action.payload;
        break;
      }
      case '@rubrica/SET_ATIVIDADE_VALUE_REQUEST': {
        draft.loadingSavingAtividade.loading = true;
        break;
      }
      case '@rubrica/SET_ATIVIDADE_VALUE_SUCCESS': {
        draft.atividadeValue = action.payload.atividadeValue || '';
        draft.loadingSavingAtividade.loading = false;
        draft.loadingSavingAtividade.status = 200;
        break;
      }
      case '@rubrica/SET_ATIVIDADE_VALUE_FAILURE': {
        draft.loadingSavingAtividade.loading = false;
        draft.loadingSavingAtividade.status = 500;
        break;
      }
      case '@rubrica/SET_COMMENTS': {
        const { payload } = action;
        draft.comments = payload;
        break;
      }
      case '@rubrica/SET_LOADING_COMMENTS': {
        draft.loadingComments = action.payload;
        break;
      }
      case '@rubrica/GET_DEVOLUTIVA_REQUEST': {
        draft.loadingComments = true;
        break;
      }
      case '@rubrica/GET_DEVOLUTIVA_SUCCESS': {
        draft.loadingComments = false;

        if (action.payload.status === 204) {
          draft.comments = {
            ...INITIAL_STATE.comments,
            ...action.payload.params,
          };
        } else {
          const [comments] = action.payload.data;
          draft.comments = comments || INITIAL_STATE.comments;
        }
        break;
      }
      case '@rubrica/SET_MODAL': {
        draft.modalRubrica.statusModal = action.payload.statusModal;
        draft.modalRubrica.toggleModal = action.payload.toggleModal;
        break;
      }
      case '@rubrica/SET_ESCALAVALUES': {
        draft.escalaValues = action.payload;
        break;
      }
      case '@rubrica/SAVE_RUBRICA_REQUEST': {
        const { ra } = action.payload;
        const indexItem = draft.stackSavingRubrica.findIndex(
          (item) =>
            item.ra === ra,
        );

        const objAluno = {
          ...action.payload,
          statusLoading: { loading: true, status: 0 },
        };

        if (indexItem > -1) {
          draft.stackSavingRubrica[indexItem] = objAluno;
        } else {
          draft.stackSavingRubrica.push(objAluno);
        }
        break;
      }
      case '@rubrica/SAVE_RUBRICA_SUCCESS': {
        const { ra } = action.payload;
        const indexItem = draft.stackSavingRubrica.findIndex(
          (item) =>
            item.ra === ra,
        );
        const objAluno = {
          ...action.payload,
          statusLoading: { loading: false, status: 200 },
        };

        draft.stackSavingRubrica[indexItem] = objAluno;
        break;
      }
      case '@rubrica/SAVE_RUBRICA_FAILURE': {
        const { ra } = action.payload;
        const indexItem = draft.stackSavingRubrica.findIndex(
          (item) =>
            item.ra === ra,
        );

        const objAluno = {
          ...action.payload,
          statusLoading: { loading: false, status: 500 },
        };

        draft.stackSavingRubrica[indexItem] = objAluno;
        break;
      }
      case '@rubrica/CLEAR_STACKSAVING': {
        draft.stackSavingRubrica = [];
        break;
      }
      case '@parametrosAvaliacao/SAVE_PARAMETROS_REQUEST': {
        draft.loadingSavingParametros.loading = true;
        draft.loadingSavingParametros.status = 0;
        break;
      }
      case '@parametrosAvaliacao/SAVE_PARAMETROS_ANESINTESE_REQUEST': {
        draft.loadingSavingParametros.loading = true;
        draft.loadingSavingParametros.status = 0;
        break;
      }
      case '@parametrosAvaliacao/SAVE_PARAMETROS_SUCCESS': {
        draft.loadingSavingParametros.loading = false;
        draft.loadingSavingParametros.status = 200;
        break;
      }
      case '@parametrosAvaliacao/SET_CREATE_PARAMETROS': {
        const value = action.payload;
        draft.showCreateParametros = value;
        break;
      }
      case '@parametrosAvaliacao/GET_ESCALA_REQUEST': {
        draft.loadingEscala = true;
        break;
      }
      case '@parametrosAvaliacao/GET_ESCALA_SUCCESS': {
        const value = action.payload;
        draft.escala = value.data;
        draft.loadingEscala = false;
        break;
      }

      case '@rubrica/GET_NIVEIS_REQUEST': {
        draft.loadingEscala = true;
        break;
      }

      case '@rubrica/GET_NIVEIS_SUCCESS': {
        const value = action.payload;
        draft.escala = value.data;
        draft.loadingEscala = false;
        break;
      }
      default:
    }
  });
}
