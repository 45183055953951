export function getEtapasRequest(idTurmaDisc, semestre) {
  return {
    type: '@notas/GET_ETAPAS_REQUEST',
    payload: { idTurmaDisc, semestre },
  };
}

export function getEtapasAneSinteseRequest(idTurmaDisc, semestre) {
  return {
    type: '@notas/GET_ETAPASANESINTESE_REQUEST',
    payload: { idTurmaDisc, semestre },
  };
}

export function getEtapasSuccess(payload) {
  return { type: '@notas/GET_ETAPAS_SUCCESS', payload };
}

/* export function getEtapasAneSinteseSuccess(payload) {
  return { type: '@notas/GET_ETAPASANESINTESE_SUCCESS', payload };
}
 */
export function getAvaliacoesRequest(etapa, idTurmaDisc) {
  return {
    type: '@notas/GET_AVALIACOES_REQUEST',
    payload: { etapa, idTurmaDisc },
  };
}

export function getAvaliacoesAneSinteseRequest(etapa, idTurmaDisc) {
  return {
    type: '@notas/GET_AVALIACOESANESINTESE_REQUEST',
    payload: { etapa, idTurmaDisc },
  };
}

export function getAvaliacoesSuccess(payload) {
  return { type: '@notas/GET_AVALIACOES_SUCCESS', payload };
}

export function setInstrumento(instrumento) {
  return { type: '@notas/SET_INSTRUMENTO', payload: { instrumento } };
}

export function getNotasEtapaRequest(idTurmaDisc, codEtapa) {
  return {
    type: '@notas/GET_NOTASETAPA_REQUEST',
    payload: { idTurmaDisc, codEtapa },
  };
}

export function getNotasEtapaSuccess(payload) {
  return {
    type: '@notas/GET_NOTASETAPA_SUCCESS',
    payload,
  };
}

export function updateNotaRequest(nota, instrumento, aluno) {
  return {
    type: '@notas/UPDATE_NOTA_REQUEST',
    payload: { nota, instrumento, aluno },
  };
}

export function updateNotaSuccess(nota, instrumento, aluno) {
  return {
    type: '@notas/UPDATE_NOTA_SUCCESS',
    payload: { nota, instrumento, aluno },
  };
}

export function clear() {
  return {
    type: '@notas/CLEAR',
  };
}

export function setSemestre(semestre) {
  return {
    type: '@notas/SET_SEMESTRE',
    payload: semestre,
  };
}

export function liberarNotas(codProva, codEtapa, idTurmaDisc) {
  return {
    type: '@notas/LIBERAR_NOTAS',
    payload: {
      liberado: true, codProva, codEtapa, idTurmaDisc,
    },
  };
}

export function bloquearNotas(codProva, codEtapa, idTurmaDisc) {
  return {
    type: '@notas/LIBERAR_NOTAS',
    payload: {
      liberado: false, codProva, codEtapa, idTurmaDisc,
    },
  };
}

export function liberarNotasSuccess(liberado, codEtapa, codProva) {
  return {
    type: '@notas/LIBERAR_NOTAS_SUCCESS',
    payload: { liberado, codEtapa, codProva },
  };
}

export function liberarNotasFailure() {
  return { type: '@notas/LIBERAR_NOTAS_FAILURE' };
}
