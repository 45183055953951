import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '../services/api';
import * as RubricaActions from '../store2/modules/rubrica/actions';
import * as NotasActions from '../store2/modules/notas/actions';
import useNotas from './useNotas';
import { getCodDiscAneSintese, isMedia } from '../helpers';

// import { Container } from './styles';

function useCadernos() {
  const { getNota, nomeDisc } = useNotas();
  const dispatch = useDispatch();

  const rubricaData = useSelector((state) =>
    state.rubrica.rubricaData);
  const comments = useSelector((state) =>
    state.rubrica.comments);
  const toggleModal = useSelector((state) =>
    state.rubrica.modalRubrica.toggleModal);
  const escalaValues = useSelector((state) =>
    state.rubrica.escalaValues);
  const loadingComments = useSelector((state) =>
    state.rubrica.loadingComments);

  const semestre = useSelector((state) =>
    state.notas.semestre);
  const instrumento = useSelector((state) =>
    state.notas.instrumento, shallowEqual);
  const notasEtapa = useSelector((state) =>
    state.notas.notasEtapa, shallowEqual);
  const savedStatusNota = useSelector((state) =>
    state.notas.savedStatusNota);

  const CODDISC = useSelector((state) =>
    state.aula.selected.CODDISC);

  const setRubricaData = useCallback(
    (data) => {
      dispatch(RubricaActions.setRubricaData(data));
    },
    [dispatch],
  );

  const [notaLiberada, setNotaLiberada] = useState(false);

  const downloadCaderno = useCallback(async (fileName) => {
    const result = await api.apiAxios.get(`cadernos/${fileName}`);
    const a = document.createElement('a');
    const { url } = result.data;

    a.href = url;
    a.download = url || 'download';
    a.target = '_blank';

    function clickHandler() {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    }

    a.addEventListener('click', clickHandler, false);

    a.click();
  }, []);

  const setAtividadeValue = useCallback(
    (value) => {
      // valida se é um número inteiro
      if (/\D/.test(value)) {
        toast.error('Permitido apenas números inteiros!');
        return;
      }
      const caderno = instrumento.filter((item) =>
        !!item.CODCOLIGADA);
      const { CODETAPA, IDTURMADISC, CODPROVA } = caderno[0];
      const ras = notasEtapa.map((item) =>
        item.RA);

      dispatch(
        RubricaActions.setAtividadeValueRequest({
          CODETAPA,
          IDTURMADISC,
          CODPROVA,
          atividadeValue: value,
          ras,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instrumento, notasEtapa],
  );

  const handleSelect = (codProva, nota) => {
    const newData = {
      [codProva]: parseFloat(nota.replace(',', '.')),
    };

    dispatch(RubricaActions.setEscalaValues({ ...escalaValues, ...newData }));
  };

  const setEscalaValues = (value) => {
    dispatch(RubricaActions.setEscalaValues(value));
  };

  const setComments = useCallback(
    (value) => {
      dispatch(RubricaActions.setComments({ ...value, semestre }));
    },
    [dispatch, semestre],
  );

  const setLoadingComments = useCallback(
    (value) => {
      dispatch(RubricaActions.setLoadingComments(value));
    },
    [dispatch],
  );

  const saveRubrica = () => {
    const { instrumento: inst, ra, nomeAluno } = rubricaData;
    const { CODETAPA, IDTURMADISC, DESCETAPA } = inst;
    const codDisc = getCodDiscAneSintese(CODETAPA) ?? CODDISC;
    const dataToSend = {
      comments,
      codEtapa: CODETAPA,
      ra,
      idTurmaDisc: IDTURMADISC,
      notas: escalaValues,
      nomeAluno,
      disciplina: nomeDisc,
      etapa: DESCETAPA,
      codProva: inst.CODPROVA,
      caderno: instrumento[0].CADERNO,
      semestre,
      codDisc,
    };
    dispatch(RubricaActions.saveRubricaRequest(dataToSend));
  };

  const modalRubrica = useCallback(
    async (aluno, inst) => {
      const notasRubrica = {};
      instrumento.forEach((item) => {
        if (!isMedia(item.CODPROVA) && item.PARAMETRO !== 'ATIVIDADE') {
          const nota = getNota(item.CODPROVA, item.CODETAPA, aluno.RA).slice(
            0,
            -1,
          );
          if (nota) {
            notasRubrica[item.CODPROVA] = parseFloat(nota.replace(',', '.'));
          }
        }
      });
      dispatch(RubricaActions.setEscalaValues(notasRubrica));
      dispatch(RubricaActions.getDevolutivaRequest({ aluno, inst, semestre }));
      const [disciplina, turma] = nomeDisc.split('-');
      setRubricaData({
        ra: aluno.RA,
        nomeAluno: aluno.NOMEALUNO,
        turma: turma.trim(),
        disciplina: disciplina.trim(),
        instrumento: inst,
      });
      toggleModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getNota,
      instrumento,
      nomeDisc,
      semestre,
      setRubricaData,
    ],
  );

  const modalParametros = useCallback(

    async (aluno, inst) => {
      if (inst.parametrosAvaliacao.length < 3) {
        dispatch(RubricaActions.setCreateParametros(true));
        toggleModal();
        return;
      }
      const notasRubrica = {};
      instrumento.forEach((item) => {
        // se não for média nem atividade, busca a nota
        if (!isMedia(item.CODPROVA) && item.PARAMETRO !== 'ATIVIDADE') {
          const nota = getNota(item.CODPROVA, item.CODETAPA, aluno.RA);
          if (nota !== '') {
            // adiciona no array de notas a nota encontrada
            notasRubrica[item.CODPROVA] = parseFloat(nota);
          }
        }
      });

      dispatch(RubricaActions.setEscalaValues(notasRubrica));

      // busca a devolutiva do professor
      dispatch(RubricaActions.getDevolutivaRequest({ aluno, inst, semestre }));

      const [disciplina, turma] = nomeDisc.split('-');
      setRubricaData({
        ra: aluno.RA,
        nomeAluno: aluno.NOMEALUNO,
        turma: turma.trim(),
        disciplina: disciplina.trim(),
        instrumento: inst,
      });
      toggleModal();
    },
    [
      dispatch,
      getNota,
      instrumento,
      nomeDisc,
      semestre,
      setRubricaData,
      toggleModal,
    ],
  );

  const validateInput = () => {
    if (!loadingComments) {
      if (rubricaData.instrumento) {
        const lengthNoAtividade = rubricaData.instrumento.parametrosAvaliacao.filter(
          (item) =>
            item.PARAMETRO !== 'ATIVIDADE',
        ).length;
        const escalaValuesLength = Object.keys(escalaValues).length;
        if (lengthNoAtividade === escalaValuesLength) {
          return true;
        }
      }
    }
    return false;
    /* hasAtividade
      ? rubricaData.instrumento.rubricas.length - 1 === escalaValues.length
      : rubricaData.instrumento.rubricas.length === escalaValues.length; */
  };

  const rubricaTotal = useMemo(
    () =>
      Object.values(escalaValues)
        .reduce((acc, currentValue) =>
          Number(acc) + Number(currentValue), 0),
    [escalaValues],
  );

  const liberarNotas = useCallback(
    (codProva, codEtapa, idTurmaDisc) => {
      dispatch(NotasActions.liberarNotas(codProva, codEtapa, idTurmaDisc));
    },
    [dispatch],
  );

  const bloquearNotas = useCallback(
    (codProva, codEtapa, idTurmaDisc) => {
      dispatch(NotasActions.bloquearNotas(codProva, codEtapa, idTurmaDisc));
    },
    [dispatch],
  );

  const handleLiberarNotas = useCallback(() => {
    const currentEtapaAvaliacao = instrumento.find(
      (item) =>
        isMedia(item.CODPROVA)
        || (item.DESCETAPA && !item.DESCETAPA.startsWith('CADERNO')),
    );

    setNotaLiberada((old) => {
      const { CODPROVA, CODETAPA, IDTURMADISC } = currentEtapaAvaliacao;
      if (old) {
        bloquearNotas(CODPROVA, CODETAPA, IDTURMADISC);
        return false;
      }
      liberarNotas(CODPROVA, CODETAPA, IDTURMADISC);
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumento]);

  useEffect(() => {
    const currentEtapaAvaliacao = instrumento.find(
      (item) =>
        isMedia(item.CODPROVA)
        || (item.DESCETAPA && !item.DESCETAPA.startsWith('CADERNO')),
    );
    if (currentEtapaAvaliacao) {
      if (currentEtapaAvaliacao.DTDEVOLUCAOAVALIACAO) {
        setNotaLiberada(true);
      } else {
        setNotaLiberada(false);
      }
    }

    /* return () =>
      setNotaLiberada(false); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumento]);

  const clearStackSaving = useCallback(() => {
    dispatch(RubricaActions.clearStackSaving());
  }, [dispatch]);

  // checa se a nota foi liberada com sucesso
  useEffect(() => {
    if (savedStatusNota === 'FAIL') {
      setNotaLiberada((old) =>
        !old);
    }
  }, [savedStatusNota]);
  // ---------------------------------------

  return {
    downloadCaderno,
    setRubricaData,
    setAtividadeValue,
    handleSelect,
    setComments,
    setLoadingComments,
    saveRubrica,
    modalRubrica,
    modalParametros,
    setEscalaValues,
    validateInput,
    rubricaTotal,
    clearStackSaving,
    handleLiberarNotas,
    notaLiberada,
  };
}

export default useCadernos;
