// eslint-disable-next-line import/prefer-default-export
export function getCodDiscAneSintese(codEtapa: number | string): string | undefined {
  const etapasSintese = JSON.parse(process.env.REACT_APP_ETAPAS_SINTESE ?? '');
  const etapasANE = JSON.parse(process.env.REACT_APP_ETAPAS_ANE ?? '');
  if (etapasSintese.includes(Number(codEtapa))) {
    return process.env.REACT_APP_CODDISC_SINTESE;
  }
  if (etapasANE.includes(Number(codEtapa))) {
    return process.env.REACT_APP_CODDISC_ANE;
  }
  return undefined;
}
