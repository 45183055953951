import React from 'react';

import { Button, Container } from './styles';

function SwitchButton({
  itemOff,
  itemOn,
  width,
  height,
  onClick,
  status = false,
}) {
  return (
    <Container width={width} height={height}>
      <Button onClick={onClick} width={width} height={height} status={status}>
        <div className="item">{itemOff}</div>
        <div className="item">{itemOn}</div>
      </Button>
    </Container>
  );
}

export default SwitchButton;
