import React from 'react';
import { AnimatePresence } from 'framer-motion';
import Container from './styles';

function Modal({
  children,
  showModal = false,
  hide,
  titulo = '',
  width = '30rem',
  createParametros = false,
}) {
  return (
    <AnimatePresence>
      {showModal && (
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Container.Content width={width}>
            <Container.Header>
              <h5>
                {(!createParametros && titulo) || 'Cadastro de parâmetros'}
              </h5>
              <Container.CloseButton onClick={hide}>
                &times;
              </Container.CloseButton>
            </Container.Header>
            {children}
          </Container.Content>
        </Container>
      )}
    </AnimatePresence>
  );
}

export default Modal;
