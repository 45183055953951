import styled from 'styled-components';
import { Link } from 'react-router-dom';

const buttonSize = '12rem';

export const Button = styled(Link)`
  width: ${buttonSize};
  height: ${buttonSize};
  border-radius: 30px;
  background: #032b50;
  color: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 1em #ccc;
  align-items: center;
  flex-direction: column;

  &:hover {
    color: #fff;
  }

  .icon-button {
    font-size: 8rem;
  }
`;

Button.Text = styled.p`
  margin-top: 1rem;
  font-size: 1.55rem;
  text-align: center;
  font-weight: 500;
  max-width: ${buttonSize};
`;

Button.TodayWeek = styled.span`
  font-size: 2.3rem;
  text-transform: capitalize;
`;

Button.TodayNumber = styled.span`
  font-size: 4.6rem;
`;

export const CalendarIcon = styled.table`
  font-size: 0.8rem;

  & > thead > tr > th,
  & > tbody > tr > td {
    padding: 0 2px;
    text-align: center;
  }
`;
