import React from 'react';
import Lottie from 'react-lottie';

import Container from './styles';
import loadingLottie from '../../assets/lottie/loading.json';

export default function Loading() {
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0 }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingLottie,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet',
          },
        }}
        width={400}
        height={400}
        isStopped={false}
        isPaused={false}
        isClickToPauseDisabled
      />
    </Container>
  );
}

export { Spinner } from './styles';
