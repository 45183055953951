import React, { useState, useEffect } from 'react';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { useSelector } from 'react-redux';

import MenuButtons from '../../components/MenuButtons';
import Avatar from '../../components/Avatar';

import { Container, Wrapper, Buttons } from './styles';

function Home() {
  const user = useSelector((state) =>
    state.login.user);
  const [nomeProfessor, setNomeProfessor] = useState('');
  const [foto, setFoto] = useState('');
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    setNomeProfessor(user.nome);
    setFoto(user.picture);

    const utcDate = zonedTimeToUtc(new Date());
    const hour = format(utcDate, 'kk', {
      timeZone: 'America/Sao_Paulo',
    });

    if (hour > 0 && hour <= 11) {
      setGreeting('Bom dia');
    } else if (hour > 11 && hour <= 17) {
      setGreeting('Boa tarde');
    } else {
      setGreeting('Boa noite');
    }
  }, [user.nome, user.picture]);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <Wrapper>
        <Avatar src={foto} />
        <Wrapper.Greetings>
          {greeting}
          ,
          {' '}
          <span style={{ textTransform: 'capitalize' }}>{nomeProfessor}</span>
        </Wrapper.Greetings>
      </Wrapper>

      <Buttons>
        <ul>
          <li>
            <MenuButtons button="frequenciaDiaria" />
          </li>

          <li>
            <MenuButtons button="frequenciaGeral" />
          </li>
          <li>
            <MenuButtons button="relatorios" />
          </li>
          <li>
            <MenuButtons button="notas" />
          </li>
        </ul>
      </Buttons>
    </Container>
  );
}

export default Home;
