import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  width: 100%;
  max-width: 1440px;
  height: inherit;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

Wrapper.Greetings = styled.h3`
  margin-top: 1rem;
  font-size: 3rem;
  font-weight: 300;
`;

export const Buttons = styled.div`
  width: 100%;
  height: auto;

  ul {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;

    li {
      transition: transform ease-in-out 300ms;
      flex-basis: 12.5rem;

      @media screen and (max-width: 550px) {
        flex-basis: 34%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;
      }
    }

    li:hover {
      transform: scale(1.1);
    }
  }
`;
