import { combineReducers } from 'redux';
import notas from './notas/reducer';
import login from './login/reducer';
import professor from './professor/reducer';
import aula from './aula/reducer';
import frequencia from './frequencia/reducer';
import licao from './licao/reducer';
import modal from './modal/reducer';
import aluno from './aluno/reducer';
import turma from './turma/reducer';
import rubrica from './rubrica/reducer';
import livros from './livros/reducer';

const reducers = combineReducers({
  notas,
  login,
  professor,
  aula,
  frequencia,
  licao,
  modal,
  aluno,
  turma,
  rubrica,
  livros,
});

export default reducers;
