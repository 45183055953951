import styled from 'styled-components';
import device from '../../util/device';

export const Container = styled.div`
  padding: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerCallback = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  div {
    display: flex;
    align-items: center;
    flex-direction: column;

    .alert {
      margin-top: 20px;
      font-size: 0.8rem;
    }
  }

  ${device.tablet} {
    div {
      .alert {
        font-size: 1rem;
      }
    }
  }
`;

export const ContainerLogo = styled.img`
  max-width: 250px;

  ${device.tablet} {
    max-width: 300px;
  }
`;
