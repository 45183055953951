/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DefaultLayout from './pages/_layouts/default';
import LoginLayout from './pages/_layouts/login';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  ...rest
}) {
  const { signed } = useSelector((state) =>
    state.login);
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/Home" />;
  }

  const Layout = signed ? DefaultLayout : LoginLayout;

  return (
    <Route
      {...rest}
      component={(props) =>
        (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
    />
  );
}
